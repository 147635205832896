<template>
    <v-dialog v-model="shows" :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="breakpoint ? '480' : '824'" transition="fade-transition">
        <template #activator="props">
            <slot name="activator" v-bind="props" />
        </template>
        <v-card tile color="content" height="100%" style="position: relative" v-bind="{ loading }">
            <v-card-title class="dark secondary white--text">
                QR 체크인 <v-spacer />
                <v-btn dark text icon @click="shows = !shows"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-row class="ma-0" justify="center">
                <v-col cols="12" lg="auto">
                    <v-card flat rounded="xl" class="fill-height" style="min-width: 320px; min-height: 240px; overflow: hidden">
                        <v-responsive :aspect-ratio="1 / 1" :max-width="breakpoint ? undefined : 320" :max-height="breakpoint ? undefined : 320">
                            <qrcode-stream-reader v-if="!isFullscreenMode" v-model="decoded" v-bind="{ propCamera }" v-on="{ changeCamera }">
                                <message-snackbar v-bind="{ message }" absolute centered light tile @close="message = null" />
                                <v-btn slot="button" fab elevation="4" class="mb-3" @click="isFullscreenMode = !isFullscreenMode"> <v-icon large>mdi-fullscreen</v-icon> </v-btn>
                            </qrcode-stream-reader>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="auto" :style="breakpoint ? undefined : 'min-width: 480px; max-width:480px; min-height: 240px'">
                    <v-data-table v-bind="{ items, headers }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" class="fill-height rounded-xl">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        <template #no-data>출석기록이 없습니다</template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-fade-transition>
                <v-overlay v-show="isFullscreenMode" :dark="false" color="white" opacity="1">
                    <v-responsive height="100vh" width="100vw">
                        <qrcode-stream-reader v-if="isFullscreenMode" v-model="decoded" v-bind="{ propCamera }" v-on="{ changeCamera }">
                            <message-snackbar v-bind="{ message }" absolute centered light tile @close="message = null" />
                            <v-btn slot="button" fab elevation="4" class="mb-3" @click="isFullscreenMode = !isFullscreenMode"> <v-icon large>mdi-fullscreen-exit</v-icon> </v-btn>
                        </qrcode-stream-reader>
                    </v-responsive>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import MessageSnackbar from "@/components/dumb/message-snackbar.vue";
import QrcodeStreamReader from "@/components/dumb/qrcode-stream-reader.vue";

let headers = [
    { text: "아이디", value: "user.username" },
    { text: "이름", value: "user.name" },
    { text: "입실", value: "attendedAt", align: "right", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.[1] || "-", width: 90 },
    { text: "퇴실", value: "departedAt", align: "right", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.[1] || "-", width: 90 },
];

export default {
    components: {
        MessageSnackbar,
        QrcodeStreamReader,
    },
    data: () => ({
        attendances: [],

        headers,
        propCamera: "auto",
        decoded: null,
        message: null,

        shows: false,
        loading: false,
        isFullscreenMode: false,
    }),
    computed: {
        items() {
            return this.attendances;
        },
        breakpoint() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        async decoded(_attendance) {
            if (!_attendance) return;

            setTimeout(() => {
                if (_attendance == this.decoded) {
                    this.message = null;
                    this.decoded = null;
                }
            }, 3000);

            if (this.loading) return;
            else this.loading = true;

            try {
                const { attendance, message } = await api.console.common.attendances.checkIn.put({
                    _id: _attendance,
                });

                if (attendance) {
                    this.attendances = [attendance, ...this.attendances.filter(({ _id }) => _id != attendance._id)];
                    this.$emit("search");
                }
                this.message = message;
            } catch (error) {
                this.message = error.response.data.message;
            } finally {
                this.loading = false;
            }
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.attendances = (
                    await api.console.common.attendances.gets({
                        headers: {
                            sort: JSON.stringify({ updatedAt: -1 }),
                        },
                        params: {
                            isAttended: true,
                            searchDateValue: [this.$dayjs().startOf("day").toDate(), this.$dayjs().endOf("day").toDate()],
                        },
                    })
                )?.attendances;
            } finally {
                this.loading = false;
            }
        },
        changeCamera(camera) {
            this.propCamera = camera;
        },
    },
};
</script>

<style></style>
