<template>
    <v-chip-group v-model="days" multiple column dense color="primary" @change="emit">
        <template v-for="{ text, value } in items">
            <v-chip :key="text" :value="value" outlined label class="rounded-lg">
                {{ text }}
            </v-chip>
        </template>
    </v-chip-group>
</template>

<script>
const items = [
    { text: "일", value: 0 },
    { text: "월", value: 1 },
    { text: "화", value: 2 },
    { text: "수", value: 3 },
    { text: "목", value: 4 },
    { text: "금", value: 5 },
    { text: "토", value: 6 },
];

export default {
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        days: [],
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.days = [...this.value];
        },
        emit() {
            this.$emit("input", this.days);
        },
    },
};
</script>

<style>
</style>