<template>
    <list-search showsSearch v-bind="$attrs" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-user label="회원" v-model="query['_user']" placeholder="전체" clearable v-bind="inputAttrs" v-on="{ emit }" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="참석여부" v-model="query['isAttended']" :items="isAttendedItems" placeholder="전체" v-bind="inputAttrs" />
            </v-col>

            <template v-if="type == COMMON_ATTENDANCE_TYPES.PROGRAM_UNITS.value">
                <v-col cols="12" sm="6" lg="3" class="pa-2">
                    <autocomplete-program label="교육" v-model="query['_program']" placeholder="전체" clearable v-bind="inputAttrs" @input="query['_subject'] = null" />
                </v-col>
            </template>

            <template v-if="type == COMMON_ATTENDANCE_TYPES.RENTAL_EQUIPMENTS.value">
                <v-col cols="12" sm="6" lg="3" class="pa-2">
                    <autocomplete-rental-subject label="장비" v-model="query['_subject']" placeholder="전체" :params="{ type: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value }" clearable v-bind="inputAttrs" @input="query['_program'] = null" />
                </v-col>
            </template>

            <template v-if="type == COMMON_ATTENDANCE_TYPES.RENTAL_FACILITIES.value">
                <v-col cols="12" sm="6" lg="3" class="pa-2">
                    <autocomplete-rental-subject label="시설" v-model="query['_subject']" placeholder="전체" :params="{ type: RENTAL_SUBJECT_TYPES.FACILITIES.value }" clearable v-bind="inputAttrs" @input="query['_program'] = null" />
                </v-col>
            </template>

            <v-col cols="12" class="pa-2">
                <v-row align="center" class="ma-n2">
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                    </v-col>
                    <v-col class="pa-2" style="overflow-x: auto">
                        <dates-toggle v-model="query.searchDateValue" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { COMMON_ATTENDANCE_TYPES, inputAttrs, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

import DatesToggle from "@/components/console/dumb/dates-toggle.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteProgram from "@/components/dumb/autocomplete-program.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_user"]: query["_user"] ?? null,
    ["_program"]: query["_program"] ?? null,
    ["_subject"]: query["_subject"] ?? null,

    ["isAttended"]: { true: true, false: false }[query["isAttended"]] ?? null,

    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],
});

const isAttendedItems = [
    { text: "전체", value: null },
    { text: "참석", value: true },
    { text: "불참", value: false },
];

export default {
    components: {
        ListSearch,

        DatesToggle,
        AutocompleteUser,
        AutocompleteProgram,
        AutocompleteRentalSubject,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        query: initQuery(),

        isAttendedItems,
        RENTAL_SUBJECT_TYPES,
        COMMON_ATTENDANCE_TYPES,

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            console.log("isCalled?");
            let { ...query } = this.query;

            delete query.page;

            if (!query["_user"]) delete query["_user"];

            if (!query["_program"]) delete query["_program"];

            if (!query["_subject"]) delete query["_subject"];

            if (![true, false].includes(query["isAttended"])) delete query["isAttended"];

            if (!query["searchDateValue"]?.[0] && !query["searchDateValue"]?.[1]) {
                delete query["searchDateValue"];
            }

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
