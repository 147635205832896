<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div class="headline text-start mt-8">{{ title }}</div>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="12" class="py-0">
                                    <v-text-field v-model="board.subject" v-bind="{ ...inputAttrs }" label="제목" />
                                </v-col>
                            </v-row>
                            <v-row v-if="isProject" class="mt-3">
                                <v-col cols="6">
                                    <v-date-field v-model="board.businessStartsAt" label="사업시작일" v-bind="{ ...inputAttrs }"></v-date-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-date-field v-model="board.businessEndsAt" label="사업종료일" v-bind="{ ...inputAttrs }"></v-date-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="board.summary" v-bind="{ ...inputAttrs }" label="요약" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subtitle-1">썸네일 등록</v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="5" class="pl-6 my-4">
                                    <image-input v-model="board.thumb" accept="image/*" width="140" height="140" outlined style="overflow: hidden" />
                                </v-col>
                                <v-col align-self="center">
                                    <v-file-input v-model="board.thumb" accept="image/*" v-bind="{ ...inputAttrs }" label="파일명" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-card class="mt-2">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import { ARCHIVING_BOARD_TYPES, initArchivingBoard, inputAttrs } from "@/assets/variables";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ImageInput from "@/components/console/dumb/image-input.vue";

export default {
    components: {
        VDatetimeField,
        VDateField,
        NaverSmarteditor,
        ImageInput,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            inputAttrs,

            board: initArchivingBoard({ code: this.code }),
        };
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
        isCreate() {
            return !this._board;
        },
        title() {
            return `게시글 ${this.isCreate ? "등록" : "상세"}`;
        },
        isProject() {
            return this.code === ARCHIVING_BOARD_TYPES.PROJECT.value;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.isCreate) return;

                let { board } = await api.console.archiving.board.get({ _id: this._board });
                if (this.board.code != this.code) {
                    alert("권한이 없습니다");
                    this.$router.replace("/console");
                    return;
                }
                this.board = initArchivingBoard(board);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _thumb, thumb, ...form } = this.board;

                let { post, put } = api.console.archiving.board;

                let { board } = await (this.isCreate ? post : put)(form);

                if (thumb instanceof File) {
                    thumb = (await api.console.common.files.post({ path: "archiving", _archiving: board._id }, thumb))?.file;

                    if (_thumb && thumb._id !== _thumb) await api.console.common.files.delete({ _id: _thumb });

                    _thumb = thumb?._id || null;
                }

                if (_thumb && !thumb) {
                    await api.console.common.files.delete({ _id: _thumb });
                    _thumb = null;
                }

                await api.console.archiving.board.put({ _id: board._id, _thumb: _thumb });

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
