var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('list-heading', _vm._b({}, 'list-heading', {
    title: _vm.title
  }, false)), _c('current-list-search', _vm._b({
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, 'current-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "mt-4 v-sheet--outlined",
    attrs: {
      "items": _vm.manages,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "disabled": !_vm.$route.query.code || _vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: `item.index`,
      fn: function (_ref) {
        var _value$format;
        var item = _ref.item,
          value = _ref.value;
        return [_c('edit-index', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : (_value$format = value.format) === null || _value$format === void 0 ? void 0 : _value$format.call(value)) || "-") + " ")])];
      }
    }, {
      key: `item.subject`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.code == 'current' ? [_vm._v(" " + _vm._s(item.subject || "-") + " ")] : [_vm._v(" 이용안내 ")]];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDateTime()) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "to": {
              path: `/console/about/${_vm.code}/${item._id}`,
              query: {
                code: _vm.code
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "삭제"
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    loading: _vm.loading
  }, false), [_vm._v(" s ")]), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": "",
      "to": {
        path: `/console/about/${_vm.code}/create`,
        query: {
          code: _vm.code
        }
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }