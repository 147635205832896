<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer: :items-per-page="-1" ref="data-table">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :sort="!loading || items.length < 2" @change="setNewIndex">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.handle`]>
            <v-btn text tile color="grey darken-1" height="48" style="cursor: move" :disabled="items.length < 2">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </template>
        <template #[`item.name`]="{ item }">
            <v-text-field v-model="item.name" v-bind="{ ...attrs_input__verticalTable }" @input="emit" />
        </template>
        <template #[`item.count`]="{ item }">
            <v-text-field v-model="item.count" type="number" v-bind="{ ...attrs_input__verticalTable }" @input="emit" />
        </template>
        <template #[`item.desc`]="{ item }">
            <v-text-field v-model="item.desc" v-bind="{ ...attrs_input__verticalTable }" @input="emit" />
        </template>
        <template #[`item.etc`]="{ item }">
            <v-text-field v-model="item.etc" v-bind="{ ...attrs_input__verticalTable }" @input="emit" />
        </template>
        <template #[`item.actions`]="{ index }">
            <v-btn text tile height="48" color="red" @click="pull(index)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row no-gutters align="center">
                <v-col></v-col>
                <v-divider vertical />
                <v-col cols="auto">
                    <v-btn text tile height="58" color="primary" @click="push"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { initStoryGroups, attrs_input__verticalTable } from "@/assets/variables";
import Draggable from "vuedraggable";

const headers = [
    { width: 30, text: "", value: "handle", align: "center" },
    { width: 100, text: "구분", value: "name" },
    { width: 40, text: "규모", value: "count" },
    { width: 140, text: "주요활동", value: "desc" },
    { width: 140, text: "비고", value: "etc" },
    { width: 30, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, cellClass: (item.cellClass || "") + " px-0" }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
        attrs_input__verticalTable,
        loading: false,

        form: [],
    }),
    computed: {
        items() {
            return [...this.form];
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = [...this.value].map(initStoryGroups);
        },
        pull(index) {
            this.form.splice(index, 1);
            this.emit();
        },
        push() {
            this.form.push(initStoryGroups());
            this.emit();
        },
        emit() {
            this.$emit("input", this.form);
        },
        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.form.splice(newIndex, 0, ...this.form.splice(oldIndex, 1));
            } finally {
                this.loading = false;
            }

            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>