var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "700px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("소개 영상 유튜브 링크 설정")]), _c('v-card-text', [_c('v-card-subtitle', [_vm._v("유튜브 영상 -> 공유 -> 퍼가기 -> embed/ 다음 코드 복사")]), _c('v-text-field', _vm._b({
    attrs: {
      "label": "유튜브 링크"
    },
    model: {
      value: _vm.youtubeCode,
      callback: function ($$v) {
        _vm.youtubeCode = $$v;
      },
      expression: "youtubeCode"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', _vm._b({
    on: {
      "click": function () {
        return _vm.shows = false;
      }
    }
  }, 'v-btn', _vm.inputAttrs, false), [_vm._v("취소")]), _c('v-btn', _vm._b({
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.inputAttrs, false), [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }