<template>
    <v-tabs>
        <template v-for="item in items">
            <v-tab :exact="item.to.path == path" :exact-path="item.to.path != path" :to="item.to" :key="item.text">{{ item.text }}</v-tab>
        </template>
    </v-tabs>
</template>

<script>
import { COMMON_ATTENDANCE_TYPES } from "@/assets/variables";

const path = "/console/attendances";

export default {
    data: () => ({
        path,
    }),
    computed: {
        items() {
            const { ...query } = this.$route.query;

            delete query.page;

            return [{ text: "전체", to: { path, query } }].concat(Object.values(COMMON_ATTENDANCE_TYPES).map(({ text, value }) => ({ text, to: { path: `${path}/${value}`, query } })));
        },
    },
};
</script>

<style>
</style>