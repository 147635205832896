<template>
    <v-dialog v-model="shows" :max-width="form._attendance ? 800 : 400" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">사용금지 생성</template>
                <template v-else>사용금지 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="" class="pa-2">
                        <view-section-card title="기본정보">
                            <v-card-text>
                                <v-row class="ma-n2">
                                    <v-col cols="12" class="pa-2">
                                        <autocomplete-user v-model="form._user" label="적용회원" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                    <v-col cols="12" class="pa-2">
                                        <v-datetime-field v-model="form.startsAt" label="사용금지 시작일시" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                    <v-col cols="12" class="pa-2">
                                        <v-datetime-field v-model="form.endsAt" label="사용금지 종료일시" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                    <v-col cols="12" class="pa-2">
                                        <v-textarea v-model="form.desc" label="사용금지 사유" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </view-section-card>
                    </v-col>
                    <v-expand-x-transition>
                        <v-col cols="12" md="6" class="pa-0" v-show="form._attendance">
                            <div class="pa-2">
                                <view-section-card title="불참정보" disabled>
                                    <v-card-text>
                                        <v-row class="ma-n2">
                                            <v-col cols="12" class="pa-2">
                                                <v-text-field :value="form?.subject?.name" label="설비명" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-text-field :value="form?.date?.toDate?.()" label="불참일" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-currency-field :value="form?.count" label="누적불참수" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-currency-field :value="form?.policy?.punish?.days" label="금지일수" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </view-section-card>
                            </div>
                        </v-col>
                    </v-expand-x-transition>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isActive" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.isActive">사용</template>
                                <template v-else>미사용</template>
                            </span>
                        </template>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, initUserBan } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

export default {
    components: {
        VDatetimeField,
        ViewSectionCard,
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initUserBan },
    },
    data: () => ({
        form: initUserBan(),

        inputAttrs,
        switchAttrs,

        shows: false,
        loading: false,
    }),
    computed: {
        _ban() {
            return this.value?._id;
        },
        isCreate() {
            return !this._ban;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initUserBan();
                } else {
                    let { ban } = await api.console.users.bans.get({ _id: this._ban });

                    this.form = initUserBan(ban);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initUserBan(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { ...form } = this.form;

                // POST/PUT ban
                let { post, put } = api.console.users.bans;
                let { ban } = await (this.isCreate ? post(form) : put(form));

                alert("저장되었습니다");
                this.$emit("input", ban);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
