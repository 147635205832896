var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', _vm._l(_vm.files, function (_ref) {
    var name = _ref.name,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? '' : _ref$type,
      href = _ref.href;
    return _c('v-chip', _vm._b({
      key: href,
      attrs: {
        "download": ""
      }
    }, 'v-chip', Object.assign({}, _vm.$attrs, {
      href
    }), false), [type.includes('image') ? _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-image")]) : _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-file")]), _vm._v(" " + _vm._s(name) + " ")], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }