var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "85vh",
      "width": "85%",
      "disabled": !_vm.src,
      "content-class": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _class;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        }), !_vm.$scopedSlots['activator'] ? [_c('v-img', _vm._g(_vm._b({
          class: (_class = {
            'v-sheet--outlined': _vm.outlined
          }, _class[`rounded${typeof _vm.rounded == 'string' ? `-${_vm.rounded}` : ''}`] = !!_vm.rounded, _class),
          style: !_vm.src ? 'cursor:initial' : '',
          attrs: {
            "src": _vm.src
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              $event.stopPropagation();
            }
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                attrs: {
                  "absolute": "",
                  "light": "",
                  "color": "transparent"
                }
              }, [_c('v-icon', {
                attrs: {
                  "color": "grey"
                }
              }, [_vm._v("mdi-image-broken-variant")])], 1)];
            },
            proxy: true
          }], null, true)
        }, 'v-img', Object.assign({}, attrs, {
          aspectRatio: _vm.aspectRatio
        }, _vm.$attrs), false), on))] : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.src ? [_c('v-card', {
    attrs: {
      "href": _vm.src,
      "target": "_blank"
    }
  }, [_c('v-img', _vm._b({
    attrs: {
      "src": _vm.src,
      "max-height": "85vh",
      "contain": ""
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "light": "",
            "color": "transparent"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey"
          }
        }, [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 4145666038)
  }, 'v-img', Object.assign({
    aspectRatio: _vm.aspectRatio
  }, _vm.$attrs), false), [_c('v-btn', {
    staticStyle: {
      "background-color": "rgba(0, 0, 0, 0.3)"
    },
    attrs: {
      "absolute": "",
      "right": "",
      "top": "",
      "icon": "",
      "tile": "",
      "dark": "",
      "x-large": ""
    },
    on: {
      "!click": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "4rem"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }