var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-col', {
    staticClass: "mt-4 pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 게시글 등록 ")]), _vm.$route.query.code === 'current' || _vm.manage.code === 'current' ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "placeholder": "ex) 308호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manage.subject,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "subject", $$v);
      },
      expression: "manage.subject"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.$route.query.code === 'current' || _vm.manage.code === 'current' ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지",
      "placeholder": "권장사이즈 : 166*80(px)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.$route.query.code === 'current' || _vm.manage.code === 'current' ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("입주기업 현황")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "입주시기"
    },
    model: {
      value: _vm.manage.state.period,
      callback: function ($$v) {
        _vm.$set(_vm.manage.state, "period", $$v);
      },
      expression: "manage.state.period"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기업명"
    },
    model: {
      value: _vm.manage.state.company,
      callback: function ($$v) {
        _vm.$set(_vm.manage.state, "company", $$v);
      },
      expression: "manage.state.company"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "대표자명"
    },
    model: {
      value: _vm.manage.state.name,
      callback: function ($$v) {
        _vm.$set(_vm.manage.state, "name", $$v);
      },
      expression: "manage.state.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "사업아이템"
    },
    model: {
      value: _vm.manage.state.item,
      callback: function ($$v) {
        _vm.$set(_vm.manage.state, "item", $$v);
      },
      expression: "manage.state.item"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처"
    },
    model: {
      value: _vm.manage.state.phone,
      callback: function ($$v) {
        _vm.$set(_vm.manage.state, "phone", $$v);
      },
      expression: "manage.state.phone"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.$route.query.code !== 'current' || _vm.manage.code !== 'current' ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("이용안내")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "max-width": "1000"
    },
    model: {
      value: _vm.manage.content,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "content", $$v);
      },
      expression: "manage.content"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }