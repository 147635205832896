<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="설비통계 - 시간대">
                <template #buttons>
                    <v-btn rounded dark color="green" class="my-n4" @click="excel">
                        <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                        <span>엑셀 다운로드</span>
                    </v-btn>
                </template>
            </list-heading>

            <rental-statistics-list-search showsSearch flat outlined dense hideLabel dateToggleMode="monthly" />

            <v-data-table v-bind="{ items, headers, loading }" disable-pagination hide-default-footer :items-per-page="-1" :class="{ 'v-sheet--outlined mt-4': true, 'horizontal-table': !isMobile }" style="overflow: hidden" dense mobile-breakpoint="0">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import RentalStatisticsListSearch from "@/components/console/rental/statistics/rental-statistics-list-search.vue";

export default {
    components: {
        ListHeading,

        RentalStatisticsListSearch,
    },
    data: () => ({
        loading: false,
        items: [],

        isMobile: detect == "mobile",
    }),
    computed: {
        headers() {
            const headers = [
                {
                    text: "사용시각",
                    value: "time",
                    formatter: (value) => `${value || 0}시`,
                    align: "end",
                },
                {
                    text: "사용자수",
                    value: "count",
                    formatter: (value) => (value ? `${value?.format?.() || value}명` : "-"),
                    align: "end",
                },
                {
                    text: "%",
                    value: "total",
                    formatter: (value) => (value ? `${(value * 100)?.toFixed?.(2) || value}%` : "-"),
                    align: "end",
                },
            ];

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));
        },

        footers() {
            return new Array(6).fill().map((_, i) => ({
                value: this.items.reduce((sum, item) => sum + item.desc[i + 1] || 0, 0),
                suffix: "건",
            }));
        },

        params() {
            let { ...query } = this.$route.query;

            if (!query.attendanceType) delete query.attendanceType;

            if (!query._subjects?.length) delete query._subjects;

            if (!query.searchDateValue || !query.searchDateValue?.[0] || !query.searchDateValue?.[1]) {
                delete query.searchDateValue;
            }

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            this.items = [];

            try {
                const { params } = this;

                const { items } = await api.console.rental.statistics.getByTime({ params });

                const totalCount = items.reduce((total, { count }) => total + count, 0);

                this.items = items.map((item) => ({ ...item, total: item.count / totalCount }));
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { params } = this;

                const { items } = await api.console.rental.statistics.getByTime({ params });
                const totalCount = items.reduce((total, { count }) => total + count, 0);
                const rows = items.map((item) => ({ ...item, total: item.count / totalCount }));

                this.$excel(rows, this.headers, "설비통계_시간대");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    .horizontal-table table {
        position: relative;
        display: flex;

        thead {
            position: fixed;

            display: flex;
            flex: 1 0 auto;
            width: 100px;
            background-color: white;
        }

        tbody {
            display: flex;
            flex: 1 0 auto;
            padding-left: 100px;
        }

        tr {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }

        th,
        td {
            border-right: thin solid rgba(0, 0, 0, 0.12);
            min-width: 100px;
            line-height: 32px !important;

            &:not(:last-of-type) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
            }
            &:last-of-type {
                border-bottom: none !important;
            }
        }

        tr:last-of-type:not(:first-of-type) {
            td,
            th {
                border-right: none;
            }
        }

        th {
            user-select: auto !important;
        }
    }
}
</style>
