<template>
    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.attendances`]="{ item, value }">
            <program-unit-attendances v-bind="{ ...item, applications }" v-on="{ update }">
                <template #activator="{ attrs, on }">
                    <v-sheet color="transparent" class="px-4 py-3" v-bind="attrs" v-on="on">
                        <span> {{ value.filter(({ isAttended }) => isAttended).length }} / {{ value.length }} </span>
                        <v-icon small class="ml-2">mdi-pencil</v-icon>
                    </v-sheet>
                </template>
            </program-unit-attendances>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { COMMON_ATTENDANCE_TYPES, initProgramUnit, PROGRAM_APPLICATION_STATES } from "@/assets/variables";

import ProgramUnitAttendances from "./program-unit-attendances.vue";

let headers = [
    { text: "회차", value: "number", formatter: (value) => `${value}회차` },
    { text: "날짜", value: "unit.date", formatter: (value) => value.toDate() },
    { text: "요일", value: "unit.day" },
    { text: "시간", value: "unit.time" },
    { text: "참석현황", value: "attendances" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ProgramUnitAttendances,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        attendances: [],
        applications: [],

        headers,
    }),
    computed: {
        units() {
            return this.program?.units || [];
        },
        items() {
            return this.units.map((unit, index) => ({ unit: initProgramUnit(unit), attendances: this.attendances.filter(({ _unit }) => _unit == unit?._id) || [], number: index + 1 }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._attendees"() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { _id: _program } = this.program || {};
            if (_program) {
                let { attendances } = await api.console.common.attendances.gets({ params: { _program, type: COMMON_ATTENDANCE_TYPES.PROGRAM_UNITS.value } });
                this.attendances = attendances;

                let { applications } = await api.console.programs.applications.gets({ _program, params: { state: PROGRAM_APPLICATION_STATES.APPROVED.value } });
                this.applications = applications;
            }
        },

        async update(item) {
            await api.console.common.attendances.put(item);
            this.init();
        },
    },
};
</script>
