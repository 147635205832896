<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%" class="mx-n3 pa-3">
            <list-heading :title="`교육 상세 - ${program?.name}`" />
            <v-card outlined style="overflow: hidden">
                <v-tabs show-arrows icons-and-text color="secondary">
                    <v-tab exact :to="`/console/programs/${_program}`"> 교육 <v-icon>mdi-human-male-board</v-icon> </v-tab>
                    <v-divider vertical />
                    <v-tab exact :to="`/console/programs/${_program}/applications`"> 신청서 <v-icon>mdi-file-document-multiple</v-icon> </v-tab>
                    <v-divider vertical />
                    <v-tab exact :to="`/console/programs/${_program}/attendees`"> 참여자 <v-icon>mdi-account-multiple</v-icon> </v-tab>
                    <v-divider vertical />
                    <v-tab exact :to="`/console/programs/${_program}/units`"> 참석 <v-icon>mdi-checkbox-marked</v-icon> </v-tab>
                    <v-divider vertical />
                </v-tabs>
                <v-divider />
                <v-tabs-items :value="tab">
                    <v-tab-item value="information">
                        <program-information v-bind="{ program }" />
                    </v-tab-item>
                    <v-tab-item value="applications">
                        <program-applications v-bind="{ program }" />
                    </v-tab-item>
                    <v-tab-item value="attendees">
                        <program-attendees v-bind="{ program }" />
                    </v-tab-item>
                    <v-tab-item value="units">
                        <program-units v-bind="{ program }" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initProgram } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramUnits from "@/components/console/program/program-units.vue";
import ProgramAttendees from "@/components/console/program/program-attendees.vue";
import ProgramInformation from "@/components/console/program/program-information.vue";
import ProgramApplications from "@/components/console/program/program-applications.vue";

export default {
    components: {
        ListHeading,
        ProgramUnits,
        ProgramAttendees,
        ProgramInformation,
        ProgramApplications,
    },
    props: {
        _program: { type: String, default: "information" },
        tab: { type: String, default: "information" },
    },
    data: () => ({
        program: null,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { program } = await api.console.programs.get({ _id: this._program });
            this.program = initProgram(program);
        },
    },
};
</script>

<style></style>
