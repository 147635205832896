<template></template>

<script>
import api from "@/api";
import { initRentalSubject, RENTAL_LIMITATION_TARGET_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] },

        date: { type: String },
        subject: { type: Object, default: initRentalSubject },
    },
    data: () => ({
        blockages__site: [],
        blockages__type: [],
        blockages__self: [],
    }),
    computed: {
        blockages() {
            return [...this.blockages__site, ...this.blockages__type, ...this.blockages__self];
        },
    },
    mounted() {
        this.sync().then(this.search);
    },
    watch: {
        value() {
            this.sync();
        },

        date() {
            this.search();
        },
        subject() {
            this.search();
        },
    },
    methods: {
        async sync() {
            this.blockages__site = this.value.filter(({ target: { type } }) => type == RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value);
            this.blockages__type = this.value.filter(({ target: { type } }) => type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_TYPE.value);
            this.blockages__self = this.value.filter(({ target: { type } }) => type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_SELF.value);
        },

        async search() {
            const { date } = this;
            const { _id: _subject, type: subjectType } = this.subject;
            if (!_subject || !date) return;

            this.$emit("setLoading", true);
            try {
                this.blockages__site = (
                    await api.v1.rental.blockages.gets({
                        params: {
                            date,
                            isActive: true,
                            ["target.type"]: RENTAL_LIMITATION_TARGET_TYPES.ENTIRE_ASSET.value,
                        },
                    })
                )?.blockages;

                this.blockages__type = (
                    await api.v1.rental.blockages.gets({
                        params: {
                            date,
                            isActive: true,
                            ["target.type"]: RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_TYPE.value,
                            ["target.subjectTypes"]: subjectType,
                        },
                    })
                )?.blockages;

                this.blockages__self = (
                    await api.v1.rental.blockages.gets({
                        params: {
                            date,
                            isActive: true,
                            ["target.type"]: RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_SELF.value,
                            ["target._subjects"]: _subject,
                        },
                    })
                )?.blockages;

                this.$emit("input", this.blockages);
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
