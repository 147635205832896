var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "max-width": _vm.breakpoint ? '480' : '824',
      "transition": "fade-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (props) {
        return [_vm._t("activator", null, null, props)];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "tile": "",
      "color": "content",
      "height": "100%"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "dark secondary white--text"
  }, [_vm._v(" QR 체크인 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "dark": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = !_vm.shows;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    staticStyle: {
      "min-width": "320px",
      "min-height": "240px",
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "rounded": "xl"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "max-width": _vm.breakpoint ? undefined : 320,
      "max-height": _vm.breakpoint ? undefined : 320
    }
  }, [!_vm.isFullscreenMode ? _c('qrcode-stream-reader', _vm._g(_vm._b({
    model: {
      value: _vm.decoded,
      callback: function ($$v) {
        _vm.decoded = $$v;
      },
      expression: "decoded"
    }
  }, 'qrcode-stream-reader', {
    propCamera: _vm.propCamera
  }, false), {
    changeCamera: _vm.changeCamera
  }), [_c('message-snackbar', _vm._b({
    attrs: {
      "absolute": "",
      "centered": "",
      "light": "",
      "tile": ""
    },
    on: {
      "close": function ($event) {
        _vm.message = null;
      }
    }
  }, 'message-snackbar', {
    message: _vm.message
  }, false)), _c('v-btn', {
    staticClass: "mb-3",
    attrs: {
      "slot": "button",
      "fab": "",
      "elevation": "4"
    },
    on: {
      "click": function ($event) {
        _vm.isFullscreenMode = !_vm.isFullscreenMode;
      }
    },
    slot: "button"
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-fullscreen")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    style: _vm.breakpoint ? undefined : 'min-width: 480px; max-width:480px; min-height: 240px',
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "fill-height rounded-xl",
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "no-data",
      fn: function () {
        return [_vm._v("출석기록이 없습니다")];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isFullscreenMode,
      expression: "isFullscreenMode"
    }],
    attrs: {
      "dark": false,
      "color": "white",
      "opacity": "1"
    }
  }, [_c('v-responsive', {
    attrs: {
      "height": "100vh",
      "width": "100vw"
    }
  }, [_vm.isFullscreenMode ? _c('qrcode-stream-reader', _vm._g(_vm._b({
    model: {
      value: _vm.decoded,
      callback: function ($$v) {
        _vm.decoded = $$v;
      },
      expression: "decoded"
    }
  }, 'qrcode-stream-reader', {
    propCamera: _vm.propCamera
  }, false), {
    changeCamera: _vm.changeCamera
  }), [_c('message-snackbar', _vm._b({
    attrs: {
      "absolute": "",
      "centered": "",
      "light": "",
      "tile": ""
    },
    on: {
      "close": function ($event) {
        _vm.message = null;
      }
    }
  }, 'message-snackbar', {
    message: _vm.message
  }, false)), _c('v-btn', {
    staticClass: "mb-3",
    attrs: {
      "slot": "button",
      "fab": "",
      "elevation": "4"
    },
    on: {
      "click": function ($event) {
        _vm.isFullscreenMode = !_vm.isFullscreenMode;
      }
    },
    slot: "button"
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-fullscreen-exit")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }