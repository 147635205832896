<template>
    <v-dialog width="700px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>본문 미리보기</v-card-title>
            <v-card-text>
                <iframe v-if="youtubeCode" width="100%" :src="`https://www.youtube.com/embed/${youtubeCode}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="youtube rounded-sm mb-18 mb-md-24" />
                <div v-html="content"></div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        content: { type: String, default: "" },
        youtubeCode: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
.youtube {
    aspect-ratio: 16 / 9;
}
@media (min-width: 768px) {
}
</style>