var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "주소"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우편번호",
      "disabled": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "postcode", $$v);
      },
      expression: "form.postcode"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기본주소",
      "disabled": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.address1,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address1", $$v);
      },
      expression: "form.address1"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상세주소",
      "disabled": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.address2,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address2", $$v);
      },
      expression: "form.address2"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }