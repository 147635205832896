var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.attendances`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('program-attendee-attendances', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-sheet', _vm._g(_vm._b({
                staticClass: "px-4 py-3",
                attrs: {
                  "color": "transparent"
                }
              }, 'v-sheet', attrs, false), on), [_c('span', [_vm._v(" " + _vm._s(value.filter(function (_ref4) {
                var isAttended = _ref4.isAttended;
                return isAttended;
              }).length) + " / " + _vm._s(_vm.units.length) + " ")]), _c('v-icon', {
                staticClass: "ml-2",
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-clipboard-check")])], 1)];
            }
          }], null, true)
        }, 'program-attendee-attendances', Object.assign({}, item, {
          units: _vm.units
        }), false))];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }