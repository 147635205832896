var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "기본정보"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to;
        return [_c('span', {
          staticClass: "caption"
        }, [_vm._v(" 가입일시: " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDateTime) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디",
      "readonly": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-password-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }, 'v-password-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "닉네임"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "회원명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.genderItems,
      "label": "성별"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.marketingItems,
      "label": "수신여부"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.marketingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.form, "marketingMethod", $$v);
      },
      expression: "form.marketingMethod"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }