<template>
    <v-card outlined v-bind="{ ...$attrs, loading }" style="overflow: hidden">
        <view-section-title v-if="!hideTitle" v-bind="{ title, actionCols }">
            <template #actions>
                <slot name="actions" />
            </template>
        </view-section-title>
        <v-divider v-if="!hideDivider" />
        <slot />
    </v-card>
</template>

<script>
import ViewSectionTitle from "./view-section-title.vue";

export default {
    components: {
        ViewSectionTitle,
    },
    props: {
        title: { type: String },
        loading: { type: Boolean, default: false },
        hideTitle: { type: Boolean, default: false },
        actionCols: { type: [String, Number], default: "auto" },
        hideDivider: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>