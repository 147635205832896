<template>
    <list-search v-bind="{ ...$attrs, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" class="pa-2">
                <v-row align="center" class="ma-n2">
                    <!-- <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-select label="설비유형" v-model="query['attendanceType']" :items="attendanceTypes" placeholder="전체" clearable v-bind="inputAttrs" @input="query['_subjects'] = null" />
                    </v-col> -->
                    <!-- <v-col cols="12" lg="9" class="pa-2"> -->
                    <v-col cols="12" class="pa-2">
                        <v-row align="center" class="ma-n2">
                            <v-col cols="12" sm="6" md="3" lg="2" class="pa-2">
                                <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="2" class="pa-2">
                                <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                            </v-col>
                            <v-col class="pa-2" style="overflow-x: auto">
                                <dates-toggle v-model="query.searchDateValue" :mode="dateToggleMode" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-expand-transition>
                <v-col cols="12" class="pa-0" v-show="query['attendanceType']">
                    <div class="pa-2">
                        <autocomplete-rental-subject v-model="query['_subjects']" placeholder="전체" v-bind="rentalSubjectAttrs">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-rental-subject>
                    </div>
                </v-col>
            </v-expand-transition> -->
        </v-row>
    </list-search>
</template>

<script>
import { COMMON_ATTENDANCE_TYPES, inputAttrs, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import DatesToggle from "@/components/console/dumb/dates-toggle.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["attendanceType"]: query["attendanceType"] ?? null,
    ["searchDateValue"]: [query["searchDateValue"] ?? [null, null]].flat(),

    ["_subjects"]: [query["_subjects"] ?? []].flat(),
});

const attendanceTypes = Object.values(COMMON_ATTENDANCE_TYPES).slice(1);

export default {
    components: {
        ListSearch,
        DatesToggle,
        AutocompleteRentalSubject,
    },

    props: {
        showsSearch: { type: Boolean, default: false },
        dateToggleMode: { type: String, default: null },
    },
    data: () => ({
        query: initQuery(),

        attendanceTypes,
        RENTAL_SUBJECT_TYPES,

        inputAttrs,
    }),

    computed: {
        rentalSubjectAttrs() {
            let label = "설비";
            let params = {};

            let subjectType;
            switch (this.query.attendanceType) {
                case COMMON_ATTENDANCE_TYPES.RENTAL_EQUIPMENTS.value: {
                    subjectType = RENTAL_SUBJECT_TYPES.EQUIPMENTS;
                    break;
                }
                case COMMON_ATTENDANCE_TYPES.RENTAL_FACILITIES.value: {
                    subjectType = RENTAL_SUBJECT_TYPES.FACILITIES;
                    break;
                }
            }
            if (subjectType) {
                label = subjectType.text;
                params = { type: subjectType.value };
            }

            return {
                ...inputAttrs,
                label,
                params,
                multiple: true,
            };
        },
    },

    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            try {
                let { ...query } = this.query;

                if (query["searchDateValue"]?.[0] || query["searchDateValue"]?.[1]) {
                    if (!query["searchDateValue"][0]) throw new Error("기간검색 시작일을 입력해주세요.");
                    if (!query["searchDateValue"][1]) throw new Error("기간검색 종료일을 입력해주세요.");
                } else delete query["searchDateValue"];

                if (!query["attendanceType"]) delete query["attendanceType"];

                if (!query["_subjects"]?.[0]) delete query["_subjects"];

                this.$router.push({ query });
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

<style></style>
