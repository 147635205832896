<template>
    <v-sheet>
        <v-row no-gutters>
            <v-col cols="12" lg="9">
                <form-table-row label="썸네일" class="d-flex d-lg-none">
                    <v-img :aspect-ratio="1 / 1" :src="program?.thumb?.url" class="rounded-xl my-3">
                        <template #placeholder>
                            <v-overlay absolute z-index="0">
                                <v-icon>mdi-image</v-icon>
                            </v-overlay>
                        </template>
                    </v-img>
                </form-table-row>
                <form-table-row label="대상회원유형"> {{ program?.userTypeTexts }} </form-table-row>
                <form-table-row label="모집인원"> {{ program?.capacity }}명 </form-table-row>
                <form-table-row label="접수기간"> {{ program?.application?.period }} </form-table-row>
                <form-table-row label="진행기간"> {{ program?.period }} </form-table-row>
                <form-table-row label="노출기간"> {{ program?.display.period }} </form-table-row>
                <form-table-row label="회차목록" contentClass="px-0">
                    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1" mobile-breakpoint="800">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    </v-data-table>
                </form-table-row>
                <form-table-row label="사용장비" contentClass="px-0">
                    <v-chip-group class="px-3">
                        <template v-for="(item, index) in program?.subjects || []">
                            <v-chip outlined label :key="index">{{ item.name }}</v-chip>
                        </template>
                    </v-chip-group>
                </form-table-row>
            </v-col>
            <v-col cols="auto" class="d-none d-lg-block"> <v-divider vertical /> </v-col>
            <v-col class="d-none d-lg-block">
                <div class="pa-3 subtitle-2 font-weight-bold grey lighten-5">썸네일</div>
                <v-divider />
                <div class="pa-3">
                    <v-img :aspect-ratio="1 / 1" :src="program?.thumb?.url" class="rounded-xl">
                        <template #placeholder>
                            <v-overlay absolute z-index="0">
                                <v-icon>mdi-image</v-icon>
                            </v-overlay>
                        </template>
                    </v-img>
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <div class="pa-3 subtitle-2 font-weight-bold grey lighten-5">상세 설명</div>
        <v-divider />
        <v-card-text>
            <div v-html="program?.detail" />
        </v-card-text>
    </v-sheet>
</template>

<script>
import dayjs from "dayjs";
import FormTableRow from "../dumb/form-table-row.vue";
import FileChips from "@/components/dumb/file-chips.vue";

let days = ["일", "월", "화", "수", "목", "금", "토"];
let headers = [
    { width: +70, text: "#", value: "index", formatter: (value) => `${value}회차` },
    { width: +130, text: "날짜", value: "date", formatter: (value) => value?.toDate?.() || value },
    { width: +80, text: "요일", value: "day" },
    { width: 110, text: "시작시간", value: "startsAt", formatter: (value) => dayjs(value).format("HH:mm") },
    { width: 110, text: "종료시간", value: "endsAt", formatter: (value) => dayjs(value).format("HH:mm") },
    { value: null, text: null },
];

export default {
    components: {
        FormTableRow,
        FileChips,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return (this.program?.units || []).map((unit, index) => ({ ...unit, index: index + 1 }));
        },
    },
};
</script>
