var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    staticClass: "row no-gutters",
    attrs: {
      "draggable": ".image-item"
    },
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }, [_vm._l(_vm.images, function (_ref) {
    var file = _ref.file,
      src = _ref.src;
    return _c('v-col', {
      key: file.name,
      staticClass: "image-item",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "cols": _vm.cols
      }
    }, [_vm.mode == 'menu' ? _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var attrs = _ref2.attrs,
            on = _ref2.on;
          return _c('v-img', _vm._g(_vm._b({
            attrs: {
              "aspect-ratio": 1 / 1,
              "src": src
            },
            on: {
              "mouseover": function ($event) {
                return _vm.$emit('mouseover', file);
              }
            }
          }, 'v-img', attrs, false), on));
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      staticClass: "font-size-14",
      on: {
        "click": function ($event) {
          return _vm.remove(file);
        }
      }
    }, [_vm._v("삭제하기")])], 1)], 1) : _vm._e(), _vm.mode == 'hover' ? _c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          var hover = _ref3.hover;
          return [_c('v-img', {
            attrs: {
              "aspect-ratio": 1 / 1,
              "src": src,
              "content-class": "d-flex align-center justify-center"
            },
            on: {
              "mouseover": function ($event) {
                return _vm.$emit('mouseover', file);
              }
            }
          }, [_c('v-fade-transition', [_c('v-btn', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "icon": "",
              "color": "black"
            },
            on: {
              "click": function ($event) {
                return _vm.remove(file);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1)];
        }
      }], null, true)
    }) : _vm._e()], 1);
  }), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsAddButton,
      expression: "showsAddButton"
    }],
    attrs: {
      "slot": "footer",
      "cols": _vm.cols
    },
    slot: "footer"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "fab": "",
      "color": "grey lighten-2",
      "width": "100%",
      "height": "100%"
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('v-icon', {
    attrs: {
      "size": _vm.addIconSize,
      "color": "primary"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "change": _vm.onChange
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }