<template>
    <v-dialog v-model="shows" max-width="320" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">관리자 생성</template>
                <template v-else>관리자 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.name" v-bind="inputAttrs" label="관리자명" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.username" v-bind="inputAttrs" label="아이디" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-password-field v-model="form.subPassword" v-bind="inputAttrs" label="비밀번호" autocomplete="new-password" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-autocomplete v-model="form.scope" v-bind="inputAttrs" :items="scopeItems" label="권한" multiple chips small-chips :hide-details="false" messages="※ 기본권한 미 설정 시 관리자 기능 사용이 불가능합니다." class="mb-n2">
                            <template #message="{ message }">
                                <div style="line-height: 1.5">{{ message }}</div>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, initManager, MANAGER_SCOPES, USER_TYPES } from "@/assets/variables";

import cryptoAes from "@/plugins/crypto-aes";
import vPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

const scopeItems = Object.values(MANAGER_SCOPES);

export default {
    components: { vPasswordField },
    props: {
        value: { type: Object, default: initManager },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initManager(),

        scopeItems,

        inputAttrs,

        JSON,
    }),
    computed: {
        _user() {
            return this.value?._id;
        },
        isCreate() {
            return !this._user;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initManager();
                } else {
                    let { user } = await api.console.users.get({ _id: this._user });

                    this.form = initManager(user);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initManager(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { subPassword, ...form } = this.form;

                form.subPassword = cryptoAes.encrypt(subPassword);

                let { post, put } = api.console.users;
                let { user } = await (this.isCreate ? post(form) : put(form));

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", user);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-chip {
        margin: 4px !important;
    }
}
</style>