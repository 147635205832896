var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1300px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.isCreate ? '금산에 활력을 불어넣는 사람들 등록' : '금산에 활력을 불어넣는 사람들 상세'
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "사람들 타입 설정",
            "items": _vm.peopleTypeItems
          },
          model: {
            value: _vm.people.type,
            callback: function ($$v) {
              _vm.$set(_vm.people, "type", $$v);
            },
            expression: "people.type"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1)];
      },
      proxy: true
    }])
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "기본정보"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "label": "메인 설정"
          },
          model: {
            value: _vm.people.isMain,
            callback: function ($$v) {
              _vm.$set(_vm.people, "isMain", $$v);
            },
            expression: "people.isMain"
          }
        }, 'v-switch', Object.assign({}, _vm.switchAttrs), false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "number",
      "label": "순서"
    },
    model: {
      value: _vm.people.index,
      callback: function ($$v) {
        _vm.$set(_vm.people, "index", $$v);
      },
      expression: "people.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.people.name,
      callback: function ($$v) {
        _vm.$set(_vm.people, "name", $$v);
      },
      expression: "people.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "한줄 설명"
    },
    model: {
      value: _vm.people.summary,
      callback: function ($$v) {
        _vm.$set(_vm.people, "summary", $$v);
      },
      expression: "people.summary"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1)], 1), _c('v-card-text', [_c('v-divider'), _c('v-card-subtitle', [_vm._v("상세내용")]), _c('v-divider'), _c('people-details', {
    model: {
      value: _vm.people.details,
      callback: function ($$v) {
        _vm.$set(_vm.people, "details", $$v);
      },
      expression: "people.details"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "썸네일 등록"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('image-input', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "accept": "image/*",
      "width": "280",
      "height": "230",
      "outlined": ""
    },
    model: {
      value: _vm.people.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.people, "thumb", $$v);
      },
      expression: "people.thumb"
    }
  })], 1)], 1)], 1), !_vm.isGroup ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "멤버 이미지 슬라이드"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-select', _vm._b({
          attrs: {
            "items": _vm.groupItems,
            "label": "그룹 설정"
          },
          model: {
            value: _vm.people._group,
            callback: function ($$v) {
              _vm.$set(_vm.people, "_group", $$v);
            },
            expression: "people._group"
          }
        }, 'v-select', Object.assign({}, _vm.inputAttrs), false))];
      },
      proxy: true
    }], null, false, 3542777968)
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('images-input', {
    model: {
      value: _vm.people.images,
      callback: function ($$v) {
        _vm.$set(_vm.people, "images", $$v);
      },
      expression: "people.images"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }