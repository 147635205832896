var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pl-6",
    attrs: {
      "cols": "3"
    }
  }, [_c('image-input', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "accept": "image/*",
      "width": "140",
      "height": "140",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.image,
      callback: function ($$v) {
        _vm.$set(_vm.form, "image", $$v);
      },
      expression: "form.image"
    }
  })], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "align-self": "center"
    }
  }, [_c('v-col', [_c('v-file-input', _vm._b({
    attrs: {
      "show-size": "",
      "accept": "image/*",
      "label": "파일명"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.image,
      callback: function ($$v) {
        _vm.$set(_vm.form, "image", $$v);
      },
      expression: "form.image"
    }
  }, 'v-file-input', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }