var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item.text,
      attrs: {
        "exact": item.to.path == _vm.path,
        "exact-path": item.to.path != _vm.path,
        "to": item.to
      }
    }, [_vm._v(_vm._s(item.text))])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }