var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program2, _vm$program3, _vm$program4, _vm$program4$applicat, _vm$program5, _vm$program6, _vm$program7, _vm$program8, _vm$program8$thumb, _vm$program9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('form-table-row', {
    staticClass: "d-flex d-lg-none",
    attrs: {
      "label": "썸네일"
    }
  }, [_c('v-img', {
    staticClass: "rounded-xl my-3",
    attrs: {
      "aspect-ratio": 1 / 1,
      "src": (_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "대상회원유형"
    }
  }, [_vm._v(" " + _vm._s((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : _vm$program2.userTypeTexts) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "모집인원"
    }
  }, [_vm._v(" " + _vm._s((_vm$program3 = _vm.program) === null || _vm$program3 === void 0 ? void 0 : _vm$program3.capacity) + "명 ")]), _c('form-table-row', {
    attrs: {
      "label": "접수기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program4 = _vm.program) === null || _vm$program4 === void 0 ? void 0 : (_vm$program4$applicat = _vm$program4.application) === null || _vm$program4$applicat === void 0 ? void 0 : _vm$program4$applicat.period) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "진행기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program5 = _vm.program) === null || _vm$program5 === void 0 ? void 0 : _vm$program5.period) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "노출기간"
    }
  }, [_vm._v(" " + _vm._s((_vm$program6 = _vm.program) === null || _vm$program6 === void 0 ? void 0 : _vm$program6.display.period) + " ")]), _c('form-table-row', {
    attrs: {
      "label": "회차목록",
      "contentClass": "px-0"
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1,
      "mobile-breakpoint": "800"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1), _c('form-table-row', {
    attrs: {
      "label": "사용장비",
      "contentClass": "px-0"
    }
  }, [_c('v-chip-group', {
    staticClass: "px-3"
  }, [_vm._l(((_vm$program7 = _vm.program) === null || _vm$program7 === void 0 ? void 0 : _vm$program7.subjects) || [], function (item, index) {
    return [_c('v-chip', {
      key: index,
      attrs: {
        "outlined": "",
        "label": ""
      }
    }, [_vm._v(_vm._s(item.name))])];
  })], 2)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-none d-lg-block"
  }, [_c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("썸네일")]), _c('v-divider'), _c('div', {
    staticClass: "pa-3"
  }, [_c('v-img', {
    staticClass: "rounded-xl",
    attrs: {
      "aspect-ratio": 1 / 1,
      "src": (_vm$program8 = _vm.program) === null || _vm$program8 === void 0 ? void 0 : (_vm$program8$thumb = _vm$program8.thumb) === null || _vm$program8$thumb === void 0 ? void 0 : _vm$program8$thumb.url
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("상세 설명")]), _c('v-divider'), _c('v-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$program9 = _vm.program) === null || _vm$program9 === void 0 ? void 0 : _vm$program9.detail)
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }