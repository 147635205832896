var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3",
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "참석 목록"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('attendance-list-qrscan', _vm._g({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mt-n2 mb-n1",
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": "",
                  "color": "primary",
                  "title": "QR"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-qrcode-scan")])], 1)];
            }
          }])
        }, {
          search: _vm.search
        }))];
      },
      proxy: true
    }])
  }), _c('attendance-list-filter', _vm._b({
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, 'attendance-list-filter', {
    type: _vm.type
  }, false)), _c('v-row', {
    staticClass: "ma-n3"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.excel
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "tile": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-microsoft-excel")]), _c('span', [_vm._v("엑셀 다운로드")])], 1)], 1)], 1), _c('v-spacer'), _c('v-col', {
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('rental-request-list-sort', {
    staticClass: "subtitle-2 v-sheet--outlined",
    attrs: {
      "solo": "",
      "flat": "",
      "outlined": false
    }
  })], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined mt-3",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('attendance-list-types'), _vm._v(" "), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.user.blacksmith.insurance.policies`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [value !== null && value !== void 0 && value.length ? [_c('image-tiles', {
          attrs: {
            "images": (value || []).map(function (_ref4) {
              var url = _ref4.url;
              return url;
            }),
            "tilesOnly": "",
            "cols": "12"
          }
        })] : [_vm._v(" - ")]];
      }
    }, {
      key: `item.user.blacksmith.equipmentUseConsent`,
      fn: function (_ref5) {
        var value = _ref5.value;
        return [value ? [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.src,
            "rounded": "",
            "outlined": ""
          }
        })] : [_vm._v(" - ")]];
      }
    }, {
      key: `item.isAttended`,
      fn: function (_ref6) {
        var item = _ref6.item,
          value = _ref6.value,
          header = _ref6.header;
        return [_c('attendance-edit-is-attended', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_vm._v(" " + _vm._s(header.formatter(value)) + " ")])];
      }
    }, {
      key: `item.attendedAt`,
      fn: function (_ref7) {
        var item = _ref7.item,
          value = _ref7.value,
          header = _ref7.header;
        return [_c('attendance-edit-attended-at', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_vm._v(" " + _vm._s(header.formatter(value)) + " ")])];
      }
    }, {
      key: `item.departedAt`,
      fn: function (_ref8) {
        var item = _ref8.item,
          value = _ref8.value,
          header = _ref8.header;
        return [_c('attendance-edit-departed-at', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_vm._v(" " + _vm._s(header.formatter(value)) + " ")])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 20
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }