<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="사용금지 목록" showsFilterButton>
                <template #add-button>
                    <user-ban-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="사용금지 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-ban-form>
                </template>
            </list-heading>

            <user-ban-list-search v-bind="{ type, showsSearch }" flat outlined />

            <v-row class="ma-n3">
                <v-col cols="auto">
                    <v-card outlined style="overflow: hidden" @click="excel">
                        <v-btn text tile color="green">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            <span>엑셀 다운로드</span>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
            </v-row>

            <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-4" style="overflow: hidden">
                <template #top> <user-ban-list-types /> <v-divider /> </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.isActive`]="{ item }">
                    <v-switch v-model="item.isActive" class="d-inline-block mr-n4" v-bind="switchAttrs" @change="(isActive) => update({ _id: item._id, isActive })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <user-ban-form :value="item" @input="(item) => update(item)">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </user-ban-form>

                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { switchAttrs, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserBanForm from "@/components/console/user-bans/user-ban-form.vue";
import UserBanListTypes from "@/components/console/user-bans/user-ban-list-types.vue";
import UserBanListSearch from "@/components/console/user-bans/user-ban-list-search.vue";
import dayjs from "dayjs";

const headers = [
    { text: "#", value: "index" },
    // 회원정보
    { text: "회원유형", value: "user.type", formatter: (value) => ({ [USER_TYPES.INDIVIDUAL]: "개인회원", [USER_TYPES.ORGANIZATION]: "기업회원" }[value] || "관리자") },
    { text: "기업명", value: "user.companyName" },
    { text: "회원명", value: "user.name" },
    { text: "아이디", value: "user.username" },
    { text: "연락처", value: "user.phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-" },
    { text: "이메일", value: "user.email" },

    // 금지상세
    { text: "설비명", value: "subject.name" },
    { text: "불참일", value: "date", formatter: (value) => value?.toDate?.() || value || "-" },
    { text: "누적불참수", value: "count", formatter: (value) => (value ? `${value?.format?.() || value}건` : "-"), width: 100 },
    {
        text: "금지일수",
        value: "policy.punish.days",
        formatter: function () {
            if (this.endsAt && this.startsAt) {
                let days = (dayjs(this.endsAt).diff(this.startsAt, "hours") / 24).toFixed(1);
                days = days.replace(".0", "");
                return `${days}일`;
            }

            return "-";
        },
        width: 100,
    },
    {
        text: "사용금지",
        value: "ban",
        formatter: function (value, item, isExcel = false) {
            const { startsAt, endsAt } = this || {};
            let ban = "";
            ban += `${startsAt?.toDateTime?.() || "시작일시없음"}　\r\n`;
            ban += `~ ${endsAt?.toDateTime?.() || "종료일시없음"}`;
            if (isExcel) ban = ban.replace("　\r\n", " ");
            return ban;
        },
        width: 180,
    },

    { text: "사용여부", value: "isActive", width: +90, excel: (value) => (value ? "사용" : "미사용") },
    { text: "", value: "actions", align: "right", width: +88 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,
        UserBanForm,
        UserBanListTypes,
        UserBanListSearch,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        bans: [],
        summary: { totalCount: 0 },

        limit: 10,
        loading: false,
        showsSearch: true,

        headers,
        switchAttrs,
    }),
    computed: {
        items() {
            return this.bans.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query["type"] = this.type;
            if (!query["type"]) delete query["type"];

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;

                var { summary, bans } = await api.console.users.bans.gets({
                    headers: { skip, limit },
                    params,
                });

                this.bans = bans;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { ban } = await api.console.users.bans.put(item);
                this.updateItem(ban);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async remove(item) {
            if (this.loading) return;
            else {
                const go = confirm(`해당 사용금지를 삭제하시겠습니까?`);
                if (go) {
                    this.loading = true;
                    try {
                        const { ban } = await api.console.users.bans.delete(item);
                        this.bans = this.bans.filter(({ _id }) => _id !== ban._id);
                        this.summary.totalCount -= 1;
                        alert(`해당 사용금지가 삭제되었습니다.`);
                    } finally {
                        this.loading = false;
                    }
                }
            }
        },

        updateItem(ban) {
            const index = this.bans.findIndex(({ _id }) => _id == ban._id);
            if (0 <= index) this.bans.splice(index, 1, ban);
            else {
                this.bans = [ban, ...this.bans];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { bans } = await api.console.users.bans.gets({
                    headers: {},
                    params,
                });

                this.$excel(bans, headers, "사용금지목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
