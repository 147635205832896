<template>
    <list-search showsSearch v-bind="$attrs" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="타입" v-model="query['type']" :items="peopleItems" placeholder="전체" v-bind="inputAttrs" @change="emit" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="그룹" v-model="query['_group']" :items="groupItems" placeholder="전체" v-bind="inputAttrs" @change="emit" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <v-col cols="4" sm="4" lg="4" class="pa-2">
                <v-select label="검색명" :items="searchKeys" v-model="query['searchKey']" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="8" sm="8" lg="8" class="pa-2">
                <v-text-field label="검색어" v-model="query['searchValue']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>

            <!-- <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field label="제목" v-model="query['title']" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field label="연락처" v-model="query['phone']" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="4" class="pa-2">
                <v-text-field label="캐치프라이즈" v-model="query['catchphrase.content']" v-bind="inputAttrs" />
            </v-col> -->
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { inputAttrs, PEOPLE_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

const searchKeys = [
    { text: "전체", value: null },
    { text: "제목", value: "name" },
    { text: "한줄설명", value: "summary" },
];

let initQuery = (query = {}) => ({
    ...query,

    type: query?.type || null,
    _group: query?._group || null,
    searchKey: query?.searchKey || null,
    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],
});

export default {
    components: {
        ListSearch,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        query: initQuery(),
        searchKeys,
        peopleItems: [{ text: "전체", value: null }, ...Object.values(PEOPLE_TYPES)],
        groupItems: [],

        inputAttrs,
    }),
    async mounted() {
        await this.init();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            await this.search();
            this.sync();
        },
        async search() {
            try {
                let { people } = await api.console.people.gets({ params: { type: PEOPLE_TYPES.GROUP.value } });
                this.groupItems = [{ text: "전체", value: null }, ...people.map(({ _id, name }) => ({ text: name, value: _id }))];
            } catch (error) {
                console.error(error);
            }
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["type"]) delete query["type"];
            if (!query["_group"]) delete query["_group"];
            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            if (!query["searchDateValue"]?.[0] && !query["searchDateValue"]?.[1]) {
                delete query["searchDateValue"];
            }

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
