<template>
    <v-edit-dialog :return-value.sync="form.isAttended" large cancel-text="취소" save-text="저장" v-on="{ save }" @open="sync">
        <slot />
        <template #input>
            <v-sheet width="240" class="mt-5">
                <v-select v-model="form.isAttended" v-bind="{ ...inputAttrs, items }" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import { initCommonAttendance, inputAttrs } from "@/assets/variables";

const items = [
    { text: "-", value: null },
    { text: "참석", value: true },
    { text: "불참", value: false },
];

export default {
    components: {
        VDatetimeField,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        form: initCommonAttendance(),

        items,
        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initCommonAttendance(this.value);
        },
        save() {
            let { _id, isAttended } = this.form;
            let form = { _id, isAttended };

            this.$emit("update", form);
        },
    },
};
</script>

