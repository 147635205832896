var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "경제자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.economy,
      callback: function ($$v) {
        _vm.$set(_vm.form, "economy", $$v);
      },
      expression: "form.economy"
    }
  })], 1), _c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "시설자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.facility,
      callback: function ($$v) {
        _vm.$set(_vm.form, "facility", $$v);
      },
      expression: "form.facility"
    }
  })], 1), _c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "역사문화자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.history,
      callback: function ($$v) {
        _vm.$set(_vm.form, "history", $$v);
      },
      expression: "form.history"
    }
  })], 1), _c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "경관자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.landscape,
      callback: function ($$v) {
        _vm.$set(_vm.form, "landscape", $$v);
      },
      expression: "form.landscape"
    }
  })], 1), _c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "마을소유자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.village,
      callback: function ($$v) {
        _vm.$set(_vm.form, "village", $$v);
      },
      expression: "form.village"
    }
  })], 1), _c('v-col', {
    staticClass: "px-5",
    attrs: {
      "cols": "6"
    }
  }, [_c('story-view-resources-form', {
    attrs: {
      "title": "주변연계자원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.around,
      callback: function ($$v) {
        _vm.$set(_vm.form, "around", $$v);
      },
      expression: "form.around"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }