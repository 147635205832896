var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "label": "노출"
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.shows,
            callback: function ($$v) {
              _vm.$set(_vm.form, "shows", $$v);
            },
            expression: "form.shows"
          }
        }, 'v-switch', Object.assign({}, _vm.inputAttrs), false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-col', [_c('v-textarea', _vm._b({
    attrs: {
      "label": "설명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  }, 'v-textarea', Object.assign({}, _vm.inputAttrs), false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }