var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('v-card', [_c('v-card-title', [_vm._v("예약신청 신청내용")]), _c('v-card-text', {
    staticClass: "mt-n4 pa-6 caption"
  }, [_vm._l(_vm.items, function (_ref2, index) {
    var term = _ref2.term,
      desc = _ref2.desc;
    return [_c('v-row', {
      key: index,
      staticClass: "ma-n4 text-start",
      staticStyle: {
        "word-break": "keep-all"
      }
    }, [_c('v-col', {
      staticClass: "px-4",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(term) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(desc) + " ")])], 1)];
  }), !_vm.items.length ? [_vm._v(" - ")] : _vm._e()], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }