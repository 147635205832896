<template>
    <v-dialog v-model="shows" max-width="900" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">설비 생성</template>
                <template v-else>설비 상세</template>
                <rental-subject-form-clone v-model="form">
                    <template #activator="{ attrs, on }">
                        <v-btn outlined small class="mx-2" v-bind="attrs" v-on="on">
                            <v-icon small class="mr-1">mdi-folder-open-outline</v-icon>
                            불러오기
                        </v-btn>
                    </template>
                </rental-subject-form-clone>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="flex: 0 1 auto; overflow-y: auto">
                <v-row no-gutters>
                    <v-col cols="12" sm="4" md="3">
                        <v-card-subtitle class="grey lighten-5">썸네일</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <image-input v-model="form.thumb" flat tile :aspect-ratio="1 / 1" @input="emit" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="auto"> <v-divider class="d-none d-sm-block" vertical /> <v-divider class="d-block d-sm-none" /> </v-col>
                    <v-col>
                        <v-card-subtitle class="grey lighten-5">기본 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters class="ma-n2">
                                <v-col class="pa-2" cols="6"> <v-text-field label="설비명(국문)" v-model="form.name" v-bind="inputAttrs" @input="emit" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-text-field label="설비명(영문)" v-model="form.code" v-bind="inputAttrs" @input="emit" /> </v-col>
                                <v-col class="pa-2" cols="6"> <v-select label="설비유형" v-model="form.type" :items="subjectTypes" v-bind="inputAttrs" :disabled="!!form._id" @input="emit" /> </v-col>
                                <template v-if="form.type == RENTAL_SUBJECT_TYPES.EQUIPMENTS.value">
                                    <v-col class="pa-2" cols="6"> <autocomplete-rental-subject-places v-model="form.place" label="사용장소" v-bind="inputAttrs" @input="emit" /> </v-col>
                                </template>
                                <template v-if="form.type == RENTAL_SUBJECT_TYPES.FACILITIES.value">
                                    <v-col class="pa-2" cols="6"> <v-text-field label="사용장소" v-model="form.place" v-bind="inputAttrs" @input="emit" /> </v-col>
                                </template>
                                <v-col class="pa-2" cols="12"> <v-textarea label="사용용도" v-model="form.desc" rows="2" auto-grow v-bind="inputAttrs" @input="emit" /> </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12"> <v-divider /> </v-col>
                    <v-col cols="12">
                        <form-table-row label="사용가능방식" cols="3" md="2" contentClass="px-0">
                            <v-select v-model="form.rental.type" :items="rentalTypes" v-bind="attrs_input__verticalTable" @input="emit" />
                        </form-table-row>
                        <form-table-row label="사용가능요일" cols="3" md="2" contentClass="pr-0">
                            <form-rental-days v-model="form.rental.businessDays" @input="emit" />
                        </form-table-row>
                        <v-expand-transition>
                            <form-table-row v-show="form.rental.type == RENTAL_TYPES.BY_TIME.value" label="사용가능시간" cols="3" md="2" contentClass="pa-0">
                                <form-rental-times v-model="form.rental.businessTimes" :days="form.rental.businessDays" @input="emit" />
                            </form-table-row>
                        </v-expand-transition>
                        <v-expand-transition>
                            <form-table-row v-show="form.rental.type == RENTAL_TYPES.BY_HOUR.value" label="사용가능시간" cols="3" md="2" contentClass="pr-1">
                                <form-rental-hours v-model="form.rental.businessHours" @input="emit" />
                            </form-table-row>
                        </v-expand-transition>
                        <form-table-row label="사용불가일자" cols="3" md="2">
                            <v-chip-group v-model="form.rental.holidays" column @input="emit">
                                <v-chip v-for="holiday in form.rental.holidays" :key="holiday" close @click:close="form.rental.holidays = form.rental.holidays.filter((item) => item != holiday)">{{ holiday.toDate() }}</v-chip>
                                <v-btn text icon class="align-self-center form-holidays-picker"> <v-icon> mdi-plus-circle </v-icon> </v-btn>
                            </v-chip-group>
                            <v-dialog width="300" activator=".form-holidays-picker">
                                <v-date-picker v-model="form.rental.holidays" multiple class="v-sheet--outlined" @input="emit" />
                            </v-dialog>
                        </form-table-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.rental.isActive" v-bind="switchAttrs" @input="emit">
                        <template #label> <span class="subtitle-2">예약하기</span> </template>
                    </v-switch>
                </v-col>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.shows" v-bind="switchAttrs" @input="emit">
                        <template #label> <span class="subtitle-2">노출</span> </template>
                    </v-switch>
                </v-col>
                <v-fade-transition>
                    <v-col cols="auto" class="pa-0" style="min-width: 140px" v-show="form.shows">
                        <v-currency-field v-model="form.index" label="노출순번" v-bind="inputAttrs" @input="emit" />
                    </v-col>
                </v-fade-transition>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, RENTAL_SUBJECT_TYPES, initRentalSubject, RENTAL_TYPES, attrs_input__verticalTable } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";
import FormTableRow from "@/components/console/dumb/form-table-row.vue";
import FormRentalDays from "./form-rental-days.vue";
import FormRentalHours from "./form-rental-hours.vue";
import FormRentalTimes from "./form-rental-times.vue";
import RentalSubjectFormClone from "./rental-subject-form-clone.vue";
import AutocompleteRentalSubjectPlaces from "@/components/dumb/autocomplete-rental-subject-places.vue";

const rentalTypes = Object.values(RENTAL_TYPES);
const subjectTypes = Object.values(RENTAL_SUBJECT_TYPES);

export default {
    components: {
        ImageInput,
        FormTableRow,
        FormRentalDays,
        FormRentalHours,
        FormRentalTimes,
        RentalSubjectFormClone,
        AutocompleteRentalSubjectPlaces,
    },
    props: {
        value: { type: Object, default: initRentalSubject },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initRentalSubject(),

        rentalTypes,
        subjectTypes,
        RENTAL_TYPES,
        RENTAL_SUBJECT_TYPES,

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,
    }),
    computed: {
        _subject() {
            return this.value?._id;
        },
        isCreate() {
            return !this._subject;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initRentalSubject();
                } else {
                    let { subject } = await api.console.rental.subjects.get({ _id: this._subject });

                    this.form = initRentalSubject(subject);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initRentalSubject(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { _thumb, thumb, ...form } = this.form;

                // POST/PUT subject
                let { post, put } = api.console.rental.subjects;
                let { subject } = await (this.isCreate ? post(form) : put(form));
                let _subject = subject?._id;

                // DELETE/POST thumb
                if (thumb instanceof File) thumb = (await api.console.rental.subjects.thumb.post({ _subject }, thumb))?.thumb;
                _thumb = thumb?._id || null;

                // PUT subject
                subject = (await put({ _id: _subject, _thumb }))?.subject;

                alert("저장되었습니다");
                this.$emit("input", subject);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
