<template>
    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.state`]="{ item, value }">
            <v-edit-dialog :return-value.sync="item.state" large cancel-text="취소" save-text="변경" @open="state = value" @save="putStateOf(item)">
                {{ PROGRAM_APPLICATION_STATES[value]?.text }} <v-icon small class="ml-2"> mdi-pencil</v-icon>
                <template #input> <v-select v-model="state" :items="Object.values(PROGRAM_APPLICATION_STATES)" v-bind="inputAttrs" class="mt-3" /> </template>
            </v-edit-dialog>
        </template>

        <template #[`item.actions`]="{ item }">
            <program-applications-view :value="item" @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile v-bind="attrs" v-on="on"> <v-icon>mdi-pencil</v-icon> </v-btn>
                </template>
            </program-applications-view>
            <!-- <v-btn text icon tile :disabled="isDisabled(item)" @click="remove(item)"> <v-icon>mdi-delete</v-icon> </v-btn> -->
        </template>

        <template #footer>
            <v-divider />
            <v-row justify="end" no-gutters>
                <v-col cols="auto">
                    <program-applications-view @input="$router.go()">
                        <template #activator="{ attrs, on }">
                            <v-btn large text tile color="primary" v-bind="attrs" v-on="on"> 신청서 생성 <v-icon class="ml-1">mdi-plus</v-icon> </v-btn>
                        </template>
                    </program-applications-view>
                </v-col>
            </v-row>
            <v-divider />
            <v-pagination :value="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { inputAttrs, PROGRAM_APPLICATION_STATES } from "@/assets/variables";

import ProgramApplicationsView from "./program-applications-view.vue";

let headers = [
    { text: "신청일", value: "appliedAt", cellClass: "caption", formatter: (value) => value?.toDateTime?.() || value },
    { text: "아이디", value: "user.username" },
    { text: "이름", value: "user.name" },
    { text: "연락처", value: "user.phone" },
    { text: "이메일", value: "user.email" },
    { text: "상태", value: "state" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ProgramApplicationsView,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        applications: [],
        state: PROGRAM_APPLICATION_STATES.APPLIED.value,

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        inputAttrs,
        PROGRAM_APPLICATION_STATES,
    }),
    computed: {
        items() {
            return this.applications.map((item) => {
                let { affiliation, department, courseName, phone, email } = item?.applicant || {};

                let applicantAffiliation = `${affiliation || "-"}\n${department || "-"} / ${courseName || "-"}`;

                let applicantContactInfo = `${phone || "-"}\n${email || "-"}`;

                return {
                    ...item,
                    applicantAffiliation,
                    applicantContactInfo,
                };
            });
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "program._id"() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            let _program = this.program?._id;
            if (_program) {
                let { skip, limit } = this;
                let { summary, applications } = await api.console.programs.applications.gets({
                    headers: { skip, limit },
                    _program,
                });
                this.summary = summary;
                this.applications = applications;
            }
        },
        async putStateOf({ _id, _program }) {
            let go = confirm("해당 신청서의 상태를 변경하시겠습니까?");
            if (go) {
                let { state } = this;
                let { application } = await api.console.programs.applications.put({ _id, _program, state });
                this.update(application);
            }
        },
        async remove({ _id, _program }) {
            let go = confirm("해당 신청서의 상태를 변경하시겠습니까?");
            if (go) {
                await api.console.programs.applications.delete({ _id, _program });
                this.$router.go();
            }
        },
        update(item) {
            let index = this.applications.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.applications.splice(index, 1, item);
            } else {
                this.applications.splice(0, 0, item);
            }
        },

        isDisabled({ state }) {
            let { APPROVED } = PROGRAM_APPLICATION_STATES;
            return [APPROVED].map(({ value }) => value).includes(state);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .white-space-pre-line {
    white-space: pre-line;
}
</style>