<template>
    <v-tabs>
        <template v-for="item in items">
            <v-tab :exact="item.to.path == path" :exact-path="item.to.path != path" :to="item.to" :key="item.text">{{ item.text }}</v-tab>
        </template>
        <slot />
    </v-tabs>
</template>

<script>
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

const path = "/console/rental-subjects";
const items = [{ text: "전체", to: { path } }].concat(Object.values(RENTAL_SUBJECT_TYPES).map(({ text, value }) => ({ text, to: { path: `${path}/${value}` } })));

export default {
    data: () => ({
        path,
        items,
    }),
};
</script>

<style>
</style>