<template>
    <v-col cols="12" sm="7" class="pa-0 mb-0">
        <v-text-field dense hide-details class="mt-6 ml-6" label="검색" persistent-placeholder v-model="search" append-icon="mdi-magnify" />
        <v-data-table v-if="variables" :headers="variablesHeaders" :items="variables" items-per-page="6" dense class="mt-5 ml-6">
            <template v-slot:[`item.name`]="{ item }">
                <span>{{ item.name }}</span>
                <v-icon x-small class="ml-2" @click="copy($event)">mdi-content-copy</v-icon>
            </template>
        </v-data-table>
    </v-col>
</template>
<script>
export default {
    data() {
        return {
            variablesHeaders: [
                { text: "변수명", value: "name" },
                { text: "설명", value: "desc" },
            ],
            variables: [
                // 구매자 정보
                { name: "#{order.sender.name}", desc: "구매자 이름" },
                { name: "#{order.sender.phone}", desc: "구매자 전화번호" },
                { name: "#{order.sender.email}", desc: "구매자 이메일" },
                { name: "#{order.sender.postcode}", desc: "구매자 우편번호" },
                { name: "#{order.sender.address1}", desc: "구매자 기본주소" },
                { name: "#{order.sender.address2}", desc: "구매자 상세주소" },
                // 수령자 정보
                { name: "#{order.receiver.name}", desc: "수령자 이름" },
                { name: "#{order.receiver.phone}", desc: "수령자 전화번호" },
                { name: "#{order.receiver.email}", desc: "수령자 이메일" },
                { name: "#{order.receiver.postcode}", desc: "수령자 우편번호" },
                { name: "#{order.receiver.address1}", desc: "수령자 기본주소" },
                { name: "#{order.receiver.address2}", desc: "수령자 상세주소" },
                // 주문 정보
                { name: "#{order.createDate}", desc: "주문일자" },
                { name: "#{order.orderNo}", desc: "주문번호" },
                { name: "#{order.totalAmount}", desc: "주문 총 결제금액" },
                { name: "#{order.totalDiscountAmount}", desc: "주문 총 할인금액" },
                { name: "#{order.totalCouponAmount}", desc: "주문 총 쿠폰할인금액" },
                { name: "#{order.totalPointAmount}", desc: "주문 총 포인트사용금액" },
                { name: "#{order.totalIslandAmount}", desc: "주문 총 도서산간금액" },
                { name: "#{order.account.bankCode}", desc: "은행코드" },
                { name: "#{order.account.accountHolder}", desc: "예금주" },
                { name: "#{order.account.accountNumber}", desc: "계좌번호" },
                { name: "#{order.account.accountNumber}", desc: "계좌번호" },
                // 구매 정보
                { name: "#{purchase.purchaseNo}", desc: "상품구매번호" },
                { name: "#{purchase.product.name}", desc: "상품명" },
                { name: "#{purchase.product.price}", desc: "기본가" },
                { name: "#{purchase.product.salePrice}", desc: "판매가" },
                { name: "#{purchase.orderStatus}", desc: "주문상태" },
                { name: "#{purchase.claimStatus}", desc: "클레임상태" },
                { name: "#{purchase.purchaseStatus}", desc: "구매확정상태" },
                { name: "#{purchase.shippingDate}", desc: "배송일자" },
                { name: "#{purchase.delivery.method}", desc: "배송방법" },
                { name: "#{purchase.delivery.company}", desc: "배송회사" },
                { name: "#{purchase.delivery.number}", desc: "운송장번호" },
                { name: "#{purchase.confirmDate}", desc: "발주일자" },
                { name: "#{purchase.quantity}", desc: "구매수량" },
                { name: "#{purchase.name}", desc: "옵션명" },
                // 사용자 정보
                { name: "#{user.name}", desc: "사용자 이름" },
                { name: "#{user.username}", desc: "사용자 아이디" },
                { name: "#{user.phone}", desc: "사용자 전화번호" },
                { name: "#{user.email}", desc: "사용자 이메일" },
                { name: "#{user.postcode}", desc: "사용자 우편번호" },
                { name: "#{user.address1}", desc: "사용자 기본주소" },
                { name: "#{user.address2}", desc: "사용자 상세주소" },
                { name: "#{user.createDate}", desc: "사용자 회원가입일" },
                { name: "#{user.block}", desc: "사용자 차단여부" },
                { name: "#{user.warning}", desc: "사용자 주의회원여부" },
                { name: "#{user.isWithdrawal}", desc: "사용자 탈퇴여부" },
                { name: "#{user.withdrawnAt}", desc: "사용자 탈퇴일" },
            ],
            search: "",
        };
    },
    methods: {
        copy(event) {
            var element = event.target.previousSibling;
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand("copy");

            if (document.selection) document.selection.empty();
            else if (window.getSelection) window.getSelection().removeAllRanges();
        },
    },
};
</script>
