<template>
    <v-dialog v-model="shows" max-width="480">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                참석현황 <v-spacer /> <v-btn text icon tile @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.hasUserAttended`]="{ value }">
                    <span v-if="value === null || value === undefined"> 대기 </span>
                    <span v-else-if="value === true" class="primary--text"> 참석 </span>
                    <span v-else-if="value === false" class="pink--text text--lighten-1"> 불참 </span>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";

let headers = [
    { text: "회차", value: "number" },
    { text: "날짜", value: "date", formatter: (value) => dayjs(value).format("YYYY-MM-DD") },
    { text: "시간", value: "time" },
    { text: "참석상태", value: "hasUserAttended" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    props: {
        units: { type: Array, default: () => [] },
        attendances: { type: Array, default: () => [] },
    },
    data() {
        return {
            shows: false,
            headers,
        };
    },
    computed: {
        items() {
            return this.units.map((item, index) => {
                let hasUserAttended = (this.attendances || []).find(({ _unit }) => _unit == item._id)?.isAttended;

                if (dayjs().isBefore(item.endsAt) && hasUserAttended == false) {
                    hasUserAttended = undefined;
                }

                return {
                    ...item,
                    number: index + 1,
                    hasUserAttended,
                };
            });
        },
    },
};
</script>
