var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4",
      "align-self": "center"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "상단고정"
    },
    model: {
      value: _vm.board.isNotice,
      callback: function ($$v) {
        _vm.$set(_vm.board, "isNotice", $$v);
      },
      expression: "board.isNotice"
    }
  }, 'v-switch', Object.assign({}, _vm.inputAttrs), false))], 1), !_vm.isNotification ? _c('v-col', {
    staticClass: "py-0 pt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "동영상 링크"
    },
    model: {
      value: _vm.board.youtubeCode,
      callback: function ($$v) {
        _vm.$set(_vm.board, "youtubeCode", $$v);
      },
      expression: "board.youtubeCode"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }