var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "tile": "",
      "max-height": "90vh"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm.isCreate ? [_vm._v("관리자 생성")] : [_vm._v("관리자 수정")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6",
    staticStyle: {
      "flex": "0 1 100%",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "관리자명"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디"
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-password-field', _vm._b({
    attrs: {
      "label": "비밀번호",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.form.subPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subPassword", $$v);
      },
      expression: "form.subPassword"
    }
  }, 'v-password-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    staticClass: "mb-n2",
    attrs: {
      "items": _vm.scopeItems,
      "label": "권한",
      "multiple": "",
      "chips": "",
      "small-chips": "",
      "hide-details": false,
      "messages": "※ 기본권한 미 설정 시 관리자 기능 사용이 불가능합니다."
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function (_ref2) {
        var message = _ref2.message;
        return [_c('div', {
          staticStyle: {
            "line-height": "1.5"
          }
        }, [_vm._v(_vm._s(message))])];
      }
    }]),
    model: {
      value: _vm.form.scope,
      callback: function ($$v) {
        _vm.$set(_vm.form, "scope", $$v);
      },
      expression: "form.scope"
    }
  }, 'v-autocomplete', _vm.inputAttrs, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 저장하기 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }