<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div class="headline text-start mt-8">{{ title }}</div>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="8" class="py-0">
                                    <v-text-field v-model="board.subject" v-bind="{ ...inputAttrs }" label="제목" />
                                </v-col>
                                <v-col cols="4" class="py-0" align-self="center">
                                    <v-switch v-model="board.isNotice" v-bind="{ ...inputAttrs }" label="상단고정" />
                                </v-col>
                                <v-col v-if="!isNotification" cols="12" class="py-0 pt-3">
                                    <v-text-field v-model="board.youtubeCode" v-bind="{ ...inputAttrs }" label="동영상 링크" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-card class="mt-2">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import { BOARD_TYPES, initBoard, inputAttrs } from "@/assets/variables";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ImageInput from "@/components/console/dumb/image-input.vue";

export default {
    components: {
        VDatetimeField,
        VDateField,
        NaverSmarteditor,
        ImageInput,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            inputAttrs,

            board: initBoard({ code: this.code }),
        };
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
        isCreate() {
            return !this._board;
        },
        title() {
            return `${BOARD_TYPES[this.code].text} 게시글 ${this.isCreate ? "등록" : "상세"}`;
        },
        isNotification() {
            return this.code === BOARD_TYPES.NOTIFICATION.value;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.isCreate) return;

                let { board } = await api.console.boards.get({ _id: this._board });
                this.board = initBoard(board);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _thumb, thumb, ...form } = this.board;

                let { post, put } = api.console.boards;

                let { board } = await (this.isCreate ? post : put)(form);

                // await api.console.boards.put({ _id: board._id, _thumb: _thumb });

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
