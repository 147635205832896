<template>
    <schedule-hour-disabled v-if="isClosed" v-bind="{ schedulesOnCloud, date, hour }" @click="alert" />
    <schedule-hour-checkbox v-else v-bind="{ value, subject, limitationMet, schedule, loading }" v-on="{ push, pull }" />
</template>

<script>
import { initRentalRequest, initRentalSubject } from "@/assets/variables";

import ScheduleHourCheckbox from "./schedule-hour-checkbox.vue";
import ScheduleHourDisabled from "./schedule-hour-disabled.vue";

export default {
    components: {
        ScheduleHourCheckbox,
        ScheduleHourDisabled,
    },
    props: {
        value: { type: Array, default: () => [] }, // schedules

        hour: { type: [String, Number] },
        request: { type: Object, default: initRentalRequest },

        blockages: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],
    }),
    computed: {
        date() {
            return this.request?.date;
        },

        _user() {
            return this.request?.user?._id;
        },

        subject() {
            return this.request?.subject || initRentalSubject();
        },

        schedule() {
            const { _id: _subject } = this.subject;
            const { _user, date, hour, request } = this;

            return { _user, _subject, date, hour, request };
        },

        startsAt() {
            return this.$dayjs(this.date).hour(this.hour).startOf("hour").toISOString();
        },

        endsAt() {
            return this.$dayjs(this.date).hour(this.hour).endOf("hour").toISOString();
        },

        blockage() {
            return this.blockages.find((blockage) => {
                if (!blockage.startsAt && !blockage.endsAt) return true;

                const scheduleStartBeforeBlockageEnd = blockage.endsAt ? this.$dayjs(this.startsAt).isBefore(blockage.endsAt) : true;
                const scheduleEndAfterBlockageStart = blockage.startsAt ? this.$dayjs(this.endsAt).isAfter(blockage.startsAt) : true;

                return scheduleStartBeforeBlockageEnd && scheduleEndAfterBlockageStart;
            });
        },

        hasDatePassed() {
            return this.$dayjs(this.date).subtract(1, "day").hour(18).minute(0).isBefore(this.$dayjs());
        },

        hasTimePassed() {
            return this.$dayjs().isAfter(this.$dayjs(this.date).hour(this.hour));
        },

        hasAnSchedule() {
            return this.schedulesOnCloud.some(({ date, hour }) => this.$dayjs(date).hour(hour).isSame(this.$dayjs(this.date).hour(this.hour), "hour"));
        },

        isClosed() {
            return this.hasDatePassed || this.hasTimePassed || this.hasAnSchedule || !!this.blockage;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },

        emit() {
            this.$emit("input", this.schedules);
        },

        push() {
            this.schedules = [...this.schedules, this.schedule];

            this.emit();
        },

        pull() {
            const { _subject, date, hour } = this.schedule;

            const index = this.schedules.findIndex((item) => item._subject == _subject && item.date == date && item.hour == hour);
            if (index > -1) this.schedules.splice(index, 1);

            this.emit();
        },

        alert() {
            if (this.hasDatePassed) {
                alert("사용 예약은 전일 18시 이전까지만 가능합니다");
            } else if (this.isClosed) {
                alert("마감된 일정입니다");
            }
        },
    },
};
</script>

<style>
</style>