<template>
    <v-dialog v-model="shows" width="680px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                <template>지역 등록</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="4" class="pa-2">
                        <v-select v-model="form.parent" :items="regionItems" label="면/읍 선택" />
                    </v-col>
                    <v-col cols="8" class="pa-2">
                        <v-text-field v-model="form.name" label="지역명" />
                    </v-col>
                    <!-- <v-col cols="4" class="pa-2">
                        <v-text-field v-model="form.code" label="코드" />
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="() => (shows = false)">취소</v-btn>
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initRegion, STORY_REGION_TYPES } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ListHeading,
    },
    props: {
        value: { type: Object, default: initRegion }
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initRegion(),
        regionItems: Object.values(STORY_REGION_TYPES),
    }),
    computed: {
        isCreate() {
            return !this.form._id;
        }
    },
    methods: {
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                if(!this.form.parent) throw new Error("면/읍을 선택해주세요");
                if(!this.form.name) throw new Error("지역명을 입력해주세요");

                let { put, post } = api.console.story.region;
                
                await (this.isCreate ? post : put)(this.form);

                alert("저장되었습니다.");
                
                this.$emit("input");
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = initRegion(this.value);
        }
    },
    watch: {
        shows() {
            this.sync();
        },
        value() {
            this.sync();
        }
    }
};
</script>

<style>
</style>