var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "700px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('v-card', [_c('v-card-title', [_vm._v("본문 미리보기")]), _c('v-card-text', [_vm.youtubeCode ? _c('iframe', {
    staticClass: "youtube rounded-sm mb-18 mb-md-24",
    attrs: {
      "width": "100%",
      "src": `https://www.youtube.com/embed/${_vm.youtubeCode}`,
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }) : _vm._e(), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }