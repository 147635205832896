<template>
    <v-dialog v-model="shows" max-width="1440" persistent :fullscreen="$vuetify.breakpoint.smAndDown" content-class="rounded-sm-0">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%" rounded="0">
            <v-card-title>
                <template>예약신청 생성</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="py-5" style="max-height: 591px; overflow: hidden">
                <v-row class="ma-n2" align="stretch">
                    <v-col cols="12" lg="3" class="pa-2">
                        <form-request v-model="request" v-bind="{ schedules }" @input="emit" />
                    </v-col>
                    <v-col cols="12" lg="3" class="pa-2">
                        <form-schedules v-model="schedules" v-bind="{ request }" />
                    </v-col>
                    <v-col cols="12" lg="6" class="pa-2">
                        <form-result v-model="schedules" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-6">
                <v-btn large outlined v-bind="{ loading }" @click="init">초기화</v-btn>
                <template v-if="disabled"> 예약을 계속하시려면 선택한 스케줄을 먼저 초기화 해주세요 </template>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading, disabled }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
            <slot name="loader" />
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "@/plugins/vue-dayjs";
import { initRentalRequest, inputAttrs } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import FormRequest from "./form-request.vue";
import FormSchedules from "./form-schedules.vue";
import FormResult from "./form-result.vue";

export default {
    components: {
        ViewSectionCard,

        FormRequest,
        FormSchedules,
        FormResult,
    },
    data: () => ({
        request: initRentalRequest({ date: dayjs().add(1, "day").format("YYYY-MM-DD") }),
        schedules: [],

        inputAttrs,

        shows: false,
        loading: false,
    }),
    computed: {
        disabled() {
            return this.schedules.some(({ _id }) => _id);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.request = initRentalRequest({ date: dayjs().add(1, "day").format("YYYY-MM-DD") });
            this.schedules = [];
        },
        async emit() {
            this.request = initRentalRequest(this.request);
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.schedules = [
                    ...(await Promise.all(
                        this.schedules.map(async ({ request, ...item }) => {
                            try {
                                return {
                                    ...(await api.console.rental.schedules.post({ ...item, desc: this.request.desc }))?.schedule,
                                    request,
                                    success: true,
                                };
                            } catch (error) {
                                console.error(error);
                                return {
                                    ...item,
                                    request,
                                    success: false,
                                };
                            }
                        })
                    )),
                ];
                let failedItemsLength = this.schedules.filter(({ success }) => !success)?.length;
                if (failedItemsLength) {
                    alert(`총 ${this.schedules.length} 건 중 ${failedItemsLength} 건의 예약 생성에 실패하였습니다.`);
                } else {
                    alert("모든 예약 생성에 성공하였습니다.");
                }

                let requests = this.schedules
                    .reduce((requests, { _id, _user, _subject, date, request }) => {
                        if (_id) {
                            let group = requests.find((group) => {
                                const isSameUser = group._user == _user;
                                const isSameItem = group._subject == _subject;
                                const isSameDate = group.date == date;
                                return isSameUser && isSameDate && isSameItem;
                            });
                            if (!group) {
                                requests.push({
                                    _user,
                                    _subject,
                                    _schedules: [],

                                    date,
                                    desc: this.request.desc,

                                    rentalType: request?.subject?.rental?.type,
                                    subjectType: request?.subject?.type,
                                });
                                group = requests[requests.length - 1];
                            }
                            group._schedules.push(_id);
                        }
                        return requests;
                    }, [])
                    .sort((a, b) => this.$dayjs(a.date).isAfter(b.date));

                for (const request of requests) {
                    await api.console.rental.requests.post(request);
                }
                this.loading = false;
                this.$emit("search");
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>
