<template>
    <v-layout justify-center>
        <v-responsive max-width="1300px" width="100%" height="100%" class="shop-product-view">
            <list-heading :title="isCreate ? '금산에 활력을 불어넣는 사람들 등록' : '금산에 활력을 불어넣는 사람들 상세'">
                <template #buttons>
                    <v-card flat>
                        <v-select v-model="people.type" label="사람들 타입 설정" :items="peopleTypeItems" v-bind="inputAttrs" />
                    </v-card>
                </template>
            </list-heading>
            <v-row no-gutters>
                <v-col cols="9" class="pa-2">
                    <view-section-card title="기본정보" v-bind="{ loading }">
                        <template #actions>
                            <v-switch v-model="people.isMain" v-bind="{ ...switchAttrs }" label="메인 설정" />
                        </template>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col cols="4" class="py-2">
                                    <v-text-field v-model="people.index" type="number" v-bind="{ ...inputAttrs }" label="순서" />
                                </v-col>
                                <v-col cols="8" class="py-2">
                                    <v-text-field v-model="people.name" v-bind="{ ...inputAttrs }" label="제목" />
                                </v-col>
                                <v-col cols="12" class="py-2">
                                    <v-text-field v-model="people.summary" v-bind="{ ...inputAttrs }" label="한줄 설명" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <v-divider></v-divider>
                            <v-card-subtitle>상세내용</v-card-subtitle>
                            <v-divider></v-divider>
                            <people-details v-model="people.details" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="3" class="pa-2">
                    <view-section-card title="썸네일 등록" v-bind="{ loading }">
                        <v-card-text>
                            <image-input v-model="people.thumb" accept="image/*" width="280" height="230" outlined style="overflow: hidden" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col v-if="!isGroup" cols="12" class="pa-2">
                    <view-section-card title="멤버 이미지 슬라이드" v-bind="{ loading }">
                        <template #actions>
                            <v-select v-model="people._group" :items="groupItems" v-bind="{ ...inputAttrs }" label="그룹 설정" />
                        </template>
                        <v-card-text>
                            <images-input v-model="people.images" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
            </v-row>
            <v-btn fixed right bottom fab color="primary" elevation="1" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PEOPLE_TYPES, inputAttrs, switchAttrs, initPeople } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ImageInput from "@/components/console/dumb/image-input.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import PeopleDetails from "@/components/console/people/people-details.vue";

export default {
    components: {
        ListHeading,
        ViewSectionCard,
        ImageInput,
        ImagesInput,
        PeopleDetails,
    },
    data: () => ({
        switchAttrs,
        inputAttrs,
        shows: false,
        loading: false,
        peopleTypeItems: Object.values(PEOPLE_TYPES),
        groupItems: [],

        people: initPeople(),
    }),
    computed: {
        _people() {
            return this.$route.params._people;
        },
        isCreate() {
            return !this._people;
        },
        isGroup() {
            return this.people?.type === PEOPLE_TYPES.GROUP.value;
        },
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.searchGroups();
            if (!this.isCreate) await this.search();
        },
        async searchGroups() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { people } = await api.console.people.gets({ params: { type: PEOPLE_TYPES.GROUP.value } });
                this.groupItems = people.map(({ _id, name }) => ({ text: name, value: _id }));
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { person } = await api.console.people.get({ _id: this._people });
                this.people = { ...person };
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { thumb, _thumb, images, _images, ...form } = this.people;

                let { put, post } = api.console.people;

                if (this.isCreate) delete form._id;

                let { person } = await (this.isCreate ? post : put)(form);

                // 썸네일 처리
                if (thumb instanceof File) {
                    thumb = (await api.console.common.files.post({ path: "people", _people: person?._id }, thumb))?.file;
                }

                if (_thumb && thumb?._id !== _thumb) await api.console.common.files.delete({ _id: _thumb });

                _thumb = thumb?._id;

                // 이미지 처리
                for (const i in images) {
                    if (images[i] instanceof File) images[i] = (await api.console.common.files.post({ path: "people", _people: person?._id }, images[i]))?.file;
                }

                for (const i in _images) {
                    if (_images[i] && !images.some(({ _id }) => _id === _images[i])) await api.console.common.files.delete({ _id: _images[i] });
                }
                _images = (images || [])?.map(({ _id }) => _id);

                await put({ _id: person?._id, _thumb, _images });

                alert("저장되었습니다.");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        isCreate() {
            this.init();
        },
    },
};
</script>