var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "880"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 참석현황 "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.attendance`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', _vm._b({
          staticClass: "ml-1 mr-n1",
          staticStyle: {
            "width": "48px"
          },
          on: {
            "change": function (isAttended) {
              return _vm.toggleAttendance({
                _id: value._id,
                isAttended
              });
            }
          },
          model: {
            value: value.isAttended,
            callback: function ($$v) {
              _vm.$set(value, "isAttended", $$v);
            },
            expression: "value.isAttended"
          }
        }, 'v-switch', _vm.switchAttrs, false))], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }