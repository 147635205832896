<template>
    <v-row :class="{ 'mx-0 form-table-row': true, 'fill-height': fillHeight }" align="stretch">
        <v-col v-bind="{ cols, sm, md, lg }" :class="`subtitle-2 font-weight-bold ${color}`">{{ label }}</v-col>
        <v-col cols="auto" class="pa-0" align-self="stretch">
            <v-divider vertical />
        </v-col>
        <v-col class="py-0" align-self="center" v-bind="{ class: $attrs.contentClass }">
            <slot />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        sm: { type: [Number, String], default: undefined },
        md: { type: [Number, String], default: undefined },
        lg: { type: [Number, String], default: undefined },
        cols: { type: [Number, String], default: 3 },
        color: { type: String, default: "grey lighten-5" },
        label: { type: String, default: null },
        fillHeight: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
.form-table-row:not(:last-of-type) {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
