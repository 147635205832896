<template>
    <v-btn v-bind="{ loading, color }" large block tile @click="toggleSchedule">
        <v-row align="center">
            <v-col>
                <span>{{ schedule.timeStart }} ~ {{ schedule.timeEnd }}</span>
            </v-col>
            <v-col cols="auto">
                <v-simple-checkbox :value="isChecked" hide-details v-bind="{ disabled }" @click="toggleSchedule" />
            </v-col>
        </v-row>
    </v-btn>
</template>

<script>
import { initRentalSubject } from "@/assets/variables";
export default {
    props: {
        value: { type: Array, default: () => [] },
        subject: { type: Object, default: initRentalSubject },
        limitationMet: { type: Boolean, default: false },

        schedule: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
    computed: {
        isChecked() {
            let { _subject, date, timeStart, timeEnd } = this.schedule;
            return this.value.some((schedule) => schedule._subject == _subject && schedule.date == date && schedule.timeStart == timeStart && schedule.timeEnd == timeEnd);
        },
        disabled() {
            return !this.isChecked && this.limitationMet;
        },
        color() {
            if (this.isChecked) return "primary";
            else return undefined;
        },
    },
    methods: {
        toggleSchedule() {
            if (this.isChecked) this.$emit("pull");
            else if (this.limitationMet) alert("예약할 수 있는 회수를 초과하셨습니다");
            else this.$emit("push");
        },
    },
};
</script>

