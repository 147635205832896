<template>
    <v-row no-gutters>
        <v-col cols="12" class="pa-2">
            <v-textarea v-model="form.content" v-bind="{ ...inputAttrs }" label=" 캐치프라이즈 한줄" @input="emit" />
        </v-col>
        <v-col cols="12" class="pa-2">
            <v-text-field v-model="form.researcher" v-bind="{ ...inputAttrs }" label="조사원" @input="emit"></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import { initStoryCatchphrase, inputAttrs } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, initStoryCatchphrase },
    },
    data: () => ({
        form: initStoryCatchphrase(),
        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initStoryCatchphrase(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>