<template>
    <v-data-table v-bind="{ items, headers }" :items-per-page="-1" hide-default-footer disable-sort disable-pagination disable-filtering class="transparent">
        <template #[`header.action`]>
            <v-btn text icon tile color="primary" @click="push">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <template #[`item.breach`]="{ item }">
            <v-row no-gutters>
                <v-col cols="12" md="" style="position: relative">
                    <v-currency-field v-model="item.breach.times" suffix="타임" class="text-align-center caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
                </v-col>
                <v-col cols="auto"> <v-divider :vertical="$vuetify.breakpoint.mdAndUp" /> </v-col>
                <v-col cols="12" md="" style="position: relative">
                    <v-currency-field v-model="item.breach.hours" suffix="시간" class="text-align-center caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
                </v-col>
                <v-col cols="auto"> <v-divider :vertical="$vuetify.breakpoint.mdAndUp" /> </v-col>
                <v-col cols="12" md="" style="position: relative">
                    <v-currency-field v-model="item.breach.dates" suffix="일" class="text-align-center caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
                </v-col>
            </v-row>
        </template>
        <template #[`item.punish`]="{ item }">
            <v-currency-field v-model="item.punish.days" suffix="일" class="text-align-center caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
        </template>
        <template #[`item.action`]="{ item }">
            <v-btn text icon tile color="red" :disabled="!(items.length - 1)" tabindex="-1" @click="pull(item)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, initRentalBanPolicy } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => ({}) },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        policies: [],

        attrs_input__verticalTable,
    }),
    computed: {
        items() {
            return [...this.policies];
        },
        headers() {
            const headers = [];

            headers.push({ value: "breach", text: "불참" });
            headers.push({ value: "punish", text: "사용금지" });
            if (this.mode == "form") headers.push({ value: "action", text: "", cellClass: "px-4" });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "pa-0") + " caption line-height-1-5 white-space-pre-line" }));
        },
        readonly() {
            return this.mode != "form";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.policies = [...this.value];
        },
        emit() {
            this.$emit("input", this.policies);
        },
        push() {
            this.policies.push(initRentalBanPolicy());
            this.emit();
        },
        pull(item) {
            const index = this.items.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) {
                this.policies.splice(index, 1);
                this.emit();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}

.v-input.text-align-center ::v-deep {
    .v-input__slot {
        background: transparent;
    }
    input {
        text-align: center;
    }
}
</style>
