<template>
    <list-search showsSearch v-bind="$attrs" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col v-if="isProject" cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="사업기간(시작일)" v-model="query['businessStartsAt']" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <v-col v-if="isProject" cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="사업기간(종료일)" v-model="query['businessEndsAt']" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" v-bind="inputAttrs" @input="emit" />
            </v-col>
        </v-row>
        <v-row class="ma-n2 pt-2">
            <v-col cols="4" sm="4" lg="4" class="pa-2">
                <v-select label="검색명" :items="computedSearchKeys" v-model="query['searchKey']" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="8" sm="8" lg="8" class="pa-2">
                <v-text-field label="검색어" v-model="query['searchValue']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

const searchKeys = [
    { text: "전체", value: null },
    { text: "제목", value: "subject" },
    { text: "요약", value: "summary", isProject: true },
    { text: "내용", value: "content" },
];

let initQuery = (query = {}) => ({
    ...query,

    searchKey: query?.searchKey || null,
    businessStartsAt: query?.businessStartsAt || null,
    businessEndsAt: query?.businessEndsAt || null,

    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],
});

export default {
    components: {
        ListSearch,
    },
    props: {
        type: { type: String, default: null },
        isProject: { type: Boolean, default: true },
    },
    data: () => ({
        query: initQuery(),
        searchKeys,

        inputAttrs,
    }),
    computed: {
        computedSearchKeys() {
            return this.isProject ? searchKeys : searchKeys.filter(({ isProject }) => !isProject);
        },
    },
    async mounted() {
        await this.init();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            this.sync();
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];
            if (!query["businessStartsAt"]) delete query["businessStartsAt"];
            if (!query["businessEndsAt"]) delete query["businessEndsAt"];

            if (!query["searchDateValue"]?.[0] && !query["searchDateValue"]?.[1]) {
                delete query["searchDateValue"];
            }

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
