var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 링크 관리 ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsSearch = !_vm.showsSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-4 v-sheet--outlined",
    attrs: {
      "items": _vm.manages,
      "headers": _vm.managesHeaders.filter(function (header) {
        return header.code ? header.code.includes(_vm.$route.query.code) : true;
      }),
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + (+_vm.manages.indexOf(item) + 1)) + " ")];
      }
    }, {
      key: `item.thumb`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-row', [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "width": "54",
            "height": "54",
            "contain": ""
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDateTime()) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), false ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]) : _vm._e(), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }