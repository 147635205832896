<template>
    <v-edit-dialog large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-text-field v-model="item.index" v-bind="attrs_input" type="number" class="mt-3" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {
            index: 0,
        },
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = { ...this.value };
        },
        save() {
            let { _id, index } = this.item;
            let form = { _id, index };

            this.$emit("update", form);
        },
    },
};
</script>

<style>
</style>