<template>
    <list-search v-bind="{ ...$attrs, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="사용여부" v-model="query['isActive']" :items="isActiveItems" v-bind="inputAttrs" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="설비유형" v-model="query['target.subjectTypes']" :items="subjectTypes" v-bind="inputAttrs" @input="query['target._subjects'] = null" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-rental-subject label="시설" v-model="query['target._subjects']" placeholder="전체" :params="{ type: RENTAL_SUBJECT_TYPES.FACILITIES.value }" clearable v-bind="inputAttrs" @input="query['target.subjectTypes'] = null" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-rental-subject label="장비" v-model="query['target._subjects']" placeholder="전체" :params="{ type: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value }" clearable v-bind="inputAttrs" @input="query['target.subjectTypes'] = null" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["isActive"]: query["isActive"] ?? null,
    ["target._subjects"]: query["target._subjects"] ?? null,
    ["target.subjectTypes"]: query["target.subjectTypes"] ?? null,
});

const isActiveItems = [
    { text: "전체", value: null },
    { text: "사용", value: true },
    { text: "미사용", value: false },
];

const subjectTypes = [{ text: "전체", value: null }].concat(Object.values(RENTAL_SUBJECT_TYPES));

export default {
    components: {
        ListSearch,
        AutocompleteRentalSubject,
    },
    props: {
        targetType: { type: String, default: null },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        subjectTypes,
        isActiveItems,
        RENTAL_SUBJECT_TYPES,

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { query = {} } = this;

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
