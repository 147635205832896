var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "680px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({}, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [[_vm._v("연혁 등록")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-date-month-field', _vm._b({
    attrs: {
      "label": "년/월"
    },
    model: {
      value: _vm.month,
      callback: function ($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  }, 'v-date-month-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "number",
      "label": "순서"
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "rows": "3",
      "label": "설명"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-switch', _vm._b({
    attrs: {
      "label": "노출"
    },
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', Object.assign({}, _vm.switchAttrs), false)), _c('v-spacer'), _c('v-btn', {
    on: {
      "click": function () {
        return _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }