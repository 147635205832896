<template>
    <v-tabs>
        <template v-for="item in items">
            <v-tab :exact="item.to.path == path" :exact-path="item.to.path != path" :to="item.to" :key="item.text">{{ item.text }}</v-tab>
        </template>
    </v-tabs>
</template>

<script>
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

const path = "/console/rental-requests";

export default {
    data: () => ({
        path,
    }),
    computed: {
        items() {
            const { ...query } = this.$route.query;

            delete query.page;

            return [{ text: "전체", to: { path, query } }].concat(Object.values(RENTAL_SUBJECT_TYPES).map(({ text, value }) => ({ text, to: { path: `${path}/${value}`, query } })));
        },
    },
};
</script>

<style>
</style>