var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1), _vm.isProject ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-date-field', _vm._b({
    attrs: {
      "label": "사업시작일"
    },
    model: {
      value: _vm.board.businessStartsAt,
      callback: function ($$v) {
        _vm.$set(_vm.board, "businessStartsAt", $$v);
      },
      expression: "board.businessStartsAt"
    }
  }, 'v-date-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-date-field', _vm._b({
    attrs: {
      "label": "사업종료일"
    },
    model: {
      value: _vm.board.businessEndsAt,
      callback: function ($$v) {
        _vm.$set(_vm.board, "businessEndsAt", $$v);
      },
      expression: "board.businessEndsAt"
    }
  }, 'v-date-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "요약"
    },
    model: {
      value: _vm.board.summary,
      callback: function ($$v) {
        _vm.$set(_vm.board, "summary", $$v);
      },
      expression: "board.summary"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일 등록")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pl-6 my-4",
    attrs: {
      "cols": "5"
    }
  }, [_c('image-input', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "accept": "image/*",
      "width": "140",
      "height": "140",
      "outlined": ""
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1), _c('v-col', {
    attrs: {
      "align-self": "center"
    }
  }, [_c('v-file-input', _vm._b({
    attrs: {
      "accept": "image/*",
      "label": "파일명"
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  }, 'v-file-input', Object.assign({}, _vm.inputAttrs), false))], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }