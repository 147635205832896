import { render, staticRenderFns } from "./rental-ban-form.vue?vue&type=template&id=64cd37bd&scoped=true"
import script from "./rental-ban-form.vue?vue&type=script&lang=js"
export * from "./rental-ban-form.vue?vue&type=script&lang=js"
import style0 from "./rental-ban-form.vue?vue&type=style&index=0&id=64cd37bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cd37bd",
  null
  
)

export default component.exports