var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "사용자메모"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "rows": "4",
      "auto-grow": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.note,
      callback: function ($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  }, 'v-textarea', _vm.attrs_input__verticalTable, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }