<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <v-col cols="auto" class="mt-4 pt-0 pb-0 headline text-start">
                게시글 등록
            </v-col>
            <v-card v-if="$route.query.code === 'current' || manage.code === 'current'" class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="manage.subject" label="제목" placeholder="ex) 308호" persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="$route.query.code === 'current' || manage.code === 'current'" class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text class="mt-4">
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="thumb" max-width="96" max-height="96" :src="createObjectURL(thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="thumb" label="썸네일 이미지" placeholder="권장사이즈 : 166*80(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="$route.query.code === 'current' || manage.code === 'current'" class="mt-4">
                <v-card-title class="subtitle-1">입주기업 현황</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="manage.state.period" label="입주시기"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="manage.state.company" label="기업명"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="manage.state.name" label="대표자명"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="manage.state.item" label="사업아이템"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="manage.state.phone" label="연락처"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="$route.query.code !== 'current' || manage.code !== 'current'" class="mt-4">
                <v-card-title class="subtitle-1">이용안내</v-card-title>
                <v-card-text>
                    <v-textarea v-model="manage.content" outlined max-width="1000"/>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";


export default {
    components: {
    },
    data() {
        return {
            ready: true,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            thumb: undefined,
            manage: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,

                state: {
                    period: null,
                    company: null,
                    name: null,
                    item: null,
                    phone: null,
                },

            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            console.log("??");
            try {
                if (this.$route.params._manage) {
                    var { manage } = await api.console.manage.get({ _id: this.$route.params._manage });
                    this.manage = manage;

                    if (manage.thumb) this.thumb = await api.getResource(manage.thumb);
                }

                if (!this.$route.params._manage === undefined) {
                    var { summary } = await api.console.manage.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////
                ////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                let { manage } = this.manage._id ? await api.console.manage.put(this.manage) : await api.console.manage.post(this.manage);

                // ////////////////////////////////////////////////////////////////
                // // 2. 리소스 저장
                // ////////////////////////////////////////////////////////////////
                if (this.thumb) await api.console.manage.postThumb(manage, this.thumb);

                // alert(this.manage._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
};
</script>
