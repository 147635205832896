var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "기본정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "label": "제목"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-date-field', _vm._b({
    attrs: {
      "label": "조사일자"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.researchedAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "researchedAt", $$v);
      },
      expression: "form.researchedAt"
    }
  }, 'v-date-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.parentItems,
      "label": "읍/면 선택"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.parent,
      callback: function ($$v) {
        _vm.parent = $$v;
      },
      expression: "parent"
    }
  }, 'v-select', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.regionItems,
      "label": "지역 선택"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_region", $$v);
      },
      expression: "form._region"
    }
  }, 'v-select', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "items": _vm.regionItems,
      "label": "마을유래"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.history,
      callback: function ($$v) {
        _vm.$set(_vm.form, "history", $$v);
      },
      expression: "form.history"
    }
  }, 'v-textarea', Object.assign({}, _vm.inputAttrs), false))], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "공동체 특성"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "주민수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.citizen,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "citizen", $$v);
      },
      expression: "form.population.citizen"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "가구수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.household,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "household", $$v);
      },
      expression: "form.population.household"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "아동/청소년 수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.teenager,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "teenager", $$v);
      },
      expression: "form.population.teenager"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "다문화자녀 수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.multiCulture,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "multiCulture", $$v);
      },
      expression: "form.population.multiCulture"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "외국인(성인) 수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.foreigner,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "foreigner", $$v);
      },
      expression: "form.population.foreigner"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "pt-0",
    attrs: {
      "type": "number",
      "label": "귀농귀촌인 수"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.population.farmer,
      callback: function ($$v) {
        _vm.$set(_vm.form.population, "farmer", $$v);
      },
      expression: "form.population.farmer"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }