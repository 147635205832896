<template>
    <list-search showsSearch v-bind="$attrs" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-program-category label="교육분류" v-model="query['_category']" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-rental-subject label="사용장비" v-model="query['_subjects']" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="대상회원유형" v-model="query['userTypes']" :items="userTypes" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-user label="참여자" v-model="query['_attendees']" placeholder="전체" clearable v-bind="inputAttrs" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="12" lg="3" class="pa-2">
                <v-text-field label="교육명" v-model="query['searchValue']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="접수상태" v-model="query['application.state']" :items="applicationStates" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="진행상태" v-model="query['state']" :items="programStates" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs, PROGRAM_STATES, PROGRAM_STATES__APPLICATION, USER_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";
import AutocompleteProgramCategory from "@/components/dumb/autocomplete-program-category.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_category"]: query["_category"] ?? null,
    ["_subjects"]: query["_subjects"] ?? null,
    ["_attendees"]: query["_attendees"] ?? null,

    ["searchValue"]: query["searchValue"] ?? null,

    ["state"]: query["state"] ?? null,
    ["application.state"]: query["application.state"] ?? null,
});

const userTypes = [
    { text: "개인회원", value: USER_TYPES.INDIVIDUAL },
    { text: "기업회원", value: USER_TYPES.ORGANIZATION },
];

const programStates = Object.values(PROGRAM_STATES);

const APPLICATION_STATES = { ...PROGRAM_STATES__APPLICATION };
delete APPLICATION_STATES.COMPLETE;
const applicationStates = Object.values(APPLICATION_STATES);

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteRentalSubject,
        AutocompleteProgramCategory,
    },
    props: {
        value: { type: Object, default: initQuery },

        mode: { type: String, default: "route" },
    },
    data: () => ({
        query: initQuery(),

        userTypes,
        programStates,
        applicationStates,

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            if (this.mode == "route") this.query = initQuery(this.$route.query);
            if (this.mode == "model") this.query = initQuery(this.value);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["_category"]) delete query["_category"];
            if (!query["_subjects"]) delete query["_subjects"];
            if (!query["_attendees"]) delete query["_attendees"];

            if (!query["searchValue"]) delete query["searchValue"];

            if (!query["state"]) delete query["state"];
            if (!query["application.state"]) delete query["application.state"];

            if (this.mode == "route") this.$router.push({ query });
            if (this.mode == "model") this.$emit("input", query);
        },
    },
};
</script>

<style></style>
