<template>
    <v-row>
        <v-col cols="7" >
            <view-section-card title="기본정보" v-bind="{ loading }">
                <v-row>
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.title" class="pt-0" v-bind="{ ...inputAttrs }" label="제목" @input="emit" />
                            </v-col>
                            <v-col cols="3" class="pa-4">
                                <v-date-field v-model="form.researchedAt" label="조사일자" v-bind="{ ...inputAttrs }" @input="emit" />
                            </v-col>
                            <v-col cols="3" class="pa-4">
                                <v-text-field v-model="form.phone" label="연락처" v-bind="{ ...inputAttrs }" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-select v-model="parent" :items="parentItems" v-bind="{ ...inputAttrs }" label="읍/면 선택" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-select v-model="form._region" :items="regionItems" v-bind="{ ...inputAttrs }" label="지역 선택" @input="emit" />
                            </v-col>
                            <v-col cols="12" class="pa-4">
                                <v-textarea v-model="form.history" :items="regionItems" v-bind="{ ...inputAttrs }" label="마을유래" @input="emit" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </view-section-card>
        </v-col>
        <v-col cols="5">
            <view-section-card title="공동체 특성" v-bind="{ loading }">
                <v-row>
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.citizen" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="주민수" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.household" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="가구수" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.teenager" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="아동/청소년 수" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.multiCulture" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="다문화자녀 수" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.foreigner" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="외국인(성인) 수" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-4">
                                <v-text-field v-model="form.population.farmer" class="pt-0" type="number" v-bind="{ ...inputAttrs }" label="귀농귀촌인 수" @input="emit" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </view-section-card>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import { initStory, STORY_REGION_TYPES, inputAttrs } from "@/assets/variables";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
export default {
    components: {
        VDateField,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initStory },
    },
    data: () => ({
        inputAttrs,
        loading: false,
        parent: null,
        regions: [],

        form: initStory(),
        parentItems: Object.values(STORY_REGION_TYPES),
        regionItems: []
    }),
    async mounted() {
        await this.search();
    },
    methods: {
        async search() {
            try {
                let { regions } = await api.console.story.region.gets();
                this.regions = regions;
            } catch (error) {
                console.error(error);
                alert(error.repsonse ? error.response.data.message : error.message);
            }
        },
        sync() {
            this.form = initStory(this.value);
            if(this.form?.region) this.parent = this.form.region.parent;
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
        parent() {
            this.regionItems = this.regions.filter(({ parent }) => this.parent === parent).map((region) => ({ text: region.name, value: region._id}));
        }
    }
};
</script>

<style>
</style>