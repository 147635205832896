<template>
    <v-dialog v-model="shows" max-width="800" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">회원 생성</template>
                <template v-else>회원 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" xl="12" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" class="pa-2">
                                <form-base v-model="form" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <form-address v-model="form" @input="emit" />
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <form-memo v-model="form" @input="emit" />
                            </v-col>

                            <!-- <v-col cols="12" md="6" class="pa-2">
                                <form-files v-model="form" @input="emit" />
                            </v-col> -->

                            <!-- <v-col cols="12" md="6" class="pa-2">
                                <v-row class="ma-n2">
                                    <v-col cols="12" class="pa-2">
                                        <form-facility v-model="form" @input="emit" />
                                    </v-col>
                                </v-row>
                            </v-col> -->
                        </v-row>
                    </v-col>

                    <!-- <v-col cols="12" md="6" xl="5" class="pa-2">
                        <form-equipments v-model="form" @input="emit" />
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <!-- <v-switch v-model="form.isActive" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.isActive">사용</template>
                                <template v-else>미사용</template>
                            </span>
                        </template>
                    </v-switch> -->
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, initUser, RENTAL_SUBJECT_TYPES, USER_TYPES, attrs_input__verticalTable } from "@/assets/variables";

import FormBase from "./form-base.vue";
import FormMemo from "./form-memo.vue";
import FormAddress from "./form-address.vue";
import cryptoAes from "@/plugins/crypto-aes";
// import FormFiles from "./form-files.vue";
// import FormFacility from "./form-facility.vue";
// import FormEquipments from "./form-equipments.vue";

export default {
    components: {
        FormBase,
        FormMemo,
        FormAddress,
        // FormFiles,
        // FormFacility,
        // FormEquipments,
    },
    props: {
        value: { type: Object, default: initUser },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initUser(),

        USER_TYPES,
        // RENTAL_SUBJECT_TYPES,

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,

        JSON,
    }),
    computed: {
        _user() {
            return this.value?._id;
        },
        isCreate() {
            return !this._user;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initUser();
                } else {
                    let { user } = await api.console.users.get({ _id: this._user });

                    this.form = initUser(user);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initUser(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { password, ...form } = this.form;

                password = cryptoAes.encrypt(password);

                // POST/PUT user
                let { post, put } = api.console.users;
                // let { user } = await (this.isCreate ? post({...form.password}) : put(form));
                let { user } = await put({ ...form, password });
                const { _id: _user } = user || {};

                alert("저장되었습니다");
                this.$emit("input", user);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
