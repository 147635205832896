var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": " 캐치프라이즈 한줄"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.inputAttrs), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "조사원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.researcher,
      callback: function ($$v) {
        _vm.$set(_vm.form, "researcher", $$v);
      },
      expression: "form.researcher"
    }
  }, 'v-text-field', Object.assign({}, _vm.inputAttrs), false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }