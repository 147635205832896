<template>
    <v-data-table v-bind="{ headers, items }" disable-sort disable-filtering hide-default-footer :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.date`]="{ item }">
            <v-edit-dialog :return-value.sync="item.date" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.date }}
                <template #input>
                    <div class="mx-n4"><v-date-picker v-model="item.date" show-adjacent-months :day-format="(day) => dayjs(day).date()" /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.timeStart`]="{ item }">
            <v-edit-dialog :return-value.sync="item.timeStart" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.timeStart }}
                <template #input>
                    <div class="mx-n4"><v-time-picker v-model="item.timeStart" ampm-in-title /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.timeEnd`]="{ item }">
            <v-edit-dialog :return-value.sync="item.timeEnd" large cancel-text="취소" save-text="저장" @save="update(item)">
                {{ item.timeEnd }}
                <template #input>
                    <div class="mx-n4"><v-time-picker v-model="item.timeEnd" ampm-in-title /> <v-divider /></div>
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.actions`]="{ item }">
            <v-btn text icon tile color="secondary" :disabled="item.isUpDisabled" @click="up(item)">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn text icon tile color="secondary" :disabled="item.isDownDisabled" @click="down(item)">
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn text icon tile color="red" :disabled="items.length == 1" @click="pull(item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row class="mx-0">
                <v-col cols="auto" class="px-4 caption">* 진행된 회차를 삭제할 시 데이터가 유실될 수 있으며, 한 번 유실된 데이터는 복구할 수 없습니다.</v-col> <v-spacer />
                <v-col cols="auto" class="px-4 py-0" align-self="center">
                    <v-btn text icon tile color="primary" @click="push"><v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import { initProgram, initProgramUnit } from "@/assets/variables";

const headers = [
    { width: +70, text: "#", value: "index", formatter: (value) => `${value}회차` },
    { width: 110, text: "날짜", value: "date" },
    { width: +80, text: "요일", value: "day", align: "center" },
    { width: +90, text: "시작시간", value: "timeStart", align: "center" },
    { width: +90, text: "종료시간", value: "timeEnd", align: "center" },
    { text: "", value: "actions", align: "right" },
];

export default {
    props: {
        value: { type: Object, default: initProgram },
    },
    data: () => ({
        form: initProgram(),

        dayjs,
        headers,
    }),
    computed: {
        items() {
            return this.form.units.map((item, index) => ({
                ...item,
                index: index + 1,
                isUpDisabled: index == 0,
                isDownDisabled: index == this.form.units.length - 1,
            }));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initProgram(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        push() {
            let { date, timeEnd, timeStart } = [...this.items].pop() ?? initProgramUnit();
            date = dayjs(date).add(1, "day").format("YYYY-MM-DD");
            this.form.units.push(initProgramUnit({ date, timeEnd, timeStart }));

            this.emit();
        },
        pull(item) {
            const index = this.form.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.form.units.splice(index, 1);
            this.emit();
        },
        update(item) {
            const index = this.form.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.form.units.splice(index, 1, item);
            this.emit();
        },
        up(item) {
            const index = this.form.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.form.units.splice(index - 1, 2, this.form.units[index], this.form.units[index - 1]);
            this.emit();
        },
        down(item) {
            const index = this.form.units.findIndex(({ tempId }) => tempId == item.tempId);
            this.form.units.splice(index, 2, this.form.units[index + 1], this.form.units[index]);
            this.emit();
        },
    },
};
</script>
