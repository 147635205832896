<template>
    <v-sheet>
        <template v-if="isOnHoliday">
            <p>운영하지 않는 날짜입니다</p>
        </template>
        <template v-else-if="isOnBusiness">
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_DATE.value">
                <schedule-date
                    v-model="schedules"
                    v-bind="{
                        request,

                        blockages,
                        schedulesOnCloud,

                        limitationMet,

                        loading,
                    }"
                    @input="emit"
                />
            </template>
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_HOUR.value">
                <template v-for="(hour, index) in subject.rental.businessHours">
                    <schedule-hour
                        v-model="schedules"
                        v-bind="{
                            hour,
                            request,

                            blockages,
                            schedulesOnCloud,

                            limitationMet,

                            loading,
                        }"
                        :key="index"
                        @input="emit"
                    />
                </template>
            </template>
            <template v-if="subject.rental.type == RENTAL_TYPES.BY_TIME.value">
                <template v-for="(time, index) in businessTimes">
                    <schedule-time
                        v-model="schedules"
                        v-bind="{
                            time,
                            request,

                            blockages,
                            schedulesOnCloud,

                            limitationMet,

                            loading,
                        }"
                        :key="index"
                        @input="emit"
                    />
                </template>
            </template>
        </template>
        <template v-else>
            <p>운영하지 않는 요일입니다</p>
        </template>
    </v-sheet>
</template>

<script>
import dayjs from "dayjs";
import { initRentalRequest, initRentalSubject, RENTAL_TYPES } from "@/assets/variables";

import ScheduleDate from "./schedule-date.vue";
import ScheduleHour from "./schedule-hour.vue";
import ScheduleTime from "./schedule-time.vue";

export default {
    components: {
        ScheduleDate,
        ScheduleHour,
        ScheduleTime,
    },
    props: {
        value: { type: Array, default: () => [] }, // schedules

        request: { type: Object, default: initRentalRequest },

        blockages: { type: Array, default: () => [] },
        schedulesOnCloud: { type: Array, default: () => [] },

        limitationMet: { type: Boolean, default: false },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        schedules: [],

        RENTAL_TYPES,
    }),
    computed: {
        _user() {
            return this.request?.user?._id;
        },
        date() {
            return this.request?.date;
        },
        subject() {
            return this.request?.subject || initRentalSubject();
        },
        isOnHoliday() {
            return (this.subject.rental.holidays || []).some((date) => dayjs(this.date).isSame(date, "date"));
        },
        isOnBusiness() {
            return (this.subject.rental.businessDays || []).includes(dayjs(this.date).day());
        },
        businessTimes() {
            const dateDay = this.$dayjs(this.date).day();
            return this.subject.rental.businessTimes.filter(({ day }) => day == dateDay);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },
        emit() {
            this.$emit("input", this.schedules);
        },
    },
};
</script>

