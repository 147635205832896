<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" max-width="1280" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="예약차단 목록" showsFilterButton>
                <template #add-button>
                    <rental-blockage-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="예약차단 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </rental-blockage-form>
                </template>
            </list-heading>

            <rental-blockage-list-search v-bind="{ targetType, showsSearch }" flat outlined />

            <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-4" style="overflow: hidden">
                <template #top> <rental-blockage-list-target-types /> <v-divider /> </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.memo`]="{ item, value }">
                    <edit-memo :value="item" v-on="{ update }"> {{ value || "-" }} </edit-memo>
                </template>

                <template #[`item.isActive`]="{ item }">
                    <v-switch v-model="item.isActive" class="d-inline-block mr-n4" v-bind="switchAttrs" @change="(isActive) => update({ _id: item._id, isActive })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <rental-blockage-form :value="item" @input="(item) => update(item)">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </rental-blockage-form>

                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { RENTAL_LIMITATION_TARGET_TYPES, RENTAL_SUBJECT_TYPES, switchAttrs } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import RentalBlockageForm from "@/components/console/rental/blockages/rental-blockage-form.vue";
import RentalBlockageListSearch from "@/components/console/rental/blockages/rental-blockage-list-search.vue";
import RentalBlockageListTargetTypes from "@/components/console/rental/blockages/rental-blockage-list-target-types.vue";

import EditMemo from "@/components/console/dumb/edit/edit-memo.vue";

const headers = [
    { width: +70, text: "번호", value: "index" },
    {
        text: "대상",
        value: "target",
        formatter: ({ type, subjectTypes = [], subjects = [] }) => {
            const contents = [];
            const typeText = RENTAL_LIMITATION_TARGET_TYPES[type]?.text;
            contents.push(typeText);

            if (type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_TYPE.value) {
                const texts = subjectTypes.map((key) => RENTAL_SUBJECT_TYPES[key]?.text);
                contents.push(`<span class="text--disabled">(${texts.join(", ")})</span>`);
            }

            if (type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_SELF.value) {
                const texts = subjects.map(({ name }) => name);
                contents.push(`<span class="text--disabled">(${texts.join(", ")})</span>`);
            }

            return contents.filter((item) => item).join("\n");
        },
    },
    { width: 120, text: "예약차단시작", value: "startsAt", formatter: (value) => value?.toDateTime?.() || "시작일시없음" },
    { width: 120, text: "예약차단종료", value: "endsAt", formatter: (value) => value?.toDateTime?.() || "종료일시없음" },
    { width: 200, text: "메모", value: "memo" },
    { width: +90, text: "사용여부", value: "isActive" },
    { width: +88, text: "", value: "actions", align: "right" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,

        RentalBlockageForm,
        RentalBlockageListSearch,
        RentalBlockageListTargetTypes,

        EditMemo,
    },
    props: {
        targetType: { type: String, default: null },
    },
    data: () => ({
        blockages: [],
        summary: { totalCount: 0 },

        limit: 10,
        loading: false,
        showsSearch: true,

        headers,
        switchAttrs,
    }),
    computed: {
        items() {
            return this.blockages.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query["target.type"] = this.targetType;
            if (!query["target.type"]) delete query["target.type"];

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;

                var { summary, blockages } = await api.console.rental.blockages.gets({
                    headers: { skip, limit },
                    params,
                });

                this.blockages = blockages;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { blockage } = await api.console.rental.blockages.put(item);
                this.updateItem(blockage);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async remove(item) {
            if (this.loading) return;
            else {
                const go = confirm(`해당 예약차단을 삭제하시겠습니까?`);
                if (go) {
                    this.loading = true;
                    try {
                        const { blockage } = await api.console.rental.blockages.delete(item);
                        this.blockages = this.blockages.filter(({ _id }) => _id !== blockage._id);
                        this.summary.totalCount -= 1;
                        alert(`해당 예약차단이 삭제되었습니다.`);
                    } finally {
                        this.loading = false;
                    }
                }
            }
        },

        updateItem(blockage) {
            const index = this.blockages.findIndex(({ _id }) => _id == blockage._id);
            if (0 <= index) this.blockages.splice(index, 1, blockage);
            else {
                this.blockages = [blockage, ...this.blockages];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
