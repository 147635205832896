var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('story-view-image-form', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form[0],
      callback: function ($$v) {
        _vm.$set(_vm.form, 0, $$v);
      },
      expression: "form[0]"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('story-view-image-form', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form[1],
      callback: function ($$v) {
        _vm.$set(_vm.form, 1, $$v);
      },
      expression: "form[1]"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('story-view-image-form', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form[2],
      callback: function ($$v) {
        _vm.$set(_vm.form, 2, $$v);
      },
      expression: "form[2]"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }