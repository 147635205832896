<template>
    <v-dialog v-model="shows" width="800px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                <template>추진단 조직도 설정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <people-organization-details v-model="form" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="() => (shows = false)">취소</v-btn>
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PeopleOrganizationDetails from "./people-organization-details.vue";

export default {
    components: {
        ListHeading,
        PeopleOrganizationDetails,
    },
    data: () => ({
        shows: false,
        loading: false,

        form: [],
    }),
    computed: {
        isCreate() {
            return !this.form._id;
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { setting } = await api.console.setting.get();

                this.form = setting?.organization;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                await api.console.setting.put({ organization : this.form});

                alert("저장되었습니다.");

                await this.search();
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        shows() {
            this.search();
        },
    },
};
</script>

<style>
</style>