<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                    링크 관리
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-data-table :items="manages" :headers="managesHeaders.filter((header) => (header.code ? header.code.includes($route.query.code) : true))" disable-sort disable-pagination hide-default-footer class="mt-4 v-sheet--outlined">
                <template #[`item.no`]="{ item }"> {{ (page - 1) * limit + (+manages.indexOf(item) + 1) }} </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row> <v-img v-if="item.thumb" :src="item.thumb" width="54" height="54" contain></v-img> </v-row>
                </template>
                <template #[`item.createdAt`]="{ item }"> {{ item.createdAt.toDateTime() }} </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="20" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()"> <v-icon>mdi-pencil</v-icon> </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            manages: [],
            managesHeaders: [
                {
                    text: "no",
                    align: "center",
                    width: 40,
                    value: "no",
                },
                {
                    text: "제목",
                    align: "center",
                    width: 250,
                    value: "subject",
                },
                {
                    text: "Actions",
                    width: 40,
                    align: "center",
                    value: "actions",
                },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            console.log(this.$route);
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, manages } = await api.console.manage.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.manages = manages;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/about/${this.$route.query.code}/create?code=${this.$route.query.code}`);
        },
        edit(manage) {
            this.$router.push(`/console/about/${this.$route.query.code}/${manage._id}`);
        },
        async remove(manage) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.manage.delete(manage);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>
