<template>
    <v-card-title class="subtitle-2 font-weight-bold grey--text text--darken-2">
        <v-row no-gutters align="center">
            <v-col cols="auto">{{ title }}</v-col>
            <v-spacer />
            <v-col :cols="actionCols" class="my-n3">
                <slot name="actions" />
            </v-col>
        </v-row>
    </v-card-title>
</template>

<script>
export default {
    props: {
        title: { type: String },
        actionCols: { type: [String, Number], default: "auto" },
    },
};
</script>

<style>
</style>