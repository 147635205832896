<template>
    <v-layout justify-center>
        <v-responsive max-width="1300px" width="100%" height="100%" class="shop-product-view">
            <list-heading :title="isCreate ? '이야기 등록' : '이야기 상세'" />
            <story-view-info v-model="story" />
            <v-row no-gutters>
                <v-col cols="12" class="py-2">
                    <view-section-card title="대표 모임 및 조직" v-bind="{ loading }">
                        <story-view-groups v-model="story.groups" />
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="py-2">
                    <view-section-card title="대표자원" v-bind="{ loading }">
                        <story-view-resources v-model="story.resources" />
                    </view-section-card>
                </v-col>
                <v-row no-gutters>
                    <v-col cols="8" class="pr-2">
                        <view-section-card title="이미지" v-bind="{ loading }">
                            <story-view-images v-model="story.images" />
                        </view-section-card>
                    </v-col>
                    <v-col cols="4">
                        <view-section-card title="캐치프라이즈" v-bind="{ loading }">
                            <story-view-catchphrase v-model="story.catchphrase" />
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-row>
            <v-btn fixed right bottom fab color="primary" elevation="1" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initStory } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import StoryViewInfo from "@/components/console/story/story-view-info.vue";
import StoryViewGroups from "@/components/console/story/story-view-groups.vue";
import StoryViewResources from "@/components/console/story/story-view-resources.vue";
import StoryViewImages from "@/components/console/story/story-view-images.vue";
import StoryViewCatchphrase from "@/components/console/story/story-view-catchphrase.vue";

export default {
    components: {
        VDateField,
        StoryViewInfo,
        ListHeading,
        ViewSectionCard,
        StoryViewGroups,
        StoryViewResources,
        StoryViewImages,
        StoryViewCatchphrase,
    },
    data: () => ({
        shows: false,
        loading: false,

        story: initStory(),
    }),
    computed: {
        _story() {
            return this.$route.params._story;
        },
        isCreate() {
            return !this._story;
        },
    },
    async mounted() {
        if (!this.isCreate) await this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = false;
            try {
                if (this.isCreate) return;

                let { story } = await api.console.story.get({ _id: this._story });
                this.story = initStory(story);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            this.loading = false;
            try {
                let { images, ...form } = this.story;
                let { put, post } = api.console.story;

                let { story } = await (this.isCreate ? post : put)(form);

                let _images = await Promise.all(
                    images.map(async ({ image, desc }) => ({
                        _image: image instanceof File ? (await api.console.common.files.post({ path: "story", _story: story?._id }, image))?.file?._id : image?._id,
                        desc,
                    }))
                );

                if (!this.isCreate) {
                    for (let i in images) {
                        let _before = images[i]?._image;
                        
                        if (_before && !_images.some(({ _image }) => _before === _image)) await api.console.common.files.delete({ _id: images[i]._image });
                    }
                }

                story = (await api.console.story.put({ _id: story._id, images: _images }))?.story;

                alert("저장되었습니다.");

                if (this.isCreate) this.$router.push(`/console/story/${story?.id}`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        isCreate() {
            this.search();
        }
    }
};
</script>