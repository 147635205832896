<template>
    <v-autocomplete v-model="item" :search-input.sync="searchValue" item-value="_id" :return-object="retrunObject" v-bind="{ ...$attrs, items, loading, itemText }" @click="alert" @keyup="onKeyup" @keyup.enter="search" @change="onChange" />
</template>

<script>
import api from "@/api";
import { USER_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: [String, Object, Array], default: null },
        retrunObject: { type: Boolean, default: false },
    },
    data: () => ({
        item: null,
        items: [],

        searchValue: null,

        loading: false,
    }),
    computed: {
        outOfSync() {
            if (this.retrunObject) {
                return (this.value?._id || null) != (this.item?._id || null);
            }
            return this.value != this.item;
        },
        params() {
            const { searchValue } = this;
            return { type: [USER_TYPES.INDIVIDUAL, USER_TYPES.ORGANIZATION], searchValue };
        },
    },
    mounted() {
        this.sync();
        this.search();
    },
    watch: {
        value() {
            this.sync();
        },
        outOfSync() {
            this.emit();
        },
    },
    methods: {
        sync() {
            if (this.retrunObject) {
                this.item = this.value ? { ...this.value } : null;
            } else {
                this.item = this.value || null;
            }
        },
        emit() {
            if (this.retrunObject) {
                this.$emit("input", this.item ? { ...this.item } : null);
            } else {
                this.$emit("input", this.item);
            }
        },
        async search() {
            this.loading = true;

            try {
                const { params } = this;
                const { users: items } = await api.console.users.gets({
                    headers: { limit: 50 },
                    params,
                });
                this.items = items;

                if (this.item) {
                    const { user: item } = await api.console.users.get({
                        _id: this.retrunObject ? this.item?._id : this.item,
                    });

                    if (item) {
                        const index = this.items.findIndex(({ _id }) => _id == item._id);

                        if (0 <= index) {
                            this.items.splice(index, 1, item);
                        } else {
                            this.items = [item, ...this.items];
                        }
                    }
                }
            } finally {
                this.loading = false;
            }
        },
        itemText({ username, name, companyName = "-", phone, email }) {
            return `${username} / ${name} / ${companyName} / ${phone} / ${email}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
        },
        onKeyup(event) {
            switch (/^Arrow/.test(event.code)) {
                case false: {
                    this.search();
                    break;
                }
            }
        },
        onChange() {
            this.$nextTick(() => this.$emit("emit"));
        },
    },
};
</script>

<style></style>
