var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("문자·알림톡 관리")]), _c('v-spacer')], 1), _vm.messages ? _c('v-data-table', {
    staticClass: "elevation-2 mt-8",
    attrs: {
      "headers": _vm.messagesHeader,
      "items": _vm.messages,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.name) + "(" + _vm._s(item.code) + ")")])];
      }
    }, {
      key: `item.enabled`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-layout', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(item.enabled ? "사용함" : "사용안함"))]), _c('v-switch', {
          on: {
            "click": function ($event) {
              return _vm.onClickSave($event, item);
            }
          },
          model: {
            value: item.enabled,
            callback: function ($$v) {
              _vm.$set(item, "enabled", $$v);
            },
            expression: "item.enabled"
          }
        })], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.test(item);
            }
          }
        }, [_vm._v("mdi-play")])];
      }
    }], null, true)
  }) : _vm._e()], 1), _c('messages-overlay', {
    ref: "messagesOverlay",
    on: {
      "save": _vm.save
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }