<template>
    <v-card v-bind="$attrs" v-on="{ click }">
        <v-hover v-slot="{ hover }">
            <v-img v-bind="{ ...$attrs, src }">
                <template #placeholder>
                    <v-overlay absolute light color="transparent">
                        <v-icon color="grey">mdi-image-broken-variant</v-icon>
                    </v-overlay>
                </template>

                <v-fade-transition>
                    <v-overlay v-show="!src" light color="transparent" absolute>
                        <v-icon color="grey">mdi-image-broken-variant</v-icon>
                    </v-overlay>
                </v-fade-transition>

                <v-fade-transition v-if="file">
                    <v-overlay absolute v-show="hover || showsMenu">
                        <image-popup v-bind="{ src }">
                            <template #activator="{ attrs, on }">
                                <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                        </image-popup>
                        <v-btn icon @click.stop.capture="remove">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-overlay>
                </v-fade-transition>
            </v-img>
        </v-hover>
        <v-file-input v-model="file" ref="fileInput" v-bind="$attrs" class="d-none" @change="emit"></v-file-input>
    </v-card>
</template>

<script>
import ImagePopup from "./image-popup.vue";

export default {
    components: {
        ImagePopup,
    },
    props: {
        value: { type: [Object, File], default: null },
    },
    data: () => ({
        file: null,
        showsMenu: false,
    }),
    computed: {
        src() {
            if (this.file instanceof File) return URL.createObjectURL(this.file);
            else return this.file?.url;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value;
        },
        emit() {
            this.$emit("input", this.file);
        },
        click() {
            if (this.file) this.showsMenu = !this.showsMenu;
            else this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
        remove() {
            this.file = null;
            this.emit();
        },
    },
};
</script>

<style>
</style>