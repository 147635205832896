<template>
    <v-autocomplete :value="value" v-bind="{ ...$attrs, items, itemText, itemValue: '_id' }" v-on="{ change }">
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        value: { type: [String, Array], default: null },
        params: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            return this.$store.state.setting?.rental?.equipment?.places || [];
        },
        item() {
            return this.items.find((item) => (this.multiple ? this.value.includes(item) : item == this.value));
        },
    },
    methods: {
        async change(newValue) {
            this.$emit("input", newValue);
        },
        itemText(item) {
            return item;
        },
    },
};
</script>

<style></style>
