var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": "",
      "dense": "",
      "color": "primary"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.hours,
      callback: function ($$v) {
        _vm.hours = $$v;
      },
      expression: "hours"
    }
  }, [_vm._l(_vm.items, function (_ref) {
    var ampm = _ref.ampm,
      hour = _ref.hour,
      value = _ref.value;
    return [_c('v-chip', {
      key: value,
      staticClass: "rounded-lg",
      staticStyle: {
        "min-width": "calc(16.66% - 8px)"
      },
      attrs: {
        "value": value,
        "outlined": "",
        "label": ""
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(ampm) + " ")]), _c('v-spacer'), _c('v-col', [_vm._v(" " + _vm._s(hour) + " ")])], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }