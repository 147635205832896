<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="설비통계 - 설비">
                <template #buttons>
                    <v-btn rounded dark color="green" class="my-n4" @click="excel">
                        <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                        <span>엑셀 다운로드</span>
                    </v-btn>
                </template>
            </list-heading>

            <rental-statistics-list-search showsSearch flat outlined dense hideLabel dateToggleMode="monthly" />

            <v-data-table v-bind="{ items, headers, loading }" disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-4" style="overflow: hidden" mobile-breakpoint="0" dense>
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <tfoot slot="foot">
                    <tr>
                        <td colspan="2" class="caption text-end">합계</td>
                        <td class="caption text-end">
                            <span> {{ summary.count }} </span>
                        </td>
                        <td class="caption text-end">
                            <span> {{ summary.users }} </span>
                        </td>
                        <td class="caption text-end">
                            <span> {{ summary.minutes }} </span>
                        </td>
                        <td class="caption text-end" v-for="i in 6" :key="i">
                            <span> {{ items.reduce((sum, item) => sum + item.desc[i] || 0, 0).format() }} 건 </span>
                        </td>
                    </tr>
                </tfoot>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initStatisticsMinutes } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import RentalStatisticsListSearch from "@/components/console/rental/statistics/rental-statistics-list-search.vue";

export default {
    components: {
        ListHeading,
        RentalStatisticsListSearch,
    },
    data: () => ({
        loading: false,
        items: [],
    }),
    computed: {
        headers() {
            const headers = [
                {
                    width: 120,
                    text: "구분",
                    value: "place",
                    cellClass: "max-width-0 text-truncate",
                    withTitle: true,
                },
                {
                    width: 160,
                    text: "설비명",
                    value: "term",
                    cellClass: "max-width-0 text-truncate",
                    withTitle: true,
                },
                {
                    width: 120,
                    text: "사용건수",
                    value: "desc.count",
                    formatter: (value) => (value ? `${value?.format?.() || value || 0}건` : "-"),
                    align: "end",
                },
                {
                    width: 120,
                    text: "사용자수",
                    value: "desc.users",
                    formatter: (value) => (value ? `${value?.format?.() || value || 0}명` : "-"),
                    align: "end",
                },
                {
                    width: 120,
                    text: "사용시간",
                    value: "desc.minutes",
                    formatter: (value) => initStatisticsMinutes(value, " "),
                    align: "end",
                },
            ];

            headers.push(
                ...new Array(6).fill().map((_, i) => ({
                    width: 120,
                    text: this.$dayjs()
                        .day(i + 1)
                        .format("dd"),
                    value: `desc.${i + 1}`,
                    formatter: (value, item) => {
                        if (!value) return "-";

                        let text = "";
                        if (item.type == "number") text = value?.format?.() || value;
                        if (item.type == "percent") text = value?.toFixed?.(2) || value;
                        if (text) text += item?.suffix || "";
                        return `${text || "-"}`;
                    },
                    align: "end",
                }))
            );

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));
        },

        summary() {
            return {
                count: `${this.items.reduce((sum, item) => sum + item.desc.count || 0, 0).format()}건`,
                users: `${this.items.reduce((sum, item) => sum + item.desc.users || 0, 0).format()}명`,
                minutes: initStatisticsMinutes(this.items.reduce((sum, item) => sum + item.desc.minutes || 0, 0)),
            };
        },

        footers() {
            return new Array(6).fill().map((_, i) => ({
                value: this.items.reduce((sum, item) => sum + item.desc[i + 1] || 0, 0),
                suffix: "건",
            }));
        },

        params() {
            let { ...query } = this.$route.query;

            if (!query.attendanceType) delete query.attendanceType;

            if (!query._subjects?.length) delete query._subjects;

            if (!query.searchDateValue || !query.searchDateValue?.[0] || !query.searchDateValue?.[1]) {
                delete query.searchDateValue;
            }

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            this.items = [];

            try {
                const { params } = this;

                const { items } = await api.console.rental.statistics.getSubject({ params });

                this.items = items;
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { params } = this;

                const { items } = await api.console.rental.statistics.getSubject({ params });

                this.$excel(items, this.headers, "설비통계_설비");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    tfoot > tr > td {
        border-top: thin solid rgba(0, 0, 0, 0.12);
    }
    tfoot > tr > td:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    th {
        user-select: auto !important;
    }
}
</style>
