<template>
    <v-chip-group>
        <v-chip v-for="{ name, type = '', href } in files" :key="href" v-bind="{ ...$attrs, href }" download>
            <v-icon v-if="type.includes('image')" small class="mr-1">mdi-image</v-icon>
            <v-icon v-else small class="mr-1">mdi-file</v-icon>
            {{ name }}
        </v-chip>
    </v-chip-group>
</template>

<script>
export default {
    props: {
        files: { type: Array, default: () => [] },
    },
};
</script>

<style>
</style>