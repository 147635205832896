var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 px-3",
    attrs: {
      "max-width": "1300px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "금산에 활력을 불어넣는 사람들"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('people-organization-form', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "tile": "",
                  "color": "primary",
                  "title": "공지사항 생성"
                }
              }, 'v-btn', attrs, false), on), [_vm._v("# 추진단 조직도 설정 ")])];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('people-list-search', _vm._b({
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, 'people-list-search', {}, false)), _c('v-data-table', _vm._b({
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('people-index-edit', _vm._b({
          on: {
            "update": _vm.update
          }
        }, 'people-index-edit', {
          value: item
        }, false), [_vm._v(" " + _vm._s(value) + " ")])];
      }
    }, {
      key: `item.thumb`,
      fn: function (_ref4) {
        var _item$thumb;
        var item = _ref4.item;
        return [_c('image-popup', _vm._b({
          staticClass: "cursor-pointer"
        }, 'image-popup', {
          src: item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.src,
          size: '30',
          rounded: true
        }, false))];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.$dayjs(item === null || item === void 0 ? void 0 : item.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: `item.isMain`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-switch', _vm._b({
          staticClass: "d-inline-block mr-n4",
          on: {
            "change": function (isMain) {
              return _vm.update({
                _id: item._id,
                isMain
              });
            }
          },
          model: {
            value: item.isMain,
            callback: function ($$v) {
              _vm.$set(item, "isMain", $$v);
            },
            expression: "item.isMain"
          }
        }, 'v-switch', Object.assign({}, _vm.switchAttrs, {
          loading: _vm.loading
        }), false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 20
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }