<template>
    <v-dialog v-model="shows" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                사용가능장비 일괄수정
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle class="caption"> 선택된 회원 또는 검색된 회원 전체를 대상으로 사용가능장비를 수정합니다 </v-card-subtitle>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 16px - 32px - 10px - 40px + 16px - 1px - 1px - 60px); overflow-y: auto">
                <v-row no-gutters>
                    <v-col cols="12" lg="8">
                        <v-sheet class="fill-height" style="max-height: calc(90vh - 16px - 32px - 10px - 40px + 16px - 1px - 1px - 60px); overflow-y: hidden">
                            <edit-equipments-select v-model="subjects" v-bind="{ shows }" @setLoading="(bool) => (loading = bool)" />
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" lg="auto">
                        <v-divider vertical class="d-none d-lg-block" />
                        <v-divider class="d-block d-lg-none" />
                    </v-col>
                    <v-col cols="12" lg="">
                        <v-sheet class="fill-height" style="max-height: calc(90vh - 16px - 32px - 10px - 40px + 16px - 1px - 1px - 60px); overflow-y: hidden">
                            <edit-equipments-result
                                v-model="message"
                                v-bind="{
                                    selected,
                                    subjects,
                                    params,
                                }"
                                @setLoading="(bool) => (loading = bool)"
                            />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn outlined color="red" v-bind="{ loading, disabled }" @click="pull">
                    <span> 사용가능장비 제거 </span>
                </v-btn>
                <v-btn color="primary" v-bind="{ loading, disabled }" @click="push">
                    <span> 사용가능장비 추가 </span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import EditEquipmentsResult from "./equipments/edit-equipments-result.vue";
import EditEquipmentsSelect from "./equipments/edit-equipments-select.vue";

export default {
    components: {
        EditEquipmentsSelect,
        EditEquipmentsResult,
    },
    props: {
        selected: { type: Array, default: () => [] },

        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        subjects: [],

        message: null,

        shows: false,
        loading: false,
    }),
    computed: {
        form() {
            const form = {};

            if (this.selected.length) {
                form._users = this.selected.map(({ _id }) => _id);
            } else {
                form.params__getUsers = { ...this.params };
            }

            form._equipments = this.subjects.map(({ _id }) => _id);

            return { ...form };
        },
        disabled() {
            if (!this.subjects.length) return true;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.subjects = [];
        },
        async push() {
            const go = confirm(`사용가능장비를 추가하시겠습니까?\r\n\r\n적용회원: ${this.message}`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.users.equipments.push(this.form);
                alert("사용가능장비 추가가 처리되었습니다");
                this.loading = false;
                this.$emit("search");
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async pull() {
            const go = confirm(`사용가능장비를 제거하시겠습니까?\r\n\r\n적용회원: ${this.message}`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.users.equipments.pull(this.form);
                alert("사용가능장비 제거가 처리되었습니다");
                this.loading = false;
                this.$emit("search");
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
