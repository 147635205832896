<template>
    <v-text-field v-bind="$attrs">
        <template #prepend-inner>
            <slot />
        </template>
    </v-text-field>
</template>

<script>
export default {};
</script>


<style lang="scss" scoped>
::v-deep {
    .v-text-field__slot > label {
        max-width: initial;
        width: initial;
        transition: none;
    }
}
</style>