<template>
    <v-row>
        <v-col cols="12">
            <story-view-image-form v-model="form[0]" @input="emit"/>
        </v-col>
        <v-col cols="12">
            <story-view-image-form v-model="form[1]" @input="emit"/>
        </v-col>
        <v-col cols="12">
            <story-view-image-form v-model="form[2]" @input="emit"/>
        </v-col>
    </v-row>
</template>

<script>
import { initStoryImages } from "@/assets/variables";
import StoryViewImageForm from "./story-view-image-form.vue";
export default {
    components: {
        StoryViewImageForm,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        form: [],
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = this.value.map(initStoryImages);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>