<template>
    <v-dialog v-model="shows" max-width="1440" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                교육 불러오기
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y: auto">
                <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template #top>
                        <program-list-filter v-model="query" mode="model" flat @input="page = 1" />
                        <v-divider />
                    </template>

                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                    <template #[`item.thumb`]="{ value }">
                        <image-popup :src="value?.src" rounded outlined />
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn color="primary" v-bind="{ loading }" @click="emit(item)"> 선택하기</v-btn>
                    </template>
                    <template #footer>
                        <v-divider />
                        <v-pagination v-model="page" :length="pageCount" :total-visible="20" color="primary" class="my-2" />
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initProgram, PROGRAM_STATES, PROGRAM_STATES__APPLICATION, USER_TYPES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ProgramListFilter from "../program-list-filter.vue";

const headers = [
    { text: "번호", value: "index" },
    { text: "분류", value: "category.name" },
    { text: "썸네일", value: "thumb" },
    { text: "교육명", value: "name" },
    { text: "사용장비", value: "subjects", formatter: (value = []) => value.map(({ name }) => name).join("\n") },
    { text: "대상회원유형", value: "userTypes", formatter: (value = []) => value.map((key) => ({ [USER_TYPES.INDIVIDUAL]: "개인회원", [USER_TYPES.ORGANIZATION]: "기업회원" }[key])).join(", ") },
    { text: "인원", value: "capacity" },
    { text: "접수기간", value: "application.period" },
    { text: "진행기간", value: "period" },
    { text: "", value: "actions", width: 128 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ImagePopup,
        ProgramListFilter,
    },
    props: {
        value: { type: Object, default: initProgram },
    },
    data: () => ({
        programs: [],

        query: undefined,

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        headers,

        shows: false,
        loading: false,
    }),
    computed: {
        items() {
            return this.programs.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.query || {};
            return { ...query };
        },
    },
    watch: {
        shows() {
            if (this.shows) this.search();
        },
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, programs } = await api.console.programs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.programs = programs;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async emit(item) {
            if (this.loading) return;
            else this.loading = true;
            try {
                item = initProgram(item);

                delete item._id;
                delete item.createdAt;

                delete item._thumb;
                if (item.thumb) item.thumb = item.thumb instanceof File ? item.thumb : await api.getResource(item.thumb.url);

                delete item._units;
                if (item.units.length) item.units = item.units.map(({ _id, ...unit }) => unit);

                delete item._attendees;
                delete item.attendees;

                delete item._applications;
                delete item.applications;

                this.$emit("input", item);
                this.shows = false;
                alert("해당 교육을 불러왔습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
