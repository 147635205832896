<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                링크등록
            </v-col>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col v-if="manage.code === 'main' " cols="12" class="py-0">
                            <v-select placeholder="선택해주세요" v-model="manage.subject" :items="['메이커스 장비 예약', '시설 및 공간 예약', '입주신청', '전자제조 지원신청']" persistent-placeholder hide-details />
                        </v-col>
                        <v-col v-if="manage.code === 'program'" cols="12" class="py-0">
                            <v-select placeholder="선택해주세요" v-model="manage.subject" :items="['장비운영교육', '기술제조기반 교육 지원', '전자제조 초도양산', '3D모델링지원', '투자 및 마케팅 지원']" persistent-placeholder hide-details />
                        </v-col>
                        <v-col v-if="manage.code === 'info'">
                            <v-select placeholder="선택해주세요" v-model="manage.subject" :items="['입주신청링크']" persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">링크</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col class="py-0">
                            <v-text-field v-model="manage.link" placeholder="https://kea-blacksmith.uxi.kr/rental/equipments"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    components: {
    },
    data() {
        return {
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            thumb: undefined,
            manage: {
                code: this.$route.query.code,

                subject: undefined,
                link: undefined,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._manage) {
                    var { manage } = await api.console.manage.get({ _id: this.$route.params._manage });
                    this.manage = manage;
                    this.manage.code = manage.code;

                    if (manage.thumb) this.thumb = await api.getResource(manage.thumb);
                }

                if (!this.$route.params._manage === undefined) {
                    var { summary } = await api.console.manage.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////
                ////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                let { manage } = this.manage._id ? await api.console.manage.put(this.manage) : await api.console.manage.post(this.manage);

                // ////////////////////////////////////////////////////////////////
                // // 2. 리소스 저장
                // ////////////////////////////////////////////////////////////////
                if (this.thumb) await api.console.manage.postThumb(manage, this.thumb);

                // alert(this.manage._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
};
</script>
