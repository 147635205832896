<template>
    <view-section-card title="예약신청 상세" class="fill-height">
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <autocomplete-user v-model="request.user" label="회원" retrunObject v-bind="{ ...inputAttrs, loading, disabled }" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <autocomplete-rental-subject v-model="request.subject" label="장비" retrunObject v-bind="{ ...inputAttrs, loading }" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-text-field v-model="request.desc.objective" label="사용목적" v-bind="{ ...inputAttrs, loading }" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <view-section-field label="날짜선택" v-bind="inputAttrs">
                        <rental-form-calendar v-model="request.date" v-bind="{ subject }" width="100%" @input="emit" />
                    </view-section-field>
                </v-col>
            </v-row>
        </v-card-text>
    </view-section-card>
</template>

<script>
import { initRentalRequest, inputAttrs } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ViewSectionField from "@/components/console/dumb/view-section-field.vue";

import RentalFormCalendar from "@/components/dumb/rental/form/rental-form-calendar.vue";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

export default {
    props: {
        value: { type: Object, default: initRentalRequest },
        schedules: { type: Array, default: () => [] },

        loading: { type: Boolean, default: false },
    },

    components: {
        ViewSectionCard,
        ViewSectionField,

        RentalFormCalendar,

        AutocompleteUser,
        AutocompleteRentalSubject,
    },

    data: () => ({
        request: initRentalRequest(),

        inputAttrs,
    }),
    computed: {
        subject() {
            return this.request?.subject || undefined;
        },
        disabled() {
            return !!this.schedules.length;
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.request = initRentalRequest(this.value);
        },
        emit() {
            this.$emit("input", this.request);
        },
    },
};
</script>

<style>
</style>