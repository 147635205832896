<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout v-if="setting" justify-center>
            <v-responsive max-width="1000px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">설정</v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto pt-0">SMTP 설정 (이메일 전송 설정) 변경</v-col>
                            <v-col class="primary--text caption pt-0 pl-0">
                                "Naver 메일 -> 환경설정 -> POP3/IMAP 설정 -> IMAP/SMTP 설정 -> IMAP/SMTP 사용" 을 사용함으로 변경
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="smtp.username" label="발신자 아이디 (네이버)" persistent-placeholder dense hide-details />
                        <v-text-field v-model="smtp.password" type="password" label="비밀번호" persistent-placeholder dense hide-details class="mt-6" />
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto pt-0">관리자 이메일 추가 등록</v-col>
                            <v-spacer></v-spacer> <!-- Spacer 추가 -->
                            <v-btn @click="addEmail" color="primary">추가하기</v-btn> <!-- 버튼을 오른쪽에 배치 -->
                        </v-row>
                    </v-card-title>
                    <v-card-text v-for="email, idx in emailList" :key="idx">
                        <div>
                            <v-text-field v-model="emailList[idx]" label="수신자 아이디" persistent-placeholder dense hide-details />
                        </div>
                    </v-card-text>
                </v-card>

                <v-btn fixed bottom right fab x-large color="primary" @click="save">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import banks from "@/assets/data/banks.json";
import vPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import CryptoAes from "@/plugins/crypto-aes";

export default {
    components: {
        vPasswordField,
    },
    created() {
        this.init();
    },
    data() {
        return {
            smtp: {
                username: null,
                password: null
            },
            setting: {
            },
            banks,
            emailList: []
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            this.setting = setting;
            this.smtp = this.setting.smtp || {};
            this.emailList = this.setting.emailUserList || [];
        },

        meta(name) {
            var meta = this.setting.seo.meta.find((meta) => meta.name == name);
            if (!meta) {
                meta = { name, content: null };
                this.setting.seo.meta.push(meta);
            }
            return meta;
        },

        async save() {
            this.setting.smtp = this.smtp;
            this.setting.emailUserList = this.emailList;
            const setting = JSON.parse(JSON.stringify(this.setting));
            if (setting?.smtp?.password) setting.smtp.password = CryptoAes.encrypt(setting.smtp.password);
            await api.console.setting.put(setting);

            alert("저장되었습니다.");
        },
        addEmail(){
            this.emailList =  [ ...this.emailList, null ]; 
        }
    },
};
</script>
