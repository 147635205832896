<template>
    <v-select v-model="field" v-bind="{ ...$attrs, items }" @input="emit" />
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

const items = [
    { text: "개인회원", value: USER_TYPES.INDIVIDUAL },
    { text: "기업회원", value: USER_TYPES.ORGANIZATION },
];

export default {
    props: {
        value: { type: [String, Array], default: null },
    },
    data: () => ({
        field: null,
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.field = Array.isArray(this.value) ? [...this.value] : this.value;
        },
        emit() {
            this.$emit("input", this.field);
        },
    },
};
</script>

<style></style>
