<template>
    <v-edit-dialog :return-value.sync="item.note" large cancel-text="취소" save-text="저장" v-on="{ save }">
        <slot />
        <template #input>
            <v-sheet width="320" min-height="240" class="mt-4">
                <v-textarea v-model="item.note" v-bind="inputAttrs" rows="9" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, note } = this.item;
            let form = { _id, note };

            this.$emit("update", form);
        },
    },
};
</script>

