var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "height": "100%"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm.isCreate ? [_vm._v("교육 생성")] : [_vm._v("교육 상세")], _vm.isCreate ? _c('program-form-clone', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-2",
          attrs: {
            "outlined": "",
            "small": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-folder-open-outline")]), _vm._v(" 불러오기 ")], 1)];
      }
    }], null, false, 3692888303),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "4"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("썸네일")]), _c('v-divider'), _c('v-card-text', [_c('image-input', {
    attrs: {
      "flat": "",
      "tile": "",
      "aspect-ratio": 1 / 1
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobile,
      expression: "!isMobile"
    }],
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMobile,
      expression: "isMobile"
    }]
  })], 1), _c('v-col', [_c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("기본 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('autocomplete-program-category', _vm._b({
    attrs: {
      "label": "분류"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_category", $$v);
      },
      expression: "form._category"
    }
  }, 'autocomplete-program-category', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('select-user-type', _vm._b({
    attrs: {
      "label": "대상회원유형",
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.userTypes,
      callback: function ($$v) {
        _vm.$set(_vm.form, "userTypes", $$v);
      },
      expression: "form.userTypes"
    }
  }, 'select-user-type', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "교육명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "모집인원",
      "suffix": "명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.capacity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "capacity", $$v);
      },
      expression: "form.capacity"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "접수기간(시작)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.application.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.application, "startsAt", $$v);
      },
      expression: "form.application.startsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "접수기간(종료)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.application.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.application, "endsAt", $$v);
      },
      expression: "form.application.endsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "진행기간(시작)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startsAt", $$v);
      },
      expression: "form.startsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "진행기간(종료)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endsAt", $$v);
      },
      expression: "form.endsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "노출기간(시작)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.display.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "startsAt", $$v);
      },
      expression: "form.display.startsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "노출기간(종료)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.display.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "endsAt", $$v);
      },
      expression: "form.display.endsAt"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('form-table-row', {
    attrs: {
      "label": "회차",
      "cols": "2",
      "contentClass": "px-0"
    }
  }, [_c('program-form-units', {
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('form-table-row', {
    attrs: {
      "label": "사용장비",
      "cols": "2",
      "contentClass": "px-0"
    }
  }, [_c('autocomplete-rental-subject', _vm._b({
    attrs: {
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref3) {
        var parent = _ref3.parent,
          item = _ref3.item,
          attrs = _ref3.attrs,
          itemText = _ref3.itemText;
        return [_c('v-chip', _vm._b({
          key: item._id,
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(_vm._s(itemText(item)))])];
      }
    }]),
    model: {
      value: _vm.form._subjects,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_subjects", $$v);
      },
      expression: "form._subjects"
    }
  }, 'autocomplete-rental-subject', _vm.attrs_input__verticalTable, false))], 1), _c('v-card-subtitle', {
    staticClass: "grey lighten-5"
  }, [_vm._v("상세 내용")]), _c('v-divider'), _c('naver-smarteditor', {
    model: {
      value: _vm.form.detail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "detail", $$v);
      },
      expression: "form.detail"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "px-6 grey lighten-5"
  }, [_c('v-col', {
    staticClass: "pa-0",
    staticStyle: {
      "min-width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', _vm.switchAttrs, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.shows ? "노출" : "미노출") + " ")])])], 1), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 저장하기 ")])], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }