<template>
    <list-search v-bind="{ loading, showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query.searchDateValue[0]" type="date" max="2999-12-31" label="작성일(시작)" clearable v-bind="attrs_input" @input="setQuery"/>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query.searchDateValue[1]" type="date" max="2999-12-31" label="작성일(종료)" clearable v-bind="attrs_input" @input="setQuery"/>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
                <v-select v-model="query['searchKey']" :items="searchKeys" label="검색명" outlined class="v-input--small w-md-100px" v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query['searchValue']" outlined placeholder="검색어를 입력하세요." class="v-input--small w-md-240px" v-bind="attrs_input" @keydown.enter="setQuery" />
            </v-col>
            <!-- <v-col cols="12" sm="6" lg="3">
                <autocomplete-user label="사용자" v-model="query._user" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col> -->
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    searchKey: query?.searchKey || "all",
    searchValue: query?.searchValue || null,
    ["searchDateValue"]: [...(query?.["searchDateValue"] || [null, null])],
});

export default {
    components: {
        ListSearch,
        // AutocompleteUser,
        // AutocompleteProduct,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
        searchKeys: [
            { text: "전체", value: "all" },
            { text: "작성자명", value: "name" },
            { text: "작성자 아이디", value: "username" },
            { text: "제목", value: "subject" },
            { text: "내용", value: "content" },
            { text: "답변", value: "reply" },
        ],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            if (!query?.["searchDateValue"]) delete query["searchDateValue"];
            if (!query?.["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
