<template>
    <v-layout column fill-height>
        <v-card flat tile style="flex: 1 1 auto" v-bind="{ loading }">
            <v-card-title class="subtitle-2 font-weight-bold"> 선택한 장비 </v-card-title>
            <v-card-text>
                {{ subjects.map(({ name }) => name).join(" / ") || "장비를 선택해주세요" }}
            </v-card-text>
        </v-card>
        <v-divider />
        <v-card flat tile style="flex: 1 1 auto" v-bind="{ loading }">
            <v-card-title class="subtitle-2 font-weight-bold"> 적용할 회원 </v-card-title>
            <v-card-text>
                {{ selectedText }}
            </v-card-text>
        </v-card>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: String, default: null },

        selected: { type: Array, default: () => [] },
        subjects: { type: Array, default: () => [] },

        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        selectedText() {
            const items = this.selected.length ? this.selected : this.users;
            if (!items.length) return "회원을 특정해주세요";

            let text = items.map(({ name, username }) => `${name}(${username})`).join(", ");

            if (!this.selected.length) {
                const countLeft = this.summary.totalCount - items.length;
                if (countLeft) {
                    text += ` 외 ${countLeft.format()}명`;
                }
            }
            return text;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { limit, params } = this;
                var { summary, users } = await api.console.users.gets({
                    headers: { limit },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } finally {
                this.loading = false;

                this.emit();
            }
        },

        emit() {
            this.$emit("input", this.selectedText);
        },
    },
};
</script>

<style>
</style>