<template>
    <v-layout justify-center>
        <v-responsive max-width="720px" width="100%" height="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">문자·알림톡 관리</v-col>
                <v-spacer />
            </v-row>

            <v-data-table v-if="messages" :headers="messagesHeader" :items="messages" disable-sort disable-pagination hide-default-footer class="elevation-2 mt-8">
                <template v-slot:[`item.name`]="{ item }">
                    <span class="font-weight-bold">{{ item.name }}({{ item.code }})</span>
                </template>
                <template v-slot:[`item.enabled`]="{ item }">
                    <v-layout class="d-flex align-center justify-center">
                        <span class="mr-2">{{ item.enabled ? "사용함" : "사용안함" }}</span>
                        <v-switch v-model="item.enabled" @click="onClickSave($event, item)" />
                    </v-layout>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    <v-icon small class="ml-2" @click="test(item)">mdi-play</v-icon>
                </template>
            </v-data-table>
        </v-responsive>

        <messages-overlay ref="messagesOverlay" @save="save" />
    </v-layout>
</template>
<script>
import api from "@/api";
import MessagesOverlay from "@/components/console/messages/messages-overlay.vue";
export default {
    components: { MessagesOverlay },
    data() {
        return {
            messagesHeader: [
                {
                    text: "구분",
                    value: "name",
                    align: "center",
                },
                {
                    text: "종류",
                    value: "type",
                    align: "center",
                    width: 160,
                },
                {
                    text: "사용안함/사용",
                    value: "enabled",
                    align: "center",
                    width: 160,
                },
                {
                    text: "Actions",
                    value: "actions",
                    align: "center",
                    width: 160,
                },
            ],
            messages: [
                // {
                //     _id: "608a7c4fedcbf49f70756947",
                //     name: "주문시",
                //     code: "order",
                //     type: "문자",
                //     enabled: false,
                //     templateCode: null,
                //     template: null,
                //     receiver: null,
                // },
            ],
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            try {
                const { messages } = await api.console.messages.gets();
                this.messages = messages;
            } catch (error) {
                this.handleError(error);
            }
        },
        onClickSave(event, item) {
            event.preventDefault();
            this.save({ _id: item._id, enabled: !item.enabled });
        },
        async save(message) {
            try {
                const { message: resMessage } = await api.console.messages.put(message);
                Object.assignDefined(
                    this.messages.find(({ _id }) => _id === resMessage._id),
                    resMessage
                );
                if (this.$refs.messagesOverlay.show) this.$refs.messagesOverlay.close();
            } catch (error) {
                this.handleError(error);
            }
        },
        handleError(error) {
            console.error(error);
            alert(error.response ? error.response.data.message : error.message);
        },
        edit(item) {
            this.$refs.messagesOverlay.item = item;
            this.$refs.messagesOverlay.open();
        },
        async test({ code }) {
            try {
                const response = await api.console.messages.testMessage({ code, data: { _inquiry: "6094c6a95e68a54390c0ea9b" } });
                console.log(response);
            } catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
