<template>
    <view-section-card title="일정선택" class="fill-height" v-bind="{ loading }">
        <v-card-text>
            <template v-if="loading"> 일정을 불러오고 있습니다. </template>
            <template v-else-if="request.subject">
                <schedules
                    v-model="schedules"
                    v-bind="{
                        request,
                        blockages,
                        schedulesOnCloud,
                    }"
                    @input="emit"
                />
            </template>
            <template v-else> 장비를 선택해주세요 </template>
        </v-card-text>
        <template v-if="request?.subject?._id">
            <rental-subject-blockages
                v-model="blockages"
                v-bind="{
                    date: request.date,
                    subject: request.subject || undefined,
                }"
                @setLoading="(bool) => (loading = bool)"
            />
            <rental-subject-schedules-on-cloud
                v-model="schedulesOnCloud"
                v-bind="{
                    date: request.date,
                    subject: request.subject || undefined,
                    loading,
                }"
                @setLoading="(bool) => (loading = bool)"
            />
        </template>
    </view-section-card>
</template>

<script>
import { initRentalRequest, inputAttrs } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import Schedules from "./schedules/schedules.vue";

import RentalSubjectBlockages from "@/components/client/rental/rental-subject-blockages.vue";
import RentalSubjectSchedulesOnCloud from "@/components/client/rental/rental-subject-schedules-on-cloud.vue";

export default {
    components: {
        ViewSectionCard,

        Schedules,

        RentalSubjectBlockages,
        RentalSubjectSchedulesOnCloud,
    },
    props: {
        value: { type: Array, default: () => [] }, // schedules
        request: { type: Object, default: initRentalRequest },
    },
    data: () => ({
        schedules: [],

        blockages: [],
        schedulesOnCloud: [],

        inputAttrs,

        loading: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async sync() {
            this.schedules = [...this.value];
        },
        async emit() {
            this.$emit("input", this.schedules);
        },
    },
};
</script>

<style>
</style>