<template>
    <v-dialog v-model="shows" width="680px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                <template>연혁 등록</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="6" class="pa-2">
                        <v-date-month-field v-model="month" v-bind="{ ...attrs_input }" label="년/월" />
                    </v-col>
                    <v-col cols="6" class="pa-2">
                        <v-text-field v-model="form.index" type="number" v-bind="{ ...attrs_input }" label="순서" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-textarea v-model="form.content" v-bind="{ ...attrs_input }" rows="3" label="설명" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-switch v-model="form.shows" v-bind="{ ...switchAttrs }" label="노출" />
                <v-spacer />
                <v-btn @click="() => (shows = false)">취소</v-btn>
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initHistory, attrs_input, switchAttrs } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import VDateMonthField from "@/components/plugins/vuetify/v-date-month-field.vue";

export default {
    components: {
        ListHeading,
        VDateMonthField,
    },
    props: {
        value: { type: Object, default: initHistory },
    },
    data: () => ({
        attrs_input,
        switchAttrs,
        shows: false,
        loading: false,
        month: null,

        form: initHistory(),
    }),
    computed: {
        isCreate() {
            return !this.form._id;
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                if (!this.form.year && !this.form.month) throw new Error("년/월을 선택해주세요");
                if (!this.form.content) throw new Error("설명을 입력해주세요");

                let { put, post } = api.console.archiving.history;

                await (this.isCreate ? post : put)(this.form);

                alert("저장되었습니다.");

                this.$emit("input");
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = initHistory(this.value);
            this.month = null;

            if (!this.isCreate) {
                let { month, year } = this.form;
                this.month = `${year}-${month}`;
            }
        },
    },
    watch: {
        shows() {
            this.sync();
        },
        value() {
            this.sync();
        },
        month() {
            let [year, month] = this.month.split("-");
            this.form.year = year;
            this.form.month = month;
        },
    },
};
</script>

<style>
</style>