<template>
    <view-section-card title="주소">
        <v-row class="ma-n2">
            <v-col cols="12" md="12" xl="12" class="px-4">
                <v-text-field v-model="form.postcode" label="우편번호" v-bind="inputAttrs" @input="emit" disabled/>
            </v-col>
            <v-col cols="12" md="12" xl="12" class="px-4">
                <v-text-field v-model="form.address1" label="기본주소" v-bind="inputAttrs" @input="emit" disabled/>
            </v-col>
            <v-col cols="12" md="12" xl="12" class="px-4">
                <v-text-field v-model="form.address2" label="상세주소" v-bind="inputAttrs" @input="emit" disabled/>
            </v-col>
        </v-row>
    </view-section-card>
</template>

<script>
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import { initUser, inputAttrs } from "@/assets/variables";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUser },
    },
    data: () => ({
        form: initUser(),

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUser(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>