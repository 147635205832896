var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([_vm.isCommunity ? {
      key: "buttons",
      fn: function () {
        return [_c('board-category-list', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "tile": "",
                  "color": "primary",
                  "title": "공지사항 생성"
                }
              }, 'v-btn', attrs, false), on), [_vm._v("# 자유게시판 카테고리 설정 ")])];
            }
          }], null, false, 3495192402)
        })];
      },
      proxy: true
    } : null], null, true)
  }), _c('board-list-search', _vm._b({
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, 'board-list-search', {
    isCommunity: _vm.isCommunity
  }, false)), _c('v-data-table', _vm._b({
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(_vm.summary.totalCount - _vm.skip - index) + " ")];
      }
    }, {
      key: `item.isNotice`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', _vm._b({
          staticClass: "d-inline-block mr-n4",
          on: {
            "change": function (isNotice) {
              return _vm.update({
                _id: item._id,
                isNotice
              });
            }
          },
          model: {
            value: item.isNotice,
            callback: function ($$v) {
              _vm.$set(item, "isNotice", $$v);
            },
            expression: "item.isNotice"
          }
        }, 'v-switch', Object.assign({}, _vm.switchAttrs, {
          loading: _vm.loading
        }), false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('board-content-preview', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-file-find ")])], 1)];
            }
          }], null, true)
        }, 'board-content-preview', {
          content: item === null || item === void 0 ? void 0 : item.content,
          youtubeCode: item === null || item === void 0 ? void 0 : item.youtubeCode
        }, false)), !_vm.isCommunity ? _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1) : _vm._e(), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.itemHeaders,
    items: _vm.items,
    loading: _vm.loading
  }, false)), !_vm.isCommunity ? _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }