<template>
    <v-dialog v-model="shows" width="700px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>소개 영상 유튜브 링크 설정</v-card-title>
            <v-card-text>
                <v-card-subtitle>유튜브 영상 -> 공유 -> 퍼가기 -> embed/ 다음 코드 복사</v-card-subtitle>
                <v-text-field v-model="youtubeCode" v-bind="inputAttrs" label="유튜브 링크" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-bind="inputAttrs" @click="() => (shows = false)">취소</v-btn>
                <v-btn v-bind="inputAttrs" color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";
export default {
    data: () => ({
        inputAttrs,

        shows: false,
        youtubeCode: "",
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { setting } = await api.console.setting.get({});
                this.youtubeCode = setting?.archiving?.youtubeCode;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async save() {
            try {
                let form = { archiving: { youtubeCode: this.youtubeCode } };
                await api.console.setting.put(form);

                alert("저장되었습니다");
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style>
</style>