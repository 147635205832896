<template>
    <v-dialog max-width="480">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>예약신청 신청내용</v-card-title>
            <v-card-text class="mt-n4 pa-6 caption">
                <template v-for="({ term, desc }, index) in items">
                    <v-row class="ma-n4 text-start" style="word-break: keep-all" :key="index">
                        <v-col cols="auto" class="px-4">
                            {{ term }}
                        </v-col>
                        <v-col>
                            {{ desc }}
                        </v-col>
                    </v-row>
                </template>
                <template v-if="!items.length"> - </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { initRentalRequest } from "@/assets/variables";

export default {
    props: {
        item: { type: Object, default: initRentalRequest },
    },
    computed: {
        items() {
            let items = [
                { term: "사용목적", desc: this.item?.desc?.objective },
                { term: "작업계획", desc: this.item?.desc?.procedure },
                { term: "사용재료", desc: this.item?.desc?.substance },
                { term: "사용인원", desc: this.item?.desc?.headcount },
            ];

            return items.filter(({ desc }) => desc);
        },
    },
};
</script>

<style>
</style>