<template>
    <view-section-card v-bind="{ loading }" :title="title">
        <template #actions>
            <v-switch v-model="form.shows" v-bind="{ ...inputAttrs }" label="노출" @change="emit" />
        </template>
        <v-col>
            <v-textarea v-model="form.desc" v-bind="{ ...inputAttrs }" label="설명" @input="emit" />
        </v-col>
    </view-section-card>
</template>

<script>
import { initStoryResourcesForm, inputAttrs } from "@/assets/variables";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        title: { type: String, default: "" },
        value: { type: Object, default: initStoryResourcesForm },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        inputAttrs,

        form: initStoryResourcesForm(),
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initStoryResourcesForm(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>