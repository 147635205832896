<template>
    <v-dialog v-model="show" max-width="900px">
        <v-card v-if="item">
            <v-card-title class="subtitle-1 font-weight-bold">
                <v-row align="center">
                    <v-col cols="auto" class="pa-0 pl-3">{{ inputs.name }}({{ inputs.code }})</v-col>
                    <v-spacer />
                    <v-col cols="auto" class="pa-0 pr-2">
                        <span class="body-2">사용안함/사용</span>
                    </v-col>
                    <v-col cols="auto" class="pa-0 pr-1">
                        <v-switch dense hide-details class="ma-0 mt-2 pa-0 d-inline-block" v-model="inputs.enabled" />
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider v-show="inputs.enabled" />

            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="5" class="pa-0">
                        <v-select label="문자/알림톡" persistent-placeholder dense hide-details :items="['문자', '알림톡']" v-model="inputs.type" class="mt-6" :disabled="!inputs.enabled" />
                        <v-text-field label="템플릿코드" persistent-placeholder dense hide-details v-model="inputs.templateCode" class="mt-6" :disabled="!inputs.enabled" />
                        <v-textarea label="템플릿" persistent-placeholder dense hide-details v-model="inputs.template" class="mt-6" :disabled="!inputs.enabled" />
                        <v-text-field label="수신자이름" persistent-placeholder dense hide-details v-model="inputs.receiverName" class="mt-6" :disabled="!inputs.enabled" />
                        <v-text-field label="수신자번호" persistent-placeholder dense hide-details v-model="inputs.receiverPhone" class="mt-6" :disabled="!inputs.enabled" />
                    </v-col>
                    <messsages-template-variables />
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn color="primary" @click="inputs = { ...item }"><v-icon class="mr-2">mdi-refresh</v-icon>초기화</v-btn>
                <v-spacer />
                <v-btn text color="grey" class="font-weight-black" @click="save">취소</v-btn>
                <v-btn text color="primary" class="font-weight-black" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import MesssagesTemplateVariables from "./messsages-template-variables.vue";
export default {
    components: { MesssagesTemplateVariables },
    data() {
        return {
            show: false,
            inputs: {
                _id: "",
                name: "",
                code: "",
                type: "",
                enabled: false,
                templateCode: "",
                template: "",
                receiverName: "",
                receiverPhone: "",
            },
            item: undefined,
        };
    },
    methods: {
        open() {
            this.inputs = { ...this.item };
            this.show = true;
        },
        close() {
            this.show = false;
        },
        save() {
            this.$emit("save", this.inputs);
        },
    },
};
</script>
