var render = function render(){
  var _vm$program;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "mx-n3 pa-3",
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": `교육 상세 - ${(_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : _vm$program.name}`
    }
  }), _c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "show-arrows": "",
      "icons-and-text": "",
      "color": "secondary"
    }
  }, [_c('v-tab', {
    attrs: {
      "exact": "",
      "to": `/console/programs/${_vm._program}`
    }
  }, [_vm._v(" 교육 "), _c('v-icon', [_vm._v("mdi-human-male-board")])], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-tab', {
    attrs: {
      "exact": "",
      "to": `/console/programs/${_vm._program}/applications`
    }
  }, [_vm._v(" 신청서 "), _c('v-icon', [_vm._v("mdi-file-document-multiple")])], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-tab', {
    attrs: {
      "exact": "",
      "to": `/console/programs/${_vm._program}/attendees`
    }
  }, [_vm._v(" 참여자 "), _c('v-icon', [_vm._v("mdi-account-multiple")])], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-tab', {
    attrs: {
      "exact": "",
      "to": `/console/programs/${_vm._program}/units`
    }
  }, [_vm._v(" 참석 "), _c('v-icon', [_vm._v("mdi-checkbox-marked")])], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-divider'), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "information"
    }
  }, [_c('program-information', _vm._b({}, 'program-information', {
    program: _vm.program
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": "applications"
    }
  }, [_c('program-applications', _vm._b({}, 'program-applications', {
    program: _vm.program
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": "attendees"
    }
  }, [_c('program-attendees', _vm._b({}, 'program-attendees', {
    program: _vm.program
  }, false))], 1), _c('v-tab-item', {
    attrs: {
      "value": "units"
    }
  }, [_c('program-units', _vm._b({}, 'program-units', {
    program: _vm.program
  }, false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }