<template>
    <v-edit-dialog :return-value.sync="form.index" large cancel-text="취소" save-text="저장" v-on="{ save }" @open="sync">
        <slot />
        <template #input>
            <v-sheet width="240" class="mt-5">
                <v-textarea v-model="form.desc.rejection" clearable v-bind="inputAttrs" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import { initRentalRequest, inputAttrs } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        form: initRentalRequest(),

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initRentalRequest(this.value);
        },
        save() {
            let { _id, desc } = this.form;
            let form = { _id, desc };

            this.$emit("update", form);
        },
    },
};
</script>

