<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="교육 목록">
                <template #add-button>
                    <program-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="교육 생성" v-bind="attrs" v-on="on">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    </program-form>
                </template>
            </list-heading>

            <program-list-filter flat outlined />

            <v-card outlined class="mt-4" style="overflow: hidden">
                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                    <template #[`item.thumb`]="{ value }">
                        <image-popup :src="value?.src" rounded outlined />
                    </template>

                    <template #[`item.subjects`]="{ item, value, header }">
                        <program-item-subjects v-bind="{ item }">
                            <template #activator="{ attrs, on }">
                                <v-card flat tile :width="header.width ?? 200" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                            </template>
                        </program-item-subjects>
                    </template>

                    <template #[`item.actions`]="{ item }">
                        <v-btn small text icon :to="`/console/programs/${item._id}`">
                            <v-icon small> mdi-eye </v-icon>
                        </v-btn>
                        <program-form :value="item" @input="(item) => updateItem(item)">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                            </template>
                        </program-form>

                        <v-btn small text icon @click="remove(item)">
                            <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                    </template>

                    <template #footer>
                        <v-divider />
                        <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                    </template>
                </v-data-table>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PROGRAM_STATES, PROGRAM_STATES__APPLICATION, USER_TYPES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProgramForm from "@/components/console/program/form/program-form.vue";
import ProgramListFilter from "@/components/console/program/program-list-filter.vue";
import ProgramItemSubjects from "@/components/console/program/item/program-item-subjects.vue";

const headers = [
    { text: "#", value: "index", width: +60 },
    { text: "분류", value: "category.name", width: 120, align: "left" },
    { text: "교육명", value: "name", cellClass: "max-width-0 text-truncate", withTitle: true, align: "left" },
    { text: "썸네일", value: "thumb", width: +60, class: "pa-0", cellClass: "pa-1" },
    { text: "사용장비", value: "subjects", formatter: (value = []) => value.map(({ name }) => name).join("\n"), cellClass: "pa-0", width: 180, align: "left" },
    { text: "회원유형", value: "userTypes", formatter: (value = []) => value.map((key) => ({ [USER_TYPES.INDIVIDUAL]: "개인회원", [USER_TYPES.ORGANIZATION]: "기업회원" }[key])).join("\r\n"), width: +90 },
    { text: "인원", value: "capacity", class: "pa-0", width: +60 },
    { text: "신청서", value: "_applications.length", formatter: (value) => value || 0, class: "pa-0", width: +60 },
    { text: "참여자", value: "_attendees.length", formatter: (value) => value || 0, class: "pa-0", width: +60 },
    { text: "접수기간", value: "application.period", formatter: (value) => value.split(" ~ ").join("　\r\n~ "), width: 110, align: "left" },
    { text: "접수상태", value: "application.state", formatter: (key) => PROGRAM_STATES__APPLICATION[key]?.text, width: +90 },
    { text: "진행기간", value: "period", formatter: (value) => value.split(" ~ ").join("　\r\n~ "), width: 110, align: "left" },
    { text: "진행상태", value: "state", formatter: (key) => PROGRAM_STATES[key]?.text, width: +90 },
    { text: "", value: "actions", align: "right", width: 116 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

export default {
    components: {
        ImagePopup,
        ListHeading,
        ProgramForm,
        ProgramListFilter,
        ProgramItemSubjects,
    },
    data: () => ({
        programs: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
    }),
    computed: {
        items() {
            return this.programs.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            delete query.sort;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, programs } = await api.console.programs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.programs = programs;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },

        async updateItem(item) {
            const index = this.programs.findIndex(({ _id }) => _id == item._id);
            if (index == -1) this.search();
            else this.programs.splice(index, 1, item);
        },

        async update(item) {
            const { space } = await api.console.programs.put(item);
            this.updateItem(space);
        },

        async putUserEquipemnts(item) {
            const go = confirm(`참석자에게 권한을 주시겠습니까?`);
        },

        async remove(item) {
            const go = confirm(`다음 프로그램을 삭제하시겠습니까?\n\n프로그램명: ${item?.name}`);
            if (!go) return;
            await api.console.programs.delete(item);
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
