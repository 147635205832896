var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "700px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({}, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_c('list-heading', {
    attrs: {
      "title": "금산마을 지역 리스트"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('story-region-form', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": "",
                  "color": "primary",
                  "title": "공지사항 생성"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-spacer'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.regionItems
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.filter.parent,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "parent", $$v);
      },
      expression: "filter.parent"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-data-table', _vm._b({
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref3) {
          var value = _ref3.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref4) {
        var index = _ref4.index;
        return [_vm._v(" " + _vm._s(_vm.summary.totalCount - _vm.skip - index) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('story-region-form', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.search
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-pencil ")])], 1)];
            }
          }], null, true)
        }), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 20
          },
          on: {
            "input": function (value) {
              return _vm.page = value;
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }