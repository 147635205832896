var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('v-card', _vm._b({
    staticStyle: {
      "flex": "1 1 auto"
    },
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" 선택한 장비 ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.subjects.map(function (_ref) {
    var name = _ref.name;
    return name;
  }).join(" / ") || "장비를 선택해주세요") + " ")])], 1), _c('v-divider'), _c('v-card', _vm._b({
    staticStyle: {
      "flex": "1 1 auto"
    },
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" 적용할 회원 ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.selectedText) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }