<template>
    <v-row>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.economy" title="경제자원" @input="emit" />
        </v-col>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.facility" title="시설자원" @input="emit" />
        </v-col>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.history" title="역사문화자원" @input="emit" />
        </v-col>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.landscape" title="경관자원" @input="emit" />
        </v-col>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.village" title="마을소유자원" @input="emit" />
        </v-col>
        <v-col cols="6" class="px-5">
            <story-view-resources-form v-model="form.around" title="주변연계자원" @input="emit" />
        </v-col>
    </v-row>
</template>

<script>
import { initStoryResources } from "@/assets/variables";
import StoryViewResourcesForm from "./story-view-resources-form.vue";

export default {
    components: {
        StoryViewResourcesForm,
    },
    props: {
        value: { type: Object, default: initStoryResources },
    },
    data: () => ({
        form: initStoryResources,
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initStoryResources(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>