<template>
    <v-row justify="center" align="center">
        <v-col cols="auto" class="headline text-start d-flex align-center"> {{ title }} <slot name="add-button" /> </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
            <slot name="buttons" />
            <v-btn v-if="showsFilterButton" color="secondary" rounded class="ml-3 my-n1" @click="$emit('input', !value)">
                <v-icon small class="mr-2">{{ filterButtonIcon }}</v-icon>
                {{ filterButtonText }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },

        title: { type: String, default: "목록" },
        filterButtonIcon: { type: String, default: "mdi-filter-variant" },
        filterButtonText: { type: String, default: "검색필터" },
        showsFilterButton: { type: Boolean, default: false },
    },
};
</script>

<style></style>
