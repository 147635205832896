<template>
    <list-search showsSearch v-bind="$attrs" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="6" sm="6" class="pa-2">
                <v-text-field v-model="query['year']" type="number" v-bind="inputAttrs" label="년도" @keydown.enter="emit"/>
            </v-col>

            <v-col cols="6" sm="6" class="pa-2">
                <v-select label="월" v-model="query['month']" :items="monthItems" placeholder="전체" v-bind="inputAttrs" @change="emit" />
            </v-col>

            <v-col cols="" sm="4" lg="4" class="pa-2">
                <v-select label="검색명" :items="searchKeys" v-model="query['searchKey']" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="8" sm="8" lg="8" class="pa-2">
                <v-text-field label="검색어" v-model="query['searchValue']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>

        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

const searchKeys = [
    { text: "전체", value: null },
    // { text: "순서", value: "index" },
    { text: "내용", value: "content" },
];

let initQuery = (query = {}) => ({
    ...query,

    searchKey: query?.searchKey || null,
    year: query?.year || null,
    month: query?.month || null,
    searchValue: query?.searchValue || null
});

export default {
    components: {
        ListSearch,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        query: initQuery(),
        searchKeys,
        monthItems: [
            { text: "전체", value: null },
            ...Array.from({ length: 12 }, (v, i) => ({
                text: `${i + 1}월`,
                value: (i + 1).toString(),
            })),
        ],

        inputAttrs,
    }),
    async mounted() {
        await this.init();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            this.sync();
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["year"]) delete query["year"];
            if (!query["month"]) delete query["month"];
            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
