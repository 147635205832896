<template>
    <v-tabs v-model="placeIndex" v-bind="$attrs" @change="emit">
        <template v-for="{ text, value } in items">
            <v-tab :key="value" v-bind="{ value }" exact>{{ text }}</v-tab>
        </template>
        <v-spacer />
        <v-divider vertical />
        <rental-subject-list-places-form :value="setting" @input="init">
            <template #activator="{ attrs, on }">
                <v-btn text fab tile width="50" height="48" v-bind="attrs" v-on="on">
                    <v-icon dense>mdi-cog</v-icon>
                </v-btn>
            </template>
        </rental-subject-list-places-form>
    </v-tabs>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import RentalSubjectListPlacesForm from "./rental-subject-list-places-form.vue";

const baseurl = `/console/rental-subjects/${RENTAL_SUBJECT_TYPES.EQUIPMENTS.value}`;

export default {
    components: {
        RentalSubjectListPlacesForm,
    },
    data: () => ({
        placeIndex: 0,

        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),
        items() {
            return [{ text: "전체", value: null }, ...(this.setting.rental.equipment.places || []).map((text) => ({ text, value: text }))];
        },
        place() {
            return this.items[this.placeIndex]?.value;
        },
    },
    mounted() {
        this.init();
        this.sync();
    },
    watch: {
        items() {
            this.sync();
        },
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await this.getSetting__console();
            } finally {
                this.loading = false;
            }
        },

        sync() {
            this.placeIndex = this.items.findIndex((item) => item.value == (this.$route.query.place ?? null));
        },

        emit() {
            if (this.items.length <= 1) return;

            const { ...query } = this.$route.query;

            delete query.page;
            query.place = this.place;
            if (!query.place) delete query.place;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>