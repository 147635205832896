<template>
    <v-select v-model="query.sort" v-bind="{ ...inputAttrs, ...$attrs, items }" @input="emit" />
</template>

<script>
import { inputAttrs } from "@/assets/variables";

const items = [
    { text: "예약시작시간 ↓", value: JSON.stringify({ startsAt: -1, _id: -1 }) },
    { text: "예약시작시간 ↑", value: JSON.stringify({ startsAt: 1, _id: 1 }) },
];

const initQuery = (query = {}) => ({
    ...query,

    sort: query.sort ?? items[0].value,
});

export default {
    data: () => ({
        query: initQuery(),

        items,
        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>