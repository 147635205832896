<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="참석 목록">
                <template #add-button>
                    <attendance-list-qrscan v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="QR" class="mt-n2 mb-n1" v-bind="attrs" v-on="on">
                                <v-icon>mdi-qrcode-scan</v-icon>
                            </v-btn>
                        </template>
                    </attendance-list-qrscan>
                </template>
            </list-heading>

            <attendance-list-filter v-bind="{ type }" flat outlined />

            <v-row class="ma-n3">
                <v-col cols="auto">
                    <v-card outlined style="overflow: hidden" @click="excel">
                        <v-btn text tile color="green">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            <span>엑셀 다운로드</span>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto" style="max-width: 200px">
                    <rental-request-list-sort solo flat :outlined="false" class="subtitle-2 v-sheet--outlined" />
                </v-col>
            </v-row>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-3" style="overflow: hidden">
                <template #top> <attendance-list-types /> <v-divider /> </template>

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.user.blacksmith.insurance.policies`]="{ value }">
                    <template v-if="value?.length">
                        <image-tiles :images="(value || []).map(({ url }) => url)" tilesOnly cols="12" />
                    </template>
                    <template v-else> - </template>
                </template>

                <template #[`item.user.blacksmith.equipmentUseConsent`]="{ value }">
                    <template v-if="value">
                        <image-popup :src="value?.src" rounded outlined />
                    </template>
                    <template v-else> - </template>
                </template>

                <template #[`item.isAttended`]="{ item, value, header }">
                    <attendance-edit-is-attended :value="item" v-on="{ update }">
                        {{ header.formatter(value) }}
                    </attendance-edit-is-attended>
                </template>

                <template #[`item.attendedAt`]="{ item, value, header }">
                    <attendance-edit-attended-at :value="item" v-on="{ update }">
                        {{ header.formatter(value) }}
                    </attendance-edit-attended-at>
                </template>

                <template #[`item.departedAt`]="{ item, value, header }">
                    <attendance-edit-departed-at :value="item" v-on="{ update }">
                        {{ header.formatter(value) }}
                    </attendance-edit-departed-at>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import { COMMON_ATTENDANCE_TYPES, switchAttrs } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";

import AttendanceListTypes from "@/components/console/common-attendance/attendance-list-types.vue";
import AttendanceListFilter from "@/components/console/common-attendance/attendance-list-filter.vue";
import AttendanceListQrscan from "@/components/console/common-attendance/attendance-list-qrscan.vue";
import RentalRequestListSort from "@/components/console/rental/requests/list/rental-request-list-sort.vue";

import AttendanceEditIsAttended from "@/components/console/common-attendance/attendance-edit-is-attended.vue";
import AttendanceEditAttendedAt from "@/components/console/common-attendance/attendance-edit-attended-at.vue";
import AttendanceEditDepartedAt from "@/components/console/common-attendance/attendance-edit-departed-at.vue";

const headers = [
    { text: "#", value: "index", width: +80 },
    { text: "유형", value: "type", formatter: (value) => COMMON_ATTENDANCE_TYPES[value]?.text, width: +60, align: "center" },
    { text: "신청일시", value: "appliedAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 100 },
    {
        text: "예정날짜",
        value: "date",
        formatter: function () {
            return dayjs(this.date).format("YYYY.MM.DD(dd)");
        },
        width: 120,
    },
    {
        text: "예정시간",
        value: "times",
        formatter: function (value, item, isExcel = false) {
            const contents = [];

            const times = (this.times || []).map(({ startsAt, endsAt } = {}) => {
                const timeItems = [];
                if (startsAt) timeItems.push(dayjs(startsAt).format("HH:mm"));
                timeItems.push("~");
                if (endsAt) timeItems.push(dayjs(endsAt).format("HH:mm"));

                if (isExcel) return `${timeItems.join("")}`;
                else return `${timeItems.join("")}`;
            });
            contents.push(...times);

            return contents.filter((item) => item).join("\r\n");
        },
        width: 110,
    },
    { text: "장소", value: "subject.place", width: +80, cellClass: "text-truncate max-width-0 py-3", withTitle: true },
    { text: "교육명\n/ 설비명", value: "name", cellClass: "text-truncate max-width-0 py-3", withTitle: true },
    { text: "이름", value: "user.name", width: +80 },
    {
        text: "보험증서",
        value: "user.blacksmith.insurance.policies",
        width: +60,
        align: "center",
        class: "pa-0",
        cellClass: "pa-1",
        excel: (items) =>
            (items || [])
                .map((item) => item?.url)
                .filter((url) => url)
                .join("\r\n") || "-",
    },
    { text: "보험만기일", value: "user.blacksmith.insurance.expiresAt", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.join?.("\r\n") || value || "-", width: 100, align: "center" },
    { text: "장비이용\r\n동의서", value: "user.blacksmith.equipmentUseConsent", width: +60, align: "center", class: "pa-0", cellClass: "pa-1" },
    { text: "참석", value: "isAttended", formatter: (value) => ({ [null]: "-", [true]: "참석", [false]: "불참" }[value]), width: +60 },
    { text: "입실", value: "attendedAt", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.join?.("\n") || value || "-", width: 100 },
    { text: "퇴실", value: "departedAt", formatter: (value) => value?.toDateTime?.()?.split?.(" ")?.join?.("\n") || value || "-", width: 100 },
    { text: "아이디", value: "user.username", cellClass: "text-truncate max-width-0 py-3", withTitle: true },
    { text: "연락처", value: "user.phone", width: 120 },
    { text: "이메일", value: "user.email", cellClass: "text-truncate max-width-0 py-3", withTitle: true },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,

        ImagePopup,
        ImageTiles,

        AttendanceListTypes,
        AttendanceListFilter,
        AttendanceListQrscan,
        RentalRequestListSort,

        AttendanceEditIsAttended,
        AttendanceEditAttendedAt,
        AttendanceEditDepartedAt,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        attendances: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        switchAttrs,

        loading: false,
    }),
    computed: {
        items() {
            return this.attendances.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            const { sort } = this.$route.query;
            return sort ?? JSON.stringify({ startsAt: -1, _id: -1 });
        },
        params() {
            let { ...query } = this.$route.query;

            query.type = this.type;
            if (!query.type) delete query.type;

            return { ...query };
        },
    },
    created() {
        if (!this.$route.path.includes("/console/attendances")) this.$router.replace({ path: "/console/attendances" });
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, sort, params } = this;
                var { summary, attendances } = await api.console.common.attendances.gets({
                    headers: { skip, limit, sort },
                    params,
                });

                this.attendances = attendances;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async updateItem(item) {
            const index = this.attendances.findIndex(({ _id }) => _id == item._id);
            if (index == -1) this.search();
            else this.attendances.splice(index, 1, item);
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { attendance } = await api.console.common.attendances.put(item);
                this.updateItem(attendance);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { attendances } = await api.console.common.attendances.gets({
                    headers: { sort: JSON.stringify({ date: -1 }) },
                    params,
                });

                this.$excel(attendances, headers, "참석목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
