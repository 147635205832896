<template>
    <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.attendances`]="{ item, value }">
            <program-attendee-attendances v-bind="{ ...item, units }">
                <template #activator="{ attrs, on }">
                    <v-sheet color="transparent" class="px-4 py-3" v-bind="attrs" v-on="on">
                        <span> {{ value.filter(({ isAttended }) => isAttended).length }} / {{ units.length }} </span>
                        <v-icon small class="ml-2">mdi-clipboard-check</v-icon>
                    </v-sheet>
                </template>
            </program-attendee-attendances>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";

import ProgramAttendeeAttendances from "./program-attendee-attendances.vue";
import { COMMON_ATTENDANCE_TYPES, PROGRAM_APPLICATION_STATES } from "@/assets/variables";

let headers = [
    { text: "아이디", value: "user.username" },
    { text: "이름", value: "user.name" },
    { text: "연락처", value: "user.phone" },
    { text: "이메일", value: "user.email" },
    { text: "참석현황", value: "attendances", cellClass: "pa-0" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ProgramAttendeeAttendances,
    },
    props: {
        program: { type: Object, default: () => ({}) },
    },
    data: () => ({
        applications: [],
        attendances: [],

        headers,
    }),
    computed: {
        items() {
            return this.applications.map((application = {}) => {
                let attendances = this.attendances.filter(({ _user }) => _user == application._user) || [];

                let { affiliation, department, courseName, phone, email } = application.applicant || {};
                let applicantAffiliation = `${affiliation || "-"}\n${department || "-"} / ${courseName || "-"}`;
                let applicantContactInfo = `${phone || "-"}\n${email || "-"}`;

                return {
                    ...application,
                    attendances,
                    applicantAffiliation,
                    applicantContactInfo,
                };
            });
        },
        units() {
            return this.program?.units || [];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._id"() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { _id: _program } = this.program || {};

            if (_program) {
                let { applications } = await api.console.programs.applications.gets({ _program, params: { state: PROGRAM_APPLICATION_STATES.APPROVED.value } });
                this.applications = applications;
                let { attendances } = await api.console.common.attendances.gets({ params: { _program, type: COMMON_ATTENDANCE_TYPES.PROGRAM_UNITS.value } });
                this.attendances = attendances;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .white-space-pre-line {
    white-space: pre-line;
}
</style>