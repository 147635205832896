var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.form.attendedAt,
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.form, "attendedAt", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.form, "attendedAt", $event);
      },
      "open": _vm.sync
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-sheet', {
          staticClass: "mt-5",
          attrs: {
            "width": "240"
          }
        }, [_c('v-datetime-field', _vm._b({
          model: {
            value: _vm.form.attendedAt,
            callback: function ($$v) {
              _vm.$set(_vm.form, "attendedAt", $$v);
            },
            expression: "form.attendedAt"
          }
        }, 'v-datetime-field', _vm.inputAttrs, false))], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }