<template></template>

<script>
import api from "@/api";
import { initRentalSubject, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] },

        date: { type: String },
        subject: { type: Object, default: initRentalSubject },
    },
    mounted() {
        this.search();
    },
    watch: {
        date() {
            this.search();
        },
        subject() {
            this.search();
        },
    },
    methods: {
        async search() {
            const { date } = this;
            const { _id: _subject } = this.subject;
            if (!_subject || !date) return;

            this.$emit("setLoading", true);
            try {
                const { APPLIED, APPROVED } = RENTAL_SCHEDULE_STATES;
                let { schedules } = await api.v1.rental.schedules.gets({
                    params: {
                        _subject,
                        date,
                        rentalType: this.subject.rental.type,
                        state: [APPLIED.value, APPROVED.value],
                    },
                });
                this.$emit("input", schedules);
            } finally {
                this.$emit("setLoading", false);
            }
        },
    },
};
</script>

<style></style>
