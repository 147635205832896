var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "search-input": _vm.searchValue,
      "item-value": "_id",
      "return-object": _vm.retrunObject
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      },
      "click": _vm.alert,
      "keyup": [_vm.onKeyup, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }],
      "change": _vm.onChange
    },
    model: {
      value: _vm.item,
      callback: function ($$v) {
        _vm.item = $$v;
      },
      expression: "item"
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    loading: _vm.loading,
    itemText: _vm.itemText
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }