<template>
    <v-layout justify-center>
        <v-responsive max-width="1000px" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="금산마을 이야기">
                <template #buttons>
                    <story-region-list>
                        <template #activator="{ attrs, on }">
                            <v-btn tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"># 금산마을 지역 설정 </v-btn>
                        </template>
                    </story-region-list>
                </template>
            </list-heading>

            <story-list-search v-bind="{}" flat outlined />

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.index`]="{ index }">
                    {{ summary.totalCount - skip - index }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon @click="edit(item)">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination> </template
            ></v-data-table>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { inputAttrs, STORY_REGION_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import StoryRegionList from "@/components/console/story/story-region-list.vue";
import StoryListSearch from "@/components/console/story/story-list-search.vue";

export default {
    components: {
        ListHeading,
        StoryRegionList,
        StoryListSearch,
    },
    data: () => ({
        loading: false,
        inputAttrs,

        stories: [],
        summary: { totalCount: 0 },
        limit: 10,
    }),
    computed: {
        items() {
            return [...this.stories];
        },
        headers() {
            return [
                { text: "#", value: "index", width: "20" },
                { text: "위치", value: "region", width: "100", formatter: (value) => `금산군 ${STORY_REGION_TYPES?.[value?.parent]?.text} ${value?.name}` },
                { text: "제목", value: "title", width: "150" },
                { text: "작성일자", value: "createdAt", width: "80", formatter: (value) => this.$dayjs(value).format("YYYY-MM-DD HH:mm:ss") },
                { text: "Actions", value: "actions", width: "20" },
            ];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    async mounted() {
        await this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = false;
            try {
                let { skip, limit } = this;

                let { summary, stories } = await api.console.story.gets({
                    headers: { skip, limit },
                    params: this.query,
                });

                this.stories = stories;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (this.loading) return;
            this.loading = false;
            try {
                if (confirm("삭제하시겠습니까?")) {
                    let { images } = item;
                    if (images.length) {
                        images.forEach(async ({ _image }) => {
                            if (_image) await api.console.common.files.delete({ _id: _image });
                        });
                    }

                    await api.console.story.delete(item);
                    await this.search();

                    alert("삭제되었습니다.");
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        create() {
            this.$router.push(`${this.$route.path}/create`);
        },
        edit(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
    watch: {
        query() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
