var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1300px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.isCreate ? '이야기 등록' : '이야기 상세'
    }
  }), _c('story-view-info', {
    model: {
      value: _vm.story,
      callback: function ($$v) {
        _vm.story = $$v;
      },
      expression: "story"
    }
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "대표 모임 및 조직"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('story-view-groups', {
    model: {
      value: _vm.story.groups,
      callback: function ($$v) {
        _vm.$set(_vm.story, "groups", $$v);
      },
      expression: "story.groups"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "대표자원"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('story-view-resources', {
    model: {
      value: _vm.story.resources,
      callback: function ($$v) {
        _vm.$set(_vm.story, "resources", $$v);
      },
      expression: "story.resources"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-2",
    attrs: {
      "cols": "8"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "이미지"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('story-view-images', {
    model: {
      value: _vm.story.images,
      callback: function ($$v) {
        _vm.$set(_vm.story, "images", $$v);
      },
      expression: "story.images"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "캐치프라이즈"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('story-view-catchphrase', {
    model: {
      value: _vm.story.catchphrase,
      callback: function ($$v) {
        _vm.$set(_vm.story, "catchphrase", $$v);
      },
      expression: "story.catchphrase"
    }
  })], 1)], 1)], 1)], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }