<template>
    <v-layout justify-center fill-height>
        <v-responsive width="100%" height="calc(100vh - 48px - 12px - 12px)" class="mx-n3 px-3" style="overflow: hidden">
            <v-layout column fill-height justify-start>
                <list-heading title="설비통계 - 총괄" v-model="showsSearch" showsFilterButton filterButtonIcon="mdi-arrow-expand-vertical" filterButtonText="숨기기" style="flex: 0 0 auto">
                    <template #buttons>
                        <v-btn rounded dark color="green" class="my-n4" @click="excel">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            <span>엑셀 다운로드</span>
                        </v-btn>
                    </template>
                </list-heading>

                <rental-statistics-list-search v-bind="{ showsSearch }" flat outlined dense hideLabel dateToggleMode="yearly" style="flex: 0 0 auto" />

                <v-expand-transition>
                    <div v-show="showsSearch">
                        <v-data-table v-bind="{ headers, loading }" :items="items.slice(0, 4)" disable-sort disable-pagination hide-default-footer :items-per-page="-1" dense class="v-sheet--outlined mb-4" style="overflow-y: scroll" mobile-breakpoint="0">
                            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                        </v-data-table>
                    </div>
                </v-expand-transition>

                <v-data-table v-bind="{ headers, loading }" :items="items.slice(4)" disable-pagination hide-default-footer :items-per-page="-1" fixed-header dense class="v-sheet--outlined" height="100%" style="flex: 0 1 auto; overflow: auto" mobile-breakpoint="0" v-if="4 < items.length">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                </v-data-table>
            </v-layout>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initStatisticsMinutes } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import RentalStatisticsListSearch from "@/components/console/rental/statistics/rental-statistics-list-search.vue";

export default {
    components: {
        ListHeading,
        RentalStatisticsListSearch,
    },
    data: () => ({
        items: [],

        loading: false,
        showsSearch: true,
    }),
    computed: {
        headers() {
            const headers = [
                {
                    width: 160,
                    text: "구분",
                    value: "term",
                    cellClass: "max-width-0 text-truncate",
                    withTitle: true,
                },
            ];

            if (this.params.searchDateValue) {
                headers.push({
                    width: 100,
                    text: "계",
                    value: "meta",
                    formatter: (value, item) => {
                        let text = "";
                        if (item.type == "number") text = value?.format?.() || value;
                        if (item.type == "minute") text = initStatisticsMinutes(value);
                        if (item.type == "percent") text = value?.toFixed?.(2) || value;
                        if (text) text += item?.suffix || "";
                        return `${text || "-"}`;
                    },
                    align: "end",
                });

                // 기간필터 있을 시 월단위 노출
                const items = [];

                do {
                    const lastItem = [...items].pop() ?? {
                        $gte: this.$dayjs(this.params.searchDateValue[0]).subtract(1, "month").startOf("month").toDate(),
                        $lte: this.$dayjs(this.params.searchDateValue[0]).subtract(1, "month").endOf("month").toDate(),
                    };
                    items.push({
                        $gte: this.$dayjs(lastItem.$gte).add(1, "month").startOf("month").toDate(),
                        $lte: this.$dayjs(lastItem.$lte).add(1, "month").endOf("month").toDate(),
                    });
                } while (this.$dayjs([...items].pop().$lte).isBefore(this.params.searchDateValue[1]));

                headers.push(
                    ...items.map(({ $gte }) => ({
                        width: +90,
                        text: this.$dayjs($gte).format("YYYY.MM"),
                        value: `desc.${this.$dayjs($gte).format("YYYY_MM")}`,
                        formatter: (value, item) => {
                            let text = "";
                            if (this.$dayjs().isBefore($gte)) text = "-";
                            else {
                                if (item.type == "number") text = value?.format?.() || value;
                                if (item.type == "minute") text = initStatisticsMinutes(value);
                                if (item.type == "percent") text = value?.toFixed?.(2) || value;
                                if (text) text += item?.suffix || "";
                            }
                            return `${text || "-"}`;
                        },
                        align: "end",
                    }))
                );
            }
            // 기간필터 없을 시 합계만 노출
            else {
                headers.push({ text: "합계", value: "desc", formatter: (value, item) => `${(item?.type == "number" ? value?.format?.() || value : value) || "-"}${value ? item?.suffix || "" : ""}` });
            }

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));
        },

        params() {
            let { ...query } = this.$route.query;

            if (!query.attendanceType) delete query.attendanceType;

            if (!query._subjects?.length) delete query._subjects;

            if (!query.searchDateValue || !query.searchDateValue?.[0] || !query.searchDateValue?.[1]) {
                delete query.searchDateValue;
            }

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            this.items = [];

            try {
                const { params } = this;
                const headers = { mode: this.params.searchDateValue ? "monthly" : undefined };

                const { items } = await api.console.rental.statistics.getSummary({ headers, params });

                if (this.params.searchDateValue) {
                    this.items = items.map((item, index, array) => {
                        let meta = Object.keys(item.desc).reduce((sum, key) => {
                            let value = item.desc[key];
                            if (3 < index) value *= array[3].desc[key] || 0;
                            return sum + value;
                        }, 0);

                        if (3 < index) meta /= Object.values(array[3].desc).reduce((sum, value) => sum + value || 0, 0);

                        return { ...item, meta };
                    });
                } else this.items = items;
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { params } = this;
                const headers = { mode: this.params.searchDateValue ? "monthly" : undefined };

                const { items } = await api.console.rental.statistics.getSummary({ headers, params });

                let rows = [];
                if (this.params.searchDateValue) {
                    rows = items.map((item, index, array) => {
                        let meta = Object.keys(item.desc).reduce((sum, key) => {
                            let value = item.desc[key];
                            if (3 < index) value *= array[3].desc[key] || 0;
                            return sum + value;
                        }, 0);

                        if (3 < index) meta /= Object.values(array[3].desc).reduce((sum, value) => sum + value || 0, 0);

                        return { ...item, meta };
                    });
                } else rows = items;

                this.$excel(rows, this.headers, "설비통계_총괄");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th,
    td {
        min-width: 100px;
        user-select: auto !important;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    tr > th:first-of-type,
    tr > td:first-of-type {
        position: sticky;
        left: 0;
        background-color: white;
    }
    tr > th:first-of-type {
        top: 0;
        z-index: 4 !important;
    }
    tr > td:first-of-type {
        z-index: 3 !important;
    }
    // tr > th:first-of-type,
    // tr > td:first-of-type {
    //     position: sticky;
    //     left: 0;
    //     background-color: white;
    // }
    // tr > th:nth-of-type(2),
    // tr > td:nth-of-type(2) {
    //     position: sticky;
    //     left: 160px;
    //     background-color: white;
    // }
    // tr > th:first-of-type,
    // tr > th:nth-of-type(2) {
    //     top: 0;
    //     z-index: 100 !important;
    // }
    // tr > td:first-of-type,
    // tr > td:nth-of-type(2) {
    //     z-index: 3 !important;
    // }
}
</style>
