<template>
    <view-section-card title="선택한 스케줄" class="fill-height">
        <v-data-table v-bind="{ items, headers, itemClass }" disable-filtering disable-pagination disable-sort hide-default-footer height="494" fixed-header>
            <template #no-data> 선택한 스케줄이 없습니다 </template>
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            <template #[`item.time`]="{ item }">
                <template v-if="item.request.subject.rental.type == RENTAL_TYPES.BY_DATE.value"> 종일 </template>
                <template v-if="item.request.subject.rental.type == RENTAL_TYPES.BY_HOUR.value"> {{ `${item.hour}:00`.padStart(5, 0) }} </template>
                <template v-if="item.request.subject.rental.type == RENTAL_TYPES.BY_TIME.value"> {{ `${item.timeStart} ~ ${item.timeEnd}` }} </template>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn dense text icon color="red" class="pa-0" @click="remove(item)"> <v-icon>mdi-minus</v-icon> </v-btn>
            </template>
        </v-data-table>
    </view-section-card>
</template>

<script>
import { RENTAL_TYPES } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

let headers = [
    { text: "사용자", value: "request.user", formatter: ({ username, name }) => `${username} / ${name}` },
    { text: "설비", value: "request.subject", formatter: (value) => `${value.name}` },
    { text: "예약일", value: "date", formatter: (value) => value?.toDate?.() || value || "-" },
    { text: "예약시", value: "time" },
    { text: "", value: "actions", width: "auto", align: "right" },
];

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        schedules: [],

        headers,
        RENTAL_TYPES,
    }),
    computed: {
        items() {
            return [...this.schedules];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.schedules = [...this.value];
        },
        emit() {
            this.$emit("input", this.schedules);
        },
        itemClass(item) {
            if (item?.success == true) return "success lighten-5";
            if (item?.success == false) return "error lighten-4";
        },
        remove(item) {
            this.schedules = this.schedules.filter((schedule) => schedule != item);
            this.emit();
        },
    },
};
</script>
