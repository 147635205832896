<template>
    <v-dialog v-model="shows" width="680px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                <template>카테고리 등록</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="8" class="pa-2">
                        <v-text-field v-model="form.title" label="카테고리명" />
                    </v-col>
                    <v-col cols="4" class="pa-2">
                        <v-text-field v-model="form.code" label="코드" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="() => (shows = false)">취소</v-btn>
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ListHeading,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: { title: null, code: null },
    }),
    computed: {
        isCreate() {
            return !this.form._id;
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                if (!this.form.title) throw new Error("카테고리명을 작성해주세요.");
                if (!this.form.code) throw new Error("코드를 작성해주세요");

                let { put, post } = api.console.boards.categories;

                await (this.isCreate ? post : put)(this.form);

                alert("저장되었습니다.");

                this.$emit("input");
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = { ...this.value };
        },
    },
    watch: {
        shows() {
            this.sync();
        },
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>