<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="관리자 목록">
                <template #add-button>
                    <manager-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" v-bind="attrs" v-on="on">
                                <v-icon> mdi-plus </v-icon>
                            </v-btn>
                        </template>
                    </manager-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.subPassword`]="{ value }">
                    <v-row align="center" no-gutters>
                        <v-col class="pr-2 text-subtitle-2">
                            <v-hover v-slot="{ hover }">
                                <code class="d-inline-flex justify-center"> {{ hover ? value : value.replaceAll(/./g, "*") }} </code>
                            </v-hover>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-small outlined icon color="grey" @click="copy(value)">
                                <v-icon x-small color="black">mdi-content-copy</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.scope`]="{ value, header }">
                    <v-chip-group column>
                        <template v-for="text in header.formatter(value)">
                            <v-chip small outlined label :key="text">
                                <span> {{ text }} </span>
                            </v-chip>
                        </template>
                    </v-chip-group>
                </template>

                <template #[`item.actions`]="{ item }">
                    <manager-form :value="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </manager-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders, MANAGER_SCOPES, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ManagerForm from "@/components/console/user/manager-form.vue";

const headers = initDataTableHeaders([
    {
        text: "#",
        value: "index",
        width: +60,
        align: "center",
    },
    {
        text: "관리자명",
        value: "name",
        width: 120,
    },
    {
        text: "아이디",
        value: "username",
        width: 140,
    },
    {
        text: "비밀번호",
        value: "subPassword",
        width: 240,
    },
    {
        text: "권한",
        value: "scope",
        formatter: (value = []) => value.map((key) => MANAGER_SCOPES[key]?.text).filter((item) => item),
    },
    {
        text: "생성일시",
        value: "createdAt",
        formatter: (value) => value?.toDateTime?.() || "-",
        width: 100,
    },
    { text: "", value: "actions", width: +88 },
]);

export default {
    components: {
        ListHeading,
        ManagerForm,
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({
                ...item,
                index: this.summary.totalCount - (this.page - 1) * this.limit - index,
            }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.type = USER_TYPES.MANAGER;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.users = [];
            this.summary = { totalCount: 0 };
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, users } = await api.console.users.gets({
                    headers: { skip, limit },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            const { user } = await api.console.users.put(item);
            this.updateItem(user);
        },

        async remove(value) {
            const go = confirm(`관리자 (ID: ${value?.username})를 삭제하시겠습니까?`);
            if (go) {
                try {
                    await api.console.users.delete(user);

                    alert(`관리자 (ID: ${value?.username})가 삭제되었습니다.`);
                    await this.search();
                } catch (error) {
                    console.error(error);
                    alert(error.response ? error.response.data.email : error.email);
                }
            }
        },

        async updateItem(item) {
            const index = this.users.findIndex(({ _id }) => _id == item._id);
            if (index == -1) this.search();
            else this.users.splice(index, 1, item);
        },

        copy(value) {
            navigator.clipboard.writeText(value);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
