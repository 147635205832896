<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="예약신청현황" v-model="showsSearch" showsFilterButton>
                <template #add-button>
                    <rental-request-form v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="예약신청 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </rental-request-form>
                </template>
            </list-heading>

            <rental-request-list-search v-bind="{ showsSearch }" flat outlined />

            <v-row class="ma-n3">
                <v-col cols="auto">
                    <v-card outlined style="overflow: hidden" @click="excel">
                        <v-btn text tile color="green">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            <span>엑셀 다운로드</span>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto" style="max-width: 200px">
                    <rental-request-list-sort solo flat :outlined="false" class="subtitle-2 v-sheet--outlined" />
                </v-col>
            </v-row>

            <v-card outlined class="mt-3" style="overflow: hidden">
                <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template #top> <rental-request-list-subject-types /> <v-divider /> </template>

                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value) : undefined" v-html="header.formatter.bind(item)(value)" /> </template>

                    <template #[`item.desc`]="{ item, value, header }">
                        <rental-request-item-desc v-bind="{ item }">
                            <template #activator="{ attrs, on }">
                                <v-card flat tile width="200" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                            </template>
                        </rental-request-item-desc>
                    </template>

                    <template #[`item.desc.rejection`]="{ item, value }">
                        <rental-request-edit-desc-rejection :value="item" v-on="{ update }">
                            <v-card flat tile width="160" color="transparent" class="px-4 py-3 caption text-truncate" :title="value"> {{ value || "-" }} </v-card>
                        </rental-request-edit-desc-rejection>
                    </template>

                    <template #[`item.state`]="{ item, value, header }">
                        <v-edit-dialog :return-value.sync="item.state" large cancel-text="취소" save-text="변경" @open="state = value" @save="putStateOf(item)">
                            <v-card flat tile width="90" color="transparent" class="px-4 py-3 caption text-truncate"> {{ header.formatter(value) }} </v-card>
                            <template #input> <v-select v-model="state" :items="Object.values(RENTAL_SCHEDULE_STATES)" v-bind="inputAttrs" class="mt-3" /> </template>
                        </v-edit-dialog>
                    </template>

                    <template #footer>
                        <v-divider />
                        <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                    </template>
                </v-data-table>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { inputAttrs, RENTAL_SCHEDULE_STATES, RENTAL_SUBJECT_TYPES, RENTAL_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import RentalRequestForm from "@/components/console/rental/requests/form/rental-request-form.vue";

import RentalRequestItemDesc from "@/components/console/rental/requests/item/rental-request-item-desc.vue";

import RentalRequestListSort from "@/components/console/rental/requests/list/rental-request-list-sort.vue";
import RentalRequestListSearch from "@/components/console/rental/requests/list/rental-request-list-search.vue";
import RentalRequestListSubjectTypes from "@/components/console/rental/requests/list/rental-request-list-subject-types.vue";
import RentalRequestEditDescRejection from "@/components/console/rental/requests/edit/rental-request-edit-desc-rejection.vue";

const headers = [
    { text: "#", value: "index", width: +80, align: "center" },
    { text: "유형", value: "subject.type", formatter: (value) => RENTAL_SUBJECT_TYPES[value]?.text, width: +60, align: "center" },
    { text: "예약일", value: "date", formatter: (value) => value?.toDate?.(), width: 100 },
    {
        text: "예약시간",
        value: "times",
        formatter: function (value) {
            switch (this.rentalType) {
                case RENTAL_TYPES.BY_DATE.value: {
                    return "종일";
                }
                case RENTAL_TYPES.BY_HOUR.value: {
                    return [...(value ?? [])]
                        ?.sort?.((a, b) => a - b)
                        ?.map?.((hour) => dayjs().hour(hour).minute(0).format("HH:mm"))
                        ?.join?.("\r\n");
                }
                case RENTAL_TYPES.BY_TIME.value: {
                    return value?.join?.("\r\n");
                }
            }
        },
        width: 120,
    },
    { text: "설비명", value: "subject.name", cellClass: "max-width-0 text-truncate", withTitle: true },
    { text: "이름", value: "user.name", width: +80 },
    { text: "아이디", value: "user.username", cellClass: "max-width-0 text-truncate", withTitle: true },
    { text: "연락처", value: "user.phone", width: 120 },
    { text: "이메일", value: "user.email", hides: true },
    {
        text: "신청내용",
        value: "desc",
        cellClass: "pa-0 text-align-start",
        formatter: (value) =>
            Object.keys(value || {})
                .map((key) => ({ term: { objective: "사용목적", procedure: "작업계획", substance: "사용재료", headcount: "사용인원" }[key], desc: value[key] }))
                .filter((item) => item.desc)
                .map(({ term, desc }) => `${term}: ${desc}`)
                .join("\r\n") || "-",
        width: 200,
    },
    { text: "거절사유", value: "desc.rejection", cellClass: "pa-0", width: 160 },
    { text: "신청일", value: "createdAt", formatter: (value) => value?.toDateTime?.(), width: 160 },
    { text: "상태", value: "state", formatter: (value) => RENTAL_SCHEDULE_STATES[value]?.text || value || "", cellClass: "pa-0", width: +90 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

export default {
    components: {
        ListHeading,

        RentalRequestForm,
        RentalRequestItemDesc,

        RentalRequestListSort,
        RentalRequestListSearch,
        RentalRequestListSubjectTypes,
        RentalRequestEditDescRejection,
    },
    props: {
        subjectType: { type: String, default: null },
    },
    data: () => ({
        requests: [],
        state: RENTAL_SCHEDULE_STATES.APPLIED.value,

        limit: 10,
        summary: { totalCount: 0 },

        inputAttrs,
        RENTAL_SCHEDULE_STATES,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return this.requests.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        headers() {
            return headers.filter(({ hides }) => !hides);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        sort() {
            const { sort } = this.$route.query;
            return sort ?? JSON.stringify({ startsAt: -1, _id: -1 });
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            delete query.sort;

            query.subjectType = this.subjectType;
            if (!query.subjectType) delete query.subjectType;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, sort, params } = this;
                var { summary, requests } = await api.console.rental.requests.gets({
                    headers: { skip, limit, sort },
                    params,
                });

                this.requests = requests;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { request } = await api.console.rental.requests.put(item);
                this.updateItem(request);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async putStateOf(request) {
            let go = confirm("해당 예약의 상태를 변경하시겠습니까?");
            if (go) {
                let { state } = this;
                let { _id, schedules = [] } = request || {};
                schedules = await Promise.all(
                    schedules.map(async (schedule = {}) => {
                        let { _id } = schedule;
                        try {
                            return { ...((await api.console.rental.schedules.put({ _id, state }))?.schedule || schedule), success: true };
                        } catch (error) {
                            console.error(error);
                            return { ...schedule, success: false };
                        }
                    })
                );
                let failedItemsLength = schedules.filter(({ success }) => !success)?.length;
                if (failedItemsLength) {
                    alert(`총 ${schedules.length} 건 예약시간 중 ${failedItemsLength} 건의 상태 변경에 실패하였습니다.`);
                } else {
                    alert("모든 예약시간 상태 변경이 성공하였습니다.");
                }

                request = (await api.console.rental.requests.put({ _id, state }))?.request;
                this.updateItem(request);
            }
        },

        async updateItem(item) {
            const index = this.requests.findIndex(({ _id }) => _id == item._id);
            this.requests.splice(index, 1, item);
            if (index == -1) this.search();
            else this.requests.splice(index, 1, item);
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { requests } = await api.console.rental.requests.gets({
                    headers: {},
                    params,
                });

                this.$excel(requests, headers, "예약신청현황");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
