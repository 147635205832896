var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1000px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("설정")]), _c('v-spacer'), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto pt-0"
    }
  }, [_vm._v("SMTP 설정 (이메일 전송 설정) 변경")]), _c('v-col', {
    staticClass: "primary--text caption pt-0 pl-0"
  }, [_vm._v(" \"Naver 메일 -> 환경설정 -> POP3/IMAP 설정 -> IMAP/SMTP 설정 -> IMAP/SMTP 사용\" 을 사용함으로 변경 ")])], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "발신자 아이디 (네이버)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.smtp.username,
      callback: function ($$v) {
        _vm.$set(_vm.smtp, "username", $$v);
      },
      expression: "smtp.username"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.smtp.password,
      callback: function ($$v) {
        _vm.$set(_vm.smtp, "password", $$v);
      },
      expression: "smtp.password"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto pt-0"
    }
  }, [_vm._v("관리자 이메일 추가 등록")]), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.addEmail
    }
  }, [_vm._v("추가하기")])], 1)], 1), _vm._l(_vm.emailList, function (email, idx) {
    return _c('v-card-text', {
      key: idx
    }, [_c('div', [_c('v-text-field', {
      attrs: {
        "label": "수신자 아이디",
        "persistent-placeholder": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: _vm.emailList[idx],
        callback: function ($$v) {
          _vm.$set(_vm.emailList, idx, $$v);
        },
        expression: "emailList[idx]"
      }
    })], 1)]);
  })], 2), _c('v-btn', {
    attrs: {
      "fixed": "",
      "bottom": "",
      "right": "",
      "fab": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }