<template>
    <v-dialog v-model="shows" max-width="900" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                <template v-if="isCreate">교육 생성</template>
                <template v-else>교육 상세</template>
                <program-form-clone v-model="form" v-if="isCreate">
                    <template #activator="{ attrs, on }">
                        <v-btn outlined small class="mx-2" v-bind="attrs" v-on="on">
                            <v-icon small class="mr-1">mdi-folder-open-outline</v-icon>
                            불러오기
                        </v-btn>
                    </template>
                </program-form-clone>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y: auto">
                <v-row no-gutters>
                    <v-col cols="12" md="3" lg="4">
                        <v-card-subtitle class="grey lighten-5">썸네일</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <image-input v-model="form.thumb" flat tile :aspect-ratio="1 / 1" @input="emit" />
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-divider v-show="!isMobile" vertical />
                        <v-divider v-show="isMobile" />
                    </v-col>
                    <v-col>
                        <v-card-subtitle class="grey lighten-5">기본 정보</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters class="ma-n2">
                                <v-col class="pa-2" cols="12" sm="6">
                                    <autocomplete-program-category label="분류" v-model="form._category" v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <select-user-type label="대상회원유형" v-model="form.userTypes" multiple v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-text-field label="교육명" v-model="form.name" v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-currency-field label="모집인원" suffix="명" v-model="form.capacity" v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="접수기간(시작)" v-model="form.application.startsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="접수기간(종료)" v-model="form.application.endsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="진행기간(시작)" v-model="form.startsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="진행기간(종료)" v-model="form.endsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="노출기간(시작)" v-model="form.display.startsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                                <v-col class="pa-2" cols="12" sm="6">
                                    <v-datetime-field label="노출기간(종료)" v-model="form.display.endsAt" clearable v-bind="inputAttrs" @input="emit" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                        <form-table-row label="회차" cols="2" contentClass="px-0">
                            <program-form-units v-model="form" />
                        </form-table-row>
                        <form-table-row label="사용장비" cols="2" contentClass="px-0">
                            <autocomplete-rental-subject v-model="form._subjects" multiple v-bind="attrs_input__verticalTable" @input="emit">
                                <template #selection="{ parent, item, attrs, itemText }">
                                    <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                                </template>
                            </autocomplete-rental-subject>
                        </form-table-row>
                        <v-card-subtitle class="grey lighten-5">상세 내용</v-card-subtitle>
                        <v-divider />
                        <naver-smarteditor v-model="form.detail" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 grey lighten-5">
                <v-col cols="auto" class="pa-0" style="min-width: 140px">
                    <v-switch v-model="form.shows" v-bind="switchAttrs" @input="emit">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute>
                    <v-progress-circular indeterminate size="100" width="5" />
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { attrs_input__verticalTable, initProgram, initProgramUnit, inputAttrs, switchAttrs } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";
import FormTableRow from "@/components/console/dumb/form-table-row.vue";
import SelectUserType from "@/components/console/dumb/select-user-type.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ProgramFormUnits from "./program-form-units.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ProgramFormClone from "./program-form-clone.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";
import AutocompleteProgramCategory from "@/components/dumb/autocomplete-program-category.vue";

export default {
    components: {
        ImageInput,
        FormTableRow,
        SelectUserType,
        VDatetimeField,
        ProgramFormUnits,
        NaverSmarteditor,
        ProgramFormClone,
        AutocompleteRentalSubject,
        AutocompleteProgramCategory,
    },
    props: {
        value: { type: Object, default: initProgram },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initProgram({ units: [initProgramUnit()] }),
        keywords: [],
        categories: [],

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,
        isMobile: detect == "mobile",
    }),
    computed: {
        _program() {
            return this.value?._id;
        },
        isCreate() {
            return !this._program;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initProgram({ units: [initProgramUnit()] });
                } else {
                    let { program } = await api.console.programs.get({ _id: this._program });

                    this.form = initProgram(program);
                }
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.form = initProgram(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { _units = [], units = [], _thumb, thumb, ...form } = this.form;

                // POST/PUT program
                let { post, put } = api.console.programs;
                let { program } = await (this.isCreate ? post(form) : put(form));
                let _program = program?._id;

                // POST/PUT/DELETE units
                units = await Promise.all(units.map(async (unit) => (await api.console.programs.units[unit._id ? "put" : "post"](initProgramUnit({ ...unit, _program })))?.unit));
                await Promise.all(_units.filter((_unit) => !units.some(({ _id }) => _id == _unit)).map(async (_id) => await api.console.programs.units.delete({ _id, _program })));
                _units = units.map(({ _id }) => _id);

                // DELETE/POST thumb
                if (thumb instanceof File) thumb = (await api.console.programs.thumb.post({ _program }, thumb))?.thumb;
                _thumb = thumb?._id || null;

                // PUT program
                program = (await put({ _id: _program, _thumb, _units }))?.program;

                alert("저장되었습니다");
                this.$emit("input", program);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
