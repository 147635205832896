<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout v-if="setting" justify-center>
            <v-responsive max-width="1024px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">장비 및 공간 타이틀</v-col>
                    <v-spacer></v-spacer>
                    <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-card outlined class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto py-0">메이커스 장비예약</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-model="setting.rental.equipment.title" persistent-placeholder dense hide-details />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card outlined class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto py-0">시설 및 공간 예약</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-model="setting.rental.facility.title" persistent-placeholder dense hide-details />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card outlined class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <v-row align="center">
                            <v-col cols="auto py-0">전문제조장비</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-model="setting.rental.intro.title" persistent-placeholder dense hide-details />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    created() {
        this.init();
    },
    data() {
        return {
            setting: null
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            this.setting = setting;
        },

        async save() {
            await api.console.setting.put(this.setting);
            alert("저장되었습니다.")
        },
    },
};
</script>
