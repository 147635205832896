var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-calendar', _vm._b({
    on: {
      "mousedown:event": _vm.startDrag,
      "mousedown:time": _vm.addItem,
      "mousemove:time": _vm.mouseMove,
      "mouseup:time": _vm.endDrag
    },
    nativeOn: {
      "mouseleave": function ($event) {
        return _vm.cancelDrag.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function (_ref) {
        var event = _ref.event;
        return [_c('div', {
          staticClass: "px-1 primary--text"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" " + _vm._s(_vm.$dayjs(event.start).format("HH:mm")) + " ~ " + _vm._s(_vm.$dayjs(event.end).format("HH:mm")) + " ")]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.removeItem(event);
            }
          }
        }, [_vm._v("mdi-close")])], 1)], 1), _c('v-fade-transition', [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.$dayjs(event.end).diff(event.start, 'minutes') >= 90,
            expression: "$dayjs(event.end).diff(event.start, 'minutes') >= 90"
          }],
          staticStyle: {
            "position": "absolute",
            "bottom": "6px",
            "right": "4px"
          }
        }, [_vm._v("총 " + _vm._s(_vm.$dayjs(event.end).diff(event.start, "minutes")) + "분")])])], 1), _c('div', {
          staticClass: "v-event-drag-bottom",
          on: {
            "mousedown": function ($event) {
              $event.stopPropagation();
              return _vm.extendBottom(event);
            },
            "mouseup": function ($event) {
              $event.stopPropagation();
              return _vm.emit.apply(null, arguments);
            }
          }
        })];
      }
    }])
  }, 'v-calendar', _vm.props_calendar, false));

}
var staticRenderFns = []

export { render, staticRenderFns }