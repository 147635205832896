var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "pa-0 mb-0",
    attrs: {
      "cols": "12",
      "sm": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-6 ml-6",
    attrs: {
      "dense": "",
      "hide-details": "",
      "label": "검색",
      "persistent-placeholder": "",
      "append-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.variables ? _c('v-data-table', {
    staticClass: "mt-5 ml-6",
    attrs: {
      "headers": _vm.variablesHeaders,
      "items": _vm.variables,
      "items-per-page": "6",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.name))]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "x-small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.copy($event);
            }
          }
        }, [_vm._v("mdi-content-copy")])];
      }
    }], null, true)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }