<template>
    <v-dialog v-model="shows" max-width="1080" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">예약차단 생성</template>
                <template v-else>예약차단 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" lg="4" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" class="pa-2">
                                <view-section-card title="기본정보">
                                    <v-card-text>
                                        <v-row class="ma-n2">
                                            <v-col cols="12" class="pa-2">
                                                <v-datetime-field v-model="form.startsAt" label="예약차단 시작일시" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-datetime-field v-model="form.endsAt" label="예약차단 종료일시" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </view-section-card>
                            </v-col>
                            <v-col cols="12" class="pa-2">
                                <view-section-card title="관리자메모">
                                    <v-textarea v-model="form.memo" v-bind="attrs_input__verticalTable" @input="emit" />
                                </view-section-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="8" class="pa-2">
                        <view-section-card title="대상정보">
                            <v-card-text style="max-height: 351px; overflow-y: auto">
                                <v-row class="ma-n2">
                                    <v-col cols="12" class="pa-2">
                                        <v-select v-model="form.target.type" label="대상유형" :items="targetTypes" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                    <v-expand-transition>
                                        <v-col cols="12" class="pa-0" v-show="form.target.type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_SELF.value">
                                            <div class="pa-2">
                                                <autocomplete-rental-subject v-model="form.target._subjects" label="대상설비" multiple :dense="false" v-bind="inputAttrs" @input="emit">
                                                    <template #selection="{ parent, item, attrs, itemText }">
                                                        <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                                                    </template>
                                                </autocomplete-rental-subject>
                                            </div>
                                        </v-col>
                                    </v-expand-transition>
                                    <v-expand-transition>
                                        <v-col cols="12" class="pa-0" v-show="form.target.type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_TYPE.value">
                                            <div class="pa-2">
                                                <v-select v-model="form.target.subjectTypes" label="대상설비유형" :items="subjectTypes" multiple v-bind="inputAttrs" @input="emit" />
                                            </div>
                                        </v-col>
                                    </v-expand-transition>
                                </v-row>
                            </v-card-text>
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isActive" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.isActive">사용</template>
                                <template v-else>미사용</template>
                            </span>
                        </template>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, RENTAL_LIMITATION_TARGET_TYPES, initRentalBlockage, RENTAL_SUBJECT_TYPES, attrs_input__verticalTable } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

const targetTypes = Object.values(RENTAL_LIMITATION_TARGET_TYPES);
const subjectTypes = Object.values(RENTAL_SUBJECT_TYPES);

export default {
    components: {
        VDatetimeField,
        ViewSectionCard,
        AutocompleteRentalSubject,
    },
    props: {
        value: { type: Object, default: initRentalBlockage },
    },
    data: () => ({
        form: initRentalBlockage(),

        targetTypes,
        subjectTypes,

        RENTAL_LIMITATION_TARGET_TYPES,

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,

        shows: false,
        loading: false,
    }),
    computed: {
        _blockage() {
            return this.value?._id;
        },
        isCreate() {
            return !this._blockage;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initRentalBlockage();
                } else {
                    let { blockage } = await api.console.rental.blockages.get({ _id: this._blockage });

                    this.form = initRentalBlockage(blockage);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initRentalBlockage(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { ...form } = this.form;

                // POST/PUT blockage
                let { post, put } = api.console.rental.blockages;
                let { blockage } = await (this.isCreate ? post(form) : put(form));

                alert("저장되었습니다");
                this.$emit("input", blockage);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
