<template>
    <v-row no-gutters>
        <v-col cols="3" class="pl-6">
            <image-input v-model="form.image" accept="image/*" width="140" height="140" outlined style="overflow: hidden" @input="emit"/>
        </v-col>
        <v-row no-gutters>
            <v-col align-self="center" class="pa-0">
                <v-col>
                    <v-file-input v-model="form.image" show-size accept="image/*" v-bind="{ ...inputAttrs }" label="파일명" @change="emit"/>
                </v-col>
                <v-col>
                    <v-text-field v-model="form.desc" v-bind="{ ...inputAttrs }" label="제목" @input="emit"/>
                </v-col>
            </v-col>
        </v-row>
    </v-row>
</template>

<script>
import { initStoryImages, inputAttrs } from "@/assets/variables";
import ImageInput from "../dumb/image-input.vue";

export default {
    components: {
        ImageInput,
    },
    props: {
        value: { type: Object, default: initStoryImages },
    },
    data: () => ({
        inputAttrs,
        fileInput: null,

        form: initStoryImages(),
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initStoryImages(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style>
</style>