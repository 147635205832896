var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "large": "",
      "block": "",
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', [_vm._v(_vm._s(_vm.$dayjs().hour(_vm.hour).minute(0).format("HH:mm")))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-simple-checkbox', {
    attrs: {
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }