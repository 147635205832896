var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 링크등록 ")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_vm.manage.code === 'main' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "선택해주세요",
      "items": ['메이커스 장비 예약', '시설 및 공간 예약', '입주신청', '전자제조 지원신청'],
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manage.subject,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "subject", $$v);
      },
      expression: "manage.subject"
    }
  })], 1) : _vm._e(), _vm.manage.code === 'program' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "선택해주세요",
      "items": ['장비운영교육', '기술제조기반 교육 지원', '전자제조 초도양산', '3D모델링지원', '투자 및 마케팅 지원'],
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manage.subject,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "subject", $$v);
      },
      expression: "manage.subject"
    }
  })], 1) : _vm._e(), _vm.manage.code === 'info' ? _c('v-col', [_c('v-select', {
    attrs: {
      "placeholder": "선택해주세요",
      "items": ['입주신청링크'],
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.manage.subject,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "subject", $$v);
      },
      expression: "manage.subject"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("링크")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "https://kea-blacksmith.uxi.kr/rental/equipments"
    },
    model: {
      value: _vm.manage.link,
      callback: function ($$v) {
        _vm.$set(_vm.manage, "link", $$v);
      },
      expression: "manage.link"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }