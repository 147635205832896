<template>
    <v-snackbar v-model="shows" v-bind="$attrs">
        <v-card-title style="white-space: pre-line"> {{ message }} </v-card-title>
        <template #action="{ attrs }">
            <v-btn large text icon color="pink" v-bind="attrs" @click="shows = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        message: { type: String, default: null },
    },
    data: () => ({
        shows: false,
    }),
    watch: {
        message() {
            if (this.message) this.shows = true;
            else this.shows = false;
        },
    },
};
</script>

<style></style>
