var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900px"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.item ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-0 pl-3",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.inputs.name) + "(" + _vm._s(_vm.inputs.code) + ")")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pa-0 pr-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "body-2"
  }, [_vm._v("사용안함/사용")])]), _c('v-col', {
    staticClass: "pa-0 pr-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 mt-2 pa-0 d-inline-block",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.inputs.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "enabled", $$v);
      },
      expression: "inputs.enabled"
    }
  })], 1)], 1)], 1), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputs.enabled,
      expression: "inputs.enabled"
    }]
  }), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('v-select', {
    staticClass: "mt-6",
    attrs: {
      "label": "문자/알림톡",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "items": ['문자', '알림톡'],
      "disabled": !_vm.inputs.enabled
    },
    model: {
      value: _vm.inputs.type,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "type", $$v);
      },
      expression: "inputs.type"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "템플릿코드",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.inputs.enabled
    },
    model: {
      value: _vm.inputs.templateCode,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "templateCode", $$v);
      },
      expression: "inputs.templateCode"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "템플릿",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.inputs.enabled
    },
    model: {
      value: _vm.inputs.template,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "template", $$v);
      },
      expression: "inputs.template"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "수신자이름",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.inputs.enabled
    },
    model: {
      value: _vm.inputs.receiverName,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "receiverName", $$v);
      },
      expression: "inputs.receiverName"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "수신자번호",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "disabled": !_vm.inputs.enabled
    },
    model: {
      value: _vm.inputs.receiverPhone,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "receiverPhone", $$v);
      },
      expression: "inputs.receiverPhone"
    }
  })], 1), _c('messsages-template-variables')], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.inputs = Object.assign({}, _vm.item);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-refresh")]), _vm._v("초기화")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "font-weight-black",
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    staticClass: "font-weight-black",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }