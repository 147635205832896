<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="month" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field :value="formatData" append-icon="event" readonly v-bind="$attrs" v-on="on" class="pt-0" ></v-text-field>
        </template>
        <v-date-picker v-model="month" type="month" no-title scrollable @input="$refs.menu.save(month)"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },
    },
    data(){
        return {
            month: this.$props.value
        };
    },
    watch: {
        value(){
            this.month = this.value;
        },
        month(){
            this.$emit('input', this.month);
        }
    },
    computed: {
        formatData(){
            return this.month?.substr(0, 10);
        }
    }
}
</script>
