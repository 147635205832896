<template>
    <v-btn-toggle v-model="searchDateValue" dense color="primary" @change="emit">
        <v-btn v-for="{ text, value } in items.slice(0, itemsLength)" :key="text" v-bind="{ value }" height="40" color="white">{{ text }}</v-btn>
    </v-btn-toggle>
</template>

<script>
function mapYearly(_, i) {
    let text = "";

    switch (i) {
        case 0: {
            text = "올해";
            break;
        }

        case 1: {
            text = "작년";
            break;
        }

        default: {
            text = `${this.$dayjs().subtract(i, "year").year()}년`;
            break;
        }
    }

    return { text, value: [this.$dayjs().subtract(i, "year").startOf("year").toISOString().toDate(), this.$dayjs().subtract(i, "year").endOf("year").toISOString().toDate()] };
}

function mapMonthly(_, i) {
    let text = "";

    let year = this.$dayjs().subtract(i, "month").year();
    if (year != this.$dayjs().year()) {
        if (i < this.itemsLength) {
            text += `${year}년 `;
        } else return;
    }

    text += `${this.$dayjs().subtract(i, "month").month() + 1}월`;

    return { text, value: [this.$dayjs().subtract(i, "month").startOf("month").toISOString().toDate(), this.$dayjs().subtract(i, "month").endOf("month").toISOString().toDate()] };
}

const filterItem = (item) => item;

export default {
    props: {
        value: { type: Array, default: () => [null, null] },

        mode: { type: String, default: null },
        itemsLength: { type: [String, Number], default: 8 },
    },
    data: () => ({
        searchDateValue: [null, null],
    }),
    computed: {
        items() {
            let items = [{ text: "전체", value: [null, null] }];
            switch (this.mode) {
                case "yearly": {
                    items.push(...new Array(this.itemsLength).fill().map(mapYearly.bind(this)));
                    break;
                }
                case "monthly": {
                    items.push(...new Array(Math.ceil(12)).fill().map(mapMonthly.bind(this)).filter(filterItem));
                    break;
                }
                default: {
                    items.push(
                        { text: "내일", value: [this.$dayjs().add(1, "day").toISOString().toDate(), this.$dayjs().add(1, "day").toISOString().toDate()] },
                        { text: "오늘", value: [Date.now().toDate(), Date.now().toDate()] },
                        { text: "어제", value: [this.$dayjs().subtract(1, "day").toISOString().toDate(), this.$dayjs().subtract(1, "day").toISOString().toDate()] },
                        { text: "1주일", value: [this.$dayjs().subtract(6, "day").toISOString().toDate(), Date.now().toDate()] },
                        { text: "1개월", value: [this.$dayjs().subtract(1, "month").add(1, "day").toISOString().toDate(), Date.now().toDate()] },
                        { text: "6개월", value: [this.$dayjs().subtract(6, "months").add(1, "day").toISOString().toDate(), Date.now().toDate()] },
                        { text: "12개월", value: [this.$dayjs().subtract(12, "months").add(1, "day").toISOString().toDate(), Date.now().toDate()] }
                    );
                    break;
                }
            }
            return items;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.searchDateValue = [...(this.value || [null, null])];
        },
        emit() {
            this.$emit("input", this.searchDateValue);
        },
    },
};
</script>

<style>
</style>