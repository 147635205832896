<template>
    <v-autocomplete :value="value" v-bind="attrs" v-on="{ change }">
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Object, Array], default: null },

        params: { type: Object, default: () => ({}) },
        headers: { type: Object, default: () => ({}) },

        multiple: { type: Boolean, default: false },
        retrunObject: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        attrs() {
            const { items, itemText, multiple } = this;
            return {
                ...this.$attrs,
                items,
                itemText,
                itemValue: "_id",

                multiple,
                ["return-object"]: this.retrunObject,
            };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        value() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            const { headers, params } = this;
            this.items = (await api.console.rental.subjects.gets({ headers, params }))?.subjects || [];
        },
        async change(newValue) {
            this.$emit("input", newValue);
        },
        itemText({ name }) {
            return `${name?.trim?.() || name}`;
        },
    },
};
</script>

<style></style>
