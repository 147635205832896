<template>
    <v-edit-dialog :return-value.sync="form.departedAt" large cancel-text="취소" save-text="저장" v-on="{ save }" @open="sync">
        <slot />
        <template #input>
            <v-sheet width="240" class="mt-5">
                <v-datetime-field v-model="form.departedAt" v-bind="inputAttrs" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import { initCommonAttendance, inputAttrs } from "@/assets/variables";

export default {
    components: {
        VDatetimeField,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        form: initCommonAttendance(),

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initCommonAttendance(this.value);
        },
        save() {
            let { _id, departedAt } = this.form;
            let form = { _id, departedAt };

            this.$emit("update", form);
        },
    },
};
</script>

