var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('v-card', [_c('v-card-title', [_vm._v("사용장비")]), _c('v-card-text', {
    staticClass: "mt-n4 pa-6 caption"
  }, [_vm._v(" " + _vm._s(_vm.items.sort().join(" / ")) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }