<template>
    <v-dialog v-model="shows" max-width="1080" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title>
                <template v-if="isCreate">예약제한 생성</template>
                <template v-else>예약제한 수정</template>
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-6" style="flex: 0 1 100%; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" lg="4" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" class="pa-2">
                                <view-section-card title="기본정보">
                                    <v-card-text>
                                        <v-row class="ma-n2">
                                            <v-col cols="12" class="pa-2">
                                                <v-select v-model="form.type" label="대상유형" :items="types" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-currency-field v-model="form.times" label="타임제" prefix="최대" :suffix="form.type == RENTAL_LIMITATION_TYPES.PER_WEEK.value ? '일' : '타임'" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-currency-field v-model="form.hours" label="시간제" prefix="최대" :suffix="form.type == RENTAL_LIMITATION_TYPES.PER_WEEK.value ? '일' : '시간'" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-col cols="12" class="pa-2">
                                                <v-currency-field v-model="form.dates" label="종일제" prefix="최대" suffix="일" v-bind="inputAttrs" @input="emit" />
                                            </v-col>
                                            <v-expand-transition>
                                                <v-col cols="12" class="pa-0" v-show="form.type == RENTAL_LIMITATION_TYPES.PER_WEEK.value">
                                                    <div class="pa-2">
                                                        <v-select v-model="form.resetDay" label="기준요일" :items="days" v-bind="inputAttrs" @input="emit" />
                                                    </div>
                                                </v-col>
                                            </v-expand-transition>
                                        </v-row>
                                    </v-card-text>
                                </view-section-card>
                            </v-col>
                            <v-col cols="12" class="pa-2">
                                <view-section-card title="관리자메모">
                                    <v-textarea v-model="form.memo" v-bind="attrs_input__verticalTable" @input="emit" />
                                </view-section-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="8" class="pa-2">
                        <view-section-card title="대상정보">
                            <v-card-text style="max-height: 519px; overflow-y: auto">
                                <v-row class="ma-n2">
                                    <v-col cols="12" class="pa-2">
                                        <v-select v-model="form.target.type" label="대상유형" :items="targetTypes" v-bind="inputAttrs" @input="emit" />
                                    </v-col>
                                    <v-expand-transition>
                                        <v-col cols="12" class="pa-0" v-show="form.target.type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_SELF.value">
                                            <div class="pa-2">
                                                <autocomplete-rental-subject v-model="form.target._subjects" label="대상설비" multiple :dense="false" v-bind="inputAttrs" @input="emit">
                                                    <template #selection="{ parent, item, attrs, itemText }">
                                                        <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                                                    </template>
                                                </autocomplete-rental-subject>
                                            </div>
                                        </v-col>
                                    </v-expand-transition>
                                    <v-expand-transition>
                                        <v-col cols="12" class="pa-0" v-show="form.target.type == RENTAL_LIMITATION_TARGET_TYPES.SUBJECT_TYPE.value">
                                            <div class="pa-2">
                                                <v-select v-model="form.target.subjectTypes" label="대상설비유형" :items="subjectTypes" multiple v-bind="inputAttrs" @input="emit" />
                                            </div>
                                        </v-col>
                                    </v-expand-transition>
                                </v-row>
                            </v-card-text>
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isActive" v-bind="switchAttrs">
                        <template #label>
                            <span class="subtitle-2">
                                <template v-if="form.isActive">사용</template>
                                <template v-else>미사용</template>
                            </span>
                        </template>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { inputAttrs, switchAttrs, RENTAL_LIMITATION_TARGET_TYPES, RENTAL_LIMITATION_TYPES, initRentalLimitation, RENTAL_SUBJECT_TYPES, attrs_input__verticalTable } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

const days = new Array(7)
    .fill()
    .map((_, index) => index)
    .map((value) => ({ value, text: dayjs().day(value).format("dddd") }));
const types = Object.values(RENTAL_LIMITATION_TYPES);
const targetTypes = Object.values(RENTAL_LIMITATION_TARGET_TYPES);
const subjectTypes = Object.values(RENTAL_SUBJECT_TYPES);

export default {
    components: {
        ViewSectionCard,
        AutocompleteRentalSubject,
    },
    props: {
        value: { type: Object, default: initRentalLimitation },
    },
    data: () => ({
        shows: false,
        loading: false,

        form: initRentalLimitation(),

        days,
        types,
        targetTypes,
        subjectTypes,

        RENTAL_LIMITATION_TYPES,
        RENTAL_LIMITATION_TARGET_TYPES,

        inputAttrs,
        switchAttrs,
        attrs_input__verticalTable,
    }),
    computed: {
        _limitation() {
            return this.value?._id;
        },
        isCreate() {
            return !this._limitation;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initRentalLimitation();
                } else {
                    let { limitation } = await api.console.rental.limitations.get({ _id: this._limitation });

                    this.form = initRentalLimitation(limitation);
                }
            } finally {
                this.loading = false;
            }
        },

        async emit() {
            this.form = initRentalLimitation(this.form);
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                let { _thumb, thumb, ...form } = this.form;

                // POST/PUT limitation
                let { post, put } = api.console.rental.limitations;
                let { limitation } = await (this.isCreate ? post(form) : put(form));
                let _limitation = limitation?._id;

                // DELETE/POST thumb
                if (thumb instanceof File) thumb = (await api.console.rental.limitations.thumb.post({ _limitation }, thumb))?.thumb;
                _thumb = thumb?._id || null;

                // PUT limitation
                limitation = (await put({ _id: _limitation, _thumb }))?.limitation;

                alert("저장되었습니다");
                this.$emit("input", limitation);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
