<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading :title="title">
                <template v-if="isCommunity" #buttons>
                    <board-category-list>
                        <template #activator="{ attrs, on }">
                            <v-btn tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"># 자유게시판 카테고리 설정 </v-btn>
                        </template>
                    </board-category-list>
                </template>
            </list-heading>
            <board-list-search v-bind="{ isCommunity }" flat outlined />

            <v-data-table v-bind="{ headers: itemHeaders, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.index`]="{ index }">
                    {{ summary.totalCount - skip - index }}
                </template>

                <template #[`item.isNotice`]="{ item }">
                    <v-switch v-model="item.isNotice" class="d-inline-block mr-n4" v-bind="{ ...switchAttrs, loading }" @change="(isNotice) => update({ _id: item._id, isNotice })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <board-content-preview v-bind="{ content: item?.content, youtubeCode: item?.youtubeCode }">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon small> mdi-file-find </v-icon>
                            </v-btn>
                        </template>
                    </board-content-preview>

                    <v-btn v-if="!isCommunity" small text icon @click="edit(item)">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>

                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" /> </template
            ></v-data-table>

            <v-btn v-if="!isCommunity" bottom color="accent" fab fixed right @click="create()"> <v-icon>mdi-pencil</v-icon> </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "@/plugins/vue-dayjs";
import { BOARD_TYPES, initDataTableHeaders, switchAttrs } from "@/assets/variables";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BoardListSearch from "@/components/console/boards/board-list-search.vue";
import BoardContentPreview from "@/components/console/archiving/boards/board-content-preview.vue";
import BoardCategoryList from "@/components/console/boards/board-category-list.vue";

const headers = initDataTableHeaders([
    { text: "#", value: "index", width: 20, isCommunity: true, isNews: true, isNotification: true },
    // { text: "썸네일", value: "thumb", width: 30 },
    { text: "카테고리", value: "category.title", width: 20, isCommunity: true, isNews: false, isNotification: false },
    { text: "제목", value: "subject", width: +150, isCommunity: true, isNews: true, isNotification: true },
    // { text: "내용", value: "content", width: 10 },
    { text: "조회수", value: "viewCount", width: 30, isCommunity: true, isNews: true, isNotification: true },
    { text: "상단고정", value: "isNotice", width: 20, isCommunity: false, isNews: true, isNotification: true },
    { text: "작성일자", value: "createdAt", width: 100, formatter: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"), isCommunity: true, isNews: true, isNotification: true },
    { text: "Actions", value: "actions", width: 20, isCommunity: true, isNews: true, isNotification: true },
]);

export default {
    components: {
        ImagePopup,
        VDateField,
        ListHeading,
        BoardListSearch,
        BoardContentPreview,
        BoardCategoryList,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            switchAttrs,
            headers,
            loading: false,

            limit: 10,

            boards: [],
            summary: { totalCount: 0 },
        };
    },
    computed: {
        items() {
            return [...this.boards];
        },
        itemHeaders() {
            switch (this.code) {
                case BOARD_TYPES.COMMUNITY.value:
                    return headers.filter((header) => header?.isCommunity);
                case BOARD_TYPES.NOTIFICATION.value:
                    return headers.filter((header) => header?.isNotification);;
                case BOARD_TYPES.NEWS.value:
                    return headers.filter((header) => header?.isNews);;
                default:
                    return headers;
            }
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;
            query.code = this.code;

            return query;
        },
        isCommunity() {
            return this.code === BOARD_TYPES.COMMUNITY.value;
        },
        title() {
            return `${BOARD_TYPES[this.code].text} 게시글`;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                // const { boards: fixed } = await api.console.boards.gets({
                //     headers: { limit: 0 },
                //     params: { isNotice: true, code: this.$route.query.code },
                // });
                const { summary, boards } = await api.console.boards.gets({
                    headers: { skip: this.skip, limit: this.limit },
                    params: { ...this.query },
                });

                // this.fixed = fixed;
                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`${this.$route.path}/create`);
        },
        edit(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
        async update(item) {
            try {
                await api.console.boards.put(item);
                await this.search(false);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.boards.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        "$route.query"() {
            this.search();
        },
    },
};
</script>
