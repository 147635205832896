<template>
    <list-search v-bind="{ ...$attrs, label, dense, showsSearch }" @search="emit">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="3">
                <v-select label="노출" v-model="query['shows']" :items="items_shows" placeholder="전체" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-select label="예약하기" v-model="query['rental.isActive']" :items="items_rentalIsActive" placeholder="전체" v-bind="inputAttrs" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field label="코드명" v-model="query['code']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field label="설비명" v-model="query['name']" v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["shows"]: query["shows"] ?? null,
    ["rental.isActive"]: query["rental.isActive"] ?? null,

    ["code"]: query["code"] ?? null,
    ["name"]: query["name"] ?? null,
});

const items_shows = [
    { text: "전체", value: null },
    { text: "노출", value: true },
    { text: "미노출", value: false },
];
const items_rentalIsActive = [
    { text: "전체", value: null },
    { text: "예약가능", value: true },
    { text: "예약불가", value: false },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        value: { type: Object, default: initQuery },

        mode: { type: String, default: "route" },
        label: { type: String, default: undefined },
        dense: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        items_shows,
        items_rentalIsActive,

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            if (this.mode == "route") this.query = initQuery(this.$route.query);
            if (this.mode == "model") this.query = initQuery(this.value);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (this.mode == "route") this.$router.push({ query });
            if (this.mode == "model") this.$emit("input", query);
        },
    },
};
</script>

<style></style>
