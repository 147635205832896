<template>
    <v-tabs>
        <template v-for="item in items">
            <v-tab :exact="item.to.path == path" :exact-path="item.to.path != path" :to="item.to" :key="item.text">{{ item.text }}</v-tab>
        </template>
    </v-tabs>
</template>

<script>
import { RENTAL_LIMITATION_TARGET_TYPES } from "@/assets/variables";

const path = "/console/rental-bans";
const items = [{ text: "전체", to: { path } }].concat(Object.values(RENTAL_LIMITATION_TARGET_TYPES).map(({ text, value }) => ({ text: `${text}금지`, to: { path: `${path}/${value}` } })));

export default {
    data: () => ({
        path,
        items,
    }),
};
</script>

<style>
</style>