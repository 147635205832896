<template>
    <v-data-table v-model="selected" v-bind="{ items, headers, loading }" :items-per-page="-1" show-select disable-sort disable-pagination hide-default-footer fixed-header height="calc(90vh - 16px - 32px - 10px + 16px - 20px - 20px - 1px - 134px - 1px - 1px - 60px - 1px - 60px)" @input="emit">
        <template #top>
            <rental-subject-list-search v-model="query" label="장비선택" mode="model" showsSearch dense flat class="mb-n3" @input="page = 1" />
            <v-divider />
        </template>

        <template #[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox v-bind="props" v-on="on" class="mr-n2" />
        </template>

        <template #[`item.data-table-select`]="{ select, isSelected }">
            <v-simple-checkbox :value="isSelected" class="mr-n2" @click="select(!isSelected)" />
        </template>

        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.thumb`]="{ value }">
            <image-popup :src="value?.url" rounded outlined />
        </template>

        <template #footer>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" :total-visible="20" color="primary" class="my-2" />
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import RentalSubjectListSearch from "@/components/console/rental/subjects/list/rental-subject-list-search.vue";

const headers = [
    { text: "유형", value: "type", formatter: (key) => RENTAL_SUBJECT_TYPES[key]?.text, width: +60 },
    { text: "썸네일", value: "thumb", class: "pa-0", cellClass: "pa-1", align: "center", width: +60 },
    { text: "설비명", value: "name" },
    { text: "코드명", value: "code" },
    { text: "장소", value: "place", width: 220 },
    { text: "순서", value: "index", width: +60, align: "center" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

export default {
    components: {
        ImagePopup,
        RentalSubjectListSearch,
    },
    props: {
        value: { type: Array, default: () => [] }, //selected
        shows: { type: Boolean, default: false },
    },
    data: () => ({
        subjects: [],
        selected: [],

        query: undefined,

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        items() {
            return [...this.subjects];
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.query || {};

            query.type = RENTAL_SUBJECT_TYPES.EQUIPMENTS.value;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            if (this.shows) this.init().then(this.search);
        },
        page() {
            this.search();
        },
        params() {
            this.search();
        },
        loading() {
            this.$emit("setLoading", this.loading);
        },
    },
    methods: {
        async init() {
            this.page = 1;
            this.query = undefined;
        },

        async sync() {
            this.selected = [...this.value];
        },

        async emit() {
            this.$emit("input", this.selected);
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, subjects } = await api.console.rental.subjects.gets({
                    headers: { skip, limit },
                    params,
                });

                this.subjects = subjects;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
