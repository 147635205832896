var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    attrs: {
      "large": "",
      "block": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', {
    color: _vm.color
  }, false), [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', [_vm._v(_vm._s(_vm.time.timeStart) + " ~ " + _vm._s(_vm.time.timeEnd))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-simple-checkbox', {
    attrs: {
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }