<template>
    <list-search v-bind="{ ...$attrs, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="수신여부" v-model="query['marketingMethod']" :items="marketingItems" placeholder="전체" v-bind="inputAttrs" @input="emit" />
            </v-col>

            <!-- <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-rental-subject label="사용가능장비" v-model="query['blacksmith._equipments__rentable']" placeholder="전체" :params="{ type: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value }" clearable v-bind="inputAttrs" />
            </v-col> -->

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select label="검색대상" v-model="query['searchKey']" :items="searchKeys" v-bind="inputAttrs" />
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field label="검색어" v-model="query['searchValue']" clearable v-bind="inputAttrs" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs, RENTAL_SUBJECT_TYPES, USER_MARKETING_METHODS } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import SelectUserType from "@/components/console/dumb/select-user-type.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["type"]: query["type"] ?? null,

    ["searchKey"]: query["searchKey"] ?? null,
    ["searchValue"]: query["searchValue"] ?? null,

    // ["blacksmith._equipments__rentable"]: query["blacksmith._equipments__rentable"] ?? null,
});

const searchKeys = [
    { text: "전체", value: null },
    { text: "아이디", value: "username" },
    // { text: "기업명", value: "companyName" },
    { text: "회원명", value: "name" },
    { text: "닉네임", value: "nickname" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
];

export default {
    components: {
        ListSearch,
        SelectUserType,
        AutocompleteRentalSubject,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,
        marketingItems: [{ text: "전체", value: null }, ...Object.values(USER_MARKETING_METHODS)],

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { query = {} } = this;

            delete query.page;

            if (!query["type"]) delete query["type"];

            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            // if (!query["blacksmith._equipments__rentable"]) delete query["blacksmith._equipments__rentable"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
