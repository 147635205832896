<template>
    <v-autocomplete v-model="$data._category" :items="categories" :item-text="itemText" item-value="_id" v-bind="$attrs" hide-details />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        _category: null,
        categories: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._category"(_category) {
            this.$emit("input", _category);
        },
    },
    methods: {
        sync() {
            this.$data._category = this.value;
        },
        async init() {
            this.categories = (await api.v1.programs.categories.gets())?.categories || [];
        },
        itemText({ name }) {
            return `${name}`;
        },
    },
};
</script>

<style></style>
