var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "400",
      "persistent": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("장비 사용장소 설정")]), _c('v-card-text', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [_c('draggable', {
    model: {
      value: _vm.form.rental.equipment.places,
      callback: function ($$v) {
        _vm.$set(_vm.form.rental.equipment, "places", $$v);
      },
      expression: "form.rental.equipment.places"
    }
  }, [_vm._l(_vm.form.rental.equipment.places, function (item, index) {
    return [_c('v-chip', {
      key: item,
      attrs: {
        "outlined": "",
        "label": "",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.form.rental.equipment.places.splice(index, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1), _c('v-text-field', _vm._b({
    attrs: {
      "append-icon": "mdi-plus"
    },
    on: {
      "click:append": _vm.add,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.add.apply(null, arguments);
      }
    },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소하기")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }