<template>
    <v-btn large block tile color="secondary" @click="$emit('click')">
        <v-row align="center">
            <v-col>
                <span>{{ $dayjs().hour(hour).minute(0).format("HH:mm") }}</span>
            </v-col>
            <v-col cols="auto">
                <v-simple-checkbox hide-details @click="$emit('click')" />
            </v-col>
        </v-row>
    </v-btn>
</template>

<script>
import { RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        schedulesOnCloud: { type: Array, default: () => [] },

        date: { type: String },
        hour: { type: Number },
    },
    computed: {
        scheduleOnCloud() {
            return this.schedulesOnCloud.find((schedule) => schedule.hour == this.hour);
        },
        isClosed() {
            return this.$dayjs(this.date).set("hour", this.hour).isBefore(this.$dayjs());
        },
        isApproved() {
            return this.scheduleOnCloud?.state == RENTAL_SCHEDULE_STATES.APPROVED.value;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>