<template>
    <v-dialog max-width="480">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>사용가능장비</v-card-title>
            <v-card-text class="mt-n4 pa-6 caption">
                {{ items.sort().join(" / ") }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { initUserOrganization } from "@/assets/variables";

export default {
    props: {
        item: { type: Object, default: initUserOrganization },
    },
    computed: {
        items() {
            return this.item?.blacksmith?.equipments__rentable?.map(({ name }) => name?.trim?.() || name) || [];
        },
    },
};
</script>

<style>
</style>