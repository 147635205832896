var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    staticClass: "fill-height",
    attrs: {
      "title": "선택한 스케줄"
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "height": "494",
      "fixed-header": ""
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" 선택한 스케줄이 없습니다 ")];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.time`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.request.subject.rental.type == _vm.RENTAL_TYPES.BY_DATE.value ? [_vm._v(" 종일 ")] : _vm._e(), item.request.subject.rental.type == _vm.RENTAL_TYPES.BY_HOUR.value ? [_vm._v(" " + _vm._s(`${item.hour}:00`.padStart(5, 0)) + " ")] : _vm._e(), item.request.subject.rental.type == _vm.RENTAL_TYPES.BY_TIME.value ? [_vm._v(" " + _vm._s(`${item.timeStart} ~ ${item.timeEnd}`) + " ")] : _vm._e()];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          staticClass: "pa-0",
          attrs: {
            "dense": "",
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    itemClass: _vm.itemClass
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }