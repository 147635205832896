<template>
    <v-dialog v-model="shows" max-width="880">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                참석현황 <v-spacer /> <v-btn text icon tile @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.attendance`]="{ value }">
                    <v-row no-gutters justify="center">
                        <v-col cols="auto">
                            <v-switch v-model="value.isAttended" v-bind="switchAttrs" class="ml-1 mr-n1" style="width: 48px" @change="(isAttended) => toggleAttendance({ _id: value._id, isAttended })" />
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import { COMMON_ATTENDANCE_TYPES, switchAttrs } from "@/assets/variables";

let headers = [
    { text: "아이디", value: "user.username" },
    { text: "이름", value: "user.name" },
    { text: "연락처", value: "user.phone" },
    { text: "이메일", value: "user.email" },
    { text: "참석", value: "attendance" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "center", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    props: {
        unit: { type: Object, default: () => ({}) },
        attendances: { type: Array, default: () => [] },
        applications: { type: Array, default: () => [] },
    },
    data() {
        return {
            shows: false,
            headers,
            switchAttrs,
        };
    },
    computed: {
        items() {
            return this.applications.map((application = {}) => {
                let attendance = (this.attendances || []).find(({ _user }) => _user == application._user);
                let hasUserAttended = !!attendance;

                let { affiliation, department, courseName, phone, email } = application.applicant || {};
                let applicantAffiliation = `${affiliation || "-"}\n${department || "-"} / ${courseName || "-"}`;
                let applicantContactInfo = `${phone || "-"}\n${email || "-"}`;

                return {
                    ...application,
                    attendance,
                    hasUserAttended,
                    applicantAffiliation,
                    applicantContactInfo,
                };
            });
        },
    },
    methods: {
        async toggleAttendance(item) {
            this.$emit("update", item);
        },
    },
};
</script>
