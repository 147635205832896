<template>
    <v-layout justify-center>
        <v-responsive max-width="1300px" width="100%" height="100%" class="mx-n3 px-3">
            <list-heading title="금산에 활력을 불어넣는 사람들">
                <template #buttons>
                    <people-organization-form>
                        <template #activator="{ attrs, on }">
                            <v-btn tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"># 추진단 조직도 설정 </v-btn>
                        </template>
                    </people-organization-form>
                </template>
            </list-heading>

            <people-list-search v-bind="{}" flat outlined />

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.index`]="{ item, value }">
                    <people-index-edit v-bind="{ value: item }" @update="update">
                        {{ value }}
                    </people-index-edit>
                </template>
                <template #[`item.thumb`]="{ item }">
                    <image-popup v-bind="{ src: item?.thumb?.src, size: '30', rounded: true }" class="cursor-pointer" />
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.isMain`]="{ item }">
                    <v-switch v-model="item.isMain" class="d-inline-block mr-n4" v-bind="{ ...switchAttrs, loading }" @change="(isMain) => update({ _id: item._id, isMain })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon @click="edit(item)">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination> </template
            ></v-data-table>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders, inputAttrs, PEOPLE_TYPES, switchAttrs } from "@/assets/variables";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PeopleListSearch from "@/components/console/people/people-list-search.vue";
import PeopleIndexEdit from "@/components/console/people/people-index-edit.vue";
import PeopleOrganizationForm from "@/components/console/people/people-organization-form.vue";

const headers = initDataTableHeaders([
    { text: "#", value: "index", width: "20" },
    { text: "타입", value: "type", width: "20", formatter: (value) => `${PEOPLE_TYPES[value].text}` },
    { text: "썸네일", value: "thumb", width: "40" },
    { text: "제목", value: "name", width: "150" },
    { text: "한줄설명", value: "summary", width: "150" },
    { text: "메인 설정", value: "isMain", width: "20" },
    { text: "작성일자", value: "createdAt", width: "80" },
    { text: "Actions", value: "actions", width: "20" },
]);

export default {
    components: {
        ListHeading,
        ImagePopup,
        PeopleListSearch,
        PeopleIndexEdit,
        PeopleOrganizationForm
    },
    data: () => ({
        loading: false,
        inputAttrs,
        switchAttrs,

        people: [],
        headers,
        summary: { totalCount: 0 },
        limit: 10,
    }),
    computed: {
        items() {
            return [...this.people];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    async mounted() {
        await this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { skip, limit } = this;

                let { summary, people } = await api.console.people.gets({
                    headers: { skip, limit },
                    params: this.query,
                });

                this.people = people;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (this.loading) return;
            this.loading = true;
            try {
                if (confirm("삭제하시겠습니까?")) {
                    if (item?._thumb) await api.console.common.files.delete({ _id: item._thumb });

                    if (item?._images.length) {
                        for (let i in item._images) {
                            await api.console.common.files.delete({ _id: item._images[i] });
                        }
                    }
                    await api.console.people.delete(item);

                    alert("삭제되었습니다.");
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                await this.search();
            }
        },
        async update(item) {
            if (this.loading) return;
            this.loading = false;
            try {
                await api.console.people.put(item);
                await this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        create() {
            this.$router.push(`${this.$route.path}/create`);
        },
        edit(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
    watch: {
        query() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
