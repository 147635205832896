var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._g(_vm._b({}, 'v-card', _vm.$attrs, false), {
    click: _vm.click
  }), [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-img', _vm._b({
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                attrs: {
                  "absolute": "",
                  "light": "",
                  "color": "transparent"
                }
              }, [_c('v-icon', {
                attrs: {
                  "color": "grey"
                }
              }, [_vm._v("mdi-image-broken-variant")])], 1)];
            },
            proxy: true
          }], null, true)
        }, 'v-img', Object.assign({}, _vm.$attrs, {
          src: _vm.src
        }), false), [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "light": "",
            "color": "transparent",
            "absolute": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey"
          }
        }, [_vm._v("mdi-image-broken-variant")])], 1)], 1), _vm.file ? _c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover || _vm.showsMenu,
            expression: "hover || showsMenu"
          }],
          attrs: {
            "absolute": ""
          }
        }, [_c('image-popup', _vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-eye")])], 1)];
            }
          }], null, true)
        }, 'image-popup', {
          src: _vm.src
        }, false)), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              return _vm.remove.apply(null, arguments);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1) : _vm._e()], 1)];
      }
    }])
  }), _c('v-file-input', _vm._b({
    ref: "fileInput",
    staticClass: "d-none",
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', _vm.$attrs, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }