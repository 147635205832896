<template>
    <v-chip-group v-model="hours" multiple column dense color="primary" @change="emit">
        <template v-for="{ ampm, hour, value } in items">
            <v-chip :value="value" outlined label class="rounded-lg" style="min-width: calc(16.66% - 8px)" :key="value">
                <v-row no-gutters>
                    <v-col> {{ ampm }} </v-col>
                    <v-spacer />
                    <v-col> {{ hour }} </v-col>
                </v-row>
            </v-chip>
        </template>
    </v-chip-group>
</template>

<script>
import dayjs from "dayjs";
const items = new Array(24).fill().map((_, index) => ({
    ampm: dayjs().hour(index).minute(0).format("a"),
    hour: dayjs().hour(index).minute(0).format("hh:mm"),
    value: index,
}));

export default {
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        hours: [],
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.hours = [...this.value];
        },
        emit() {
            this.$emit("input", this.hours);
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .v-chip__content {
        width: 100%;
    }
}
</style>