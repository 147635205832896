<template>
    <v-calendar v-bind="props_calendar" @mousedown:event="startDrag" @mousedown:time="addItem" @mousemove:time="mouseMove" @mouseup:time="endDrag" @mouseleave.native="cancelDrag">
        <template #event="{ event }">
            <div class="px-1 primary--text">
                <v-row no-gutters>
                    <v-col cols="auto"> {{ $dayjs(event.start).format("HH:mm") }} ~ {{ $dayjs(event.end).format("HH:mm") }} </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-icon x-small @click="removeItem(event)">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-fade-transition>
                    <div v-show="$dayjs(event.end).diff(event.start, 'minutes') >= 90" style="position: absolute; bottom: 6px; right: 4px">총 {{ $dayjs(event.end).diff(event.start, "minutes") }}분</div>
                </v-fade-transition>
            </div>
            <div class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)" @mouseup.stop="emit"></div>
        </template>
    </v-calendar>
</template>

<script>
const roundTo = 10; // minutes
const roundDownTime = roundTo * 60 * 1000;
const roundTime = (time, down = true) => (down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime)));

const toTime = (tms) => new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();

export default {
    props: {
        days: { type: Array, default: () => [] },
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        events: [],
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
    }),
    computed: {
        times() {
            return this.events.map((item) => ({
                day: this.$dayjs(item.start).day(),
                timeEnd: this.$dayjs(item.end).format("HH:mm"),
                timeStart: this.$dayjs(item.start).format("HH:mm"),
            }));
        },
        props_calendar() {
            return {
                type: "week",
                value: this.$dayjs().add(1, "week").toDate(),
                events: this.events,
                weekdays: this.days,
                firstInterval: "08:00",
                intervalCount: 12,
                intervalFormat: ({ time }) => time,

                eventHeight: 32,
                intervalHeight: 32,

                eventColor: "#EBF8FD",
                eventTextColor: "rgba(0,0,0,.12)",
            };
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.events = this.value.map(({ day, timeStart, timeEnd }) => ({
                timed: true,
                end: this.$dayjs().add(1, "week").day(day).hour(timeEnd.split(":")[0]).minute(timeEnd.split(":")[1]).valueOf(),
                start: this.$dayjs().add(1, "week").day(day).hour(timeStart.split(":")[0]).minute(timeStart.split(":")[1]).valueOf(),
            }));
        },
        emit() {
            this.$emit("input", this.times);
        },

        startDrag({ event, timed }) {
            if (event && timed) {
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;
            }
        },
        addItem(tms) {
            const mouse = toTime(tms);

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;

                this.dragTime = mouse - start;
            } else {
                this.createStart = roundTime(mouse);
                this.createEvent = {
                    start: this.createStart,
                    end: this.createStart + 60 * 60 * 1000,
                    timed: true,
                };
                this.events.push(this.createEvent);
                this.emit();
            }
        },
        removeItem(event) {
            const index = this.events.findIndex((item) => item === event);
            this.events.splice(index, 1);
            this.emit();
        },
        extendBottom(event) {
            this.createEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
        },
        mouseMove(tms) {
            const mouse = toTime(tms);

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start;
                const end = this.dragEvent.end;
                const duration = end - start;
                const newStartTime = mouse - this.dragTime;
                const newStart = roundTime(newStartTime);
                const newEnd = newStart + duration;

                this.dragEvent.start = newStart;
                this.dragEvent.end = newEnd;
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);

                this.createEvent.start = min;
                this.createEvent.end = max;
            }
        },
        endDrag() {
            this.emit();
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
        },
        cancelDrag() {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal;
                } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                }
            }

            this.createEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    &.v-calendar-daily {
        border: none !important;
    }
    .v-calendar-daily_head-day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily_head-day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily__day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily__day {
        border-bottom: none !important;
    }

    .v-calendar-daily_head-day-label {
        display: none;
    }
    .v-calendar-daily__scroll-area {
        overflow: auto;
    }
    .v-calendar-daily__head {
        margin-right: 0 !important;
    }

    .v-event-timed-container {
        margin: 0 4px !important;
        user-select: none;
    }
}

.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    background: rgba(0, 0, 0, 0.1);
    cursor: ns-resize;

    &::after {
        position: absolute;
        left: 50%;
        bottom: 1px;
        height: 3px;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: "";
    }
}
</style>