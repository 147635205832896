var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._g(_vm._b({
    attrs: {
      "value": _vm.value
    },
    scopedSlots: _vm._u([_vm.$scopedSlots['selection'] ? {
      key: "selection",
      fn: function (_ref) {
        var parent = _ref.parent,
          item = _ref.item,
          attrs = _ref.attrs;
        return [_vm._t("selection", null, null, {
          parent,
          item,
          attrs,
          itemText: _vm.itemText
        })];
      }
    } : null], null, true)
  }, 'v-autocomplete', _vm.attrs, false), {
    change: _vm.change
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }