<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading v-bind="{ title }" />

            <current-list-search v-bind="{ showsSearch }" flat outlined />

            <v-data-table :items="manages" v-bind="{ headers, loading }" disable-sort disable-pagination hide-default-footer class="mt-4 v-sheet--outlined" ref="data-table">
                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :disabled="!$route.query.code || loading" @change="setNewIndex">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>

                <template #[`item.index`]="{ item, value }">
                    <edit-index :value="item" v-on="{ update }">
                        {{ value?.format?.() || "-" }}
                    </edit-index>
                </template>

                <template #[`item.subject`]="{ item }">
                    <template v-if="code == 'current'">
                        {{ item.subject || "-" }}
                    </template>
                    <template v-else>
                        이용안내
                    </template>
                </template>

                <template #[`item.createdAt`]="{ item }"> {{ item.createdAt.toDateTime() }} </template>
                s
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon :to="{ path: `/console/about/${code}/${item._id}`, query: { code } }">
                        <v-icon small title="수정">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon small title="삭제">mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>

            <v-btn bottom color="accent" fab fixed right :to="{ path: `/console/about/${code}/create`, query: { code } }"> <v-icon>mdi-pencil</v-icon> </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders } from "@/assets/variables";

import Draggable from "vuedraggable";
import EditIndex from "@/components/console/dumb/edit/edit-index.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import CurrentListSearch from "@/components/console/manage/current-list-search.vue";

const headers = initDataTableHeaders([
    { value: "index", text: "#", width: +60, align: "end" },
    { value: "subject", text: "제목" },
    { value: "actions", text: "", width: +80, align: "center", cellClass: "pa-0" },
]);

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
        EditIndex,
        ListHeading,
        CurrentListSearch,
    },
    data: () => ({
        manages: [],

        limit: 10,
        summary: { totalCount: 0 },

        category: null,

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        code() {
            return this.$route.query.code;
        },
        title() {
            switch (this.code) {
                case "current": {
                    return "입주기업현황";
                }
                case "info": {
                    return "이용안내";
                }
                default: {
                    return "목록";
                }
            }
        },
        items() {
            return this.manages.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        async init() {
            this.manages = [];
            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, manages } = await api.console.manage.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.manages = manages;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { manage } = await api.console.manage.put(item);

                this.loading = false;
                this.updateItem(manage);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.manages.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.manages.splice(index, 1, item);
            else {
                this.manages = [item, ...this.manages];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let {
                    element: { _id, index },
                    oldIndex,
                    newIndex,
                } = moved;
                index += oldIndex - newIndex;
                let form = { _id, index };
                let { manage } = await api.console.manage.put(form);
                this.loading = false;
                this.updateItem(manage);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async remove(manage) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.manage.delete(manage);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
