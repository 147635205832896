var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item.reply,
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.item, "reply", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.item, "reply", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-sheet', {
          attrs: {
            "width": "320",
            "min-height": "240"
          }
        }, [_c('v-textarea', {
          attrs: {
            "hide-details": "",
            "rows": "9"
          },
          model: {
            value: _vm.item.reply,
            callback: function ($$v) {
              _vm.$set(_vm.item, "reply", $$v);
            },
            expression: "item.reply"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }