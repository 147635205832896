<template>
    <v-expand-transition>
        <div v-show="showsSearch">
            <v-card v-bind="$attrs" class="mb-3">
                <template v-if="dense">
                    <v-card-title class="subtitle-2 font-weight-bold" v-if="!hideLabel">
                        {{ label }}
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" class="ma-n3">
                            <v-col> <slot /> </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" @click="$emit('search')"> <v-icon small>mdi-magnify</v-icon> 검색 </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </template>
                <template v-else>
                    <v-card-title class="subtitle-2 font-weight-bold" v-if="!hideLabel">
                        {{ label }}
                    </v-card-title>
                    <v-card-text>
                        <slot />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="$emit('search')"> <v-icon small>mdi-magnify</v-icon> 검색 </v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "검색필터" },
        dense: { type: Boolean, default: false },
        hideLabel: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
};
</script>

<style></style>
